//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, SxProps, TextField, useMediaQuery, useTheme } from "@mui/material";
import { VTButton, VTSidePanelAudioItem } from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import ReactPlayer from "react-player";
import { getAudioClipDuration, getDurationOfAudioInSeconds } from "../../utils/getAudioClipDuration";
import { Params, useParams } from "react-router-dom";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import { Droppable } from "react-beautiful-dnd";

// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    currentAudio: string;
    setCurrentAudio: (currentAudio: string) => void;
    disabled?: boolean;
    sx?: SxProps;
    droppableId: EDroppableContainerId;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTAudioDropper({ currentAudio, setCurrentAudio, disabled = false, sx, droppableId }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();
    const { currentSidePanel, currentlyDragging, currentSidePannelDesiredWidth } = useAppSelector(
        (state) => state.currentReducer,
    );
    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Render for the tab buttons

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "20px", ...sx }}>
            {/*----------------------------------------------------------------------------------------*/}
            {/* Relative container to allow the drop box to overlap the image */}
            <Grid item container sx={{ position: "relative" }} xs={12} justifyContent={"center"}>
                {(currentSidePanel.currentTab === "Audio" || currentAudio !== "") && (
                    <Droppable droppableId={droppableId} type="DROPPABLE_MEDIA" isDropDisabled={false}>
                        {(provided, snapshot) => {
                            // console.log("p", provided);
                            // console.log("s", snapshot);
                            return (
                                <>
                                    {currentAudio === "" ? (
                                        <img
                                            id="canvas-drop-container"
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            src={
                                                currentAudio === ""
                                                    ? "https://virtus-ninja-dev.s3.eu-west-1.amazonaws.com/virtus_tech/images/noImage.png"
                                                    : currentAudio
                                            }
                                            style={{
                                                width: "80%",
                                                height: "193px",
                                                objectFit: "cover",
                                                borderRadius: 15,
                                                border: snapshot.isDraggingOver
                                                    ? `4px dashed ${theme.palette.primary.light}`
                                                    : `4px dashed ${theme.palette.primary.dark}`,
                                            }}
                                        />
                                    ) : (
                                        <Grid
                                            sx={
                                                currentlyDragging && currentSidePanel.currentTab === "Audio"
                                                    ? {
                                                          width: "100%",
                                                          position: "relative",
                                                          border: "4px dashed purple",
                                                          borderRadius: "15px",
                                                      }
                                                    : { width: "100%", position: "relative" }
                                            }
                                            container
                                            justifyContent={"center"}
                                            ref={provided.innerRef}
                                            id="canvas-drop-container"
                                            {...provided.droppableProps}
                                        >
                                            <VTSidePanelAudioItem name={""} audioSrc={currentAudio} />

                                            {currentlyDragging && (
                                                <img
                                                    src={
                                                        "https://virtus-ninja-dev.s3.eu-west-1.amazonaws.com/virtus_tech/images/noImage.png"
                                                    }
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        height: "193px",
                                                        opacity: 0,
                                                        zIndex: 100000,
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    )}
                                </>
                            );
                        }}

                        {/*-------------------------------------------------------------------------------------*/}
                        {/* Drop field, accepts urls from dragged images to set image, style to be less visible */}
                    </Droppable>
                )}

                <Grid item container xs={12} justifyContent={"space-evenly"}>
                    {/*----------------------------------------------------------------------------*/}
                    {/* Button to open the image side panel */}
                    {(currentSidePanel.currentWidth === 0 || currentSidePanel.currentTab !== "Audio") && (
                        <Grid item xs={5}>
                            <VTButton
                                sx={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    marginTop: "15px",
                                }}
                                onClick={() =>
                                    dispatch(
                                        setCurrentSidePanel({
                                            currentTab: "Audio",
                                            currentWidth: currentSidePannelDesiredWidth,
                                            option: undefined,
                                        }),
                                    )
                                }
                            >
                                Choose Audio
                            </VTButton>
                        </Grid>
                    )}

                    {currentAudio !== "" && (
                        <Grid item xs={5}>
                            <VTButton
                                sx={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    marginTop: "15px",
                                }}
                                onClick={() => setCurrentAudio("")}
                            >
                                Remove Audio
                            </VTButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
