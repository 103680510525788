//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
import { v4 as uuid } from "uuid";

import { Vector3 } from "three";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Renders the different hotspots
export function blankHotspot(type: EDraggableOptions, params?: string): IHotspot {
    //-------------------------------------------------------------------------------------------------
    if (type === EDraggableOptions.INFORMATION) {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(0, 0, 90),
            type: EDraggableOptions.INFORMATION,
            styles: {
                preClickStyles: {
                    // colours: { background: "#303030" },
                },
                postClickStyles: {
                    // colours: { background: "#303030" },
                },
            },
            contents: {
                preClickContent: {
                    icon: "Info",
                    title: "Info Hotspot",
                },
                postClickContent: {
                    contentData: "Info content",
                },
            },
            actions: {
                closeable: true,
            },
        } as IHotspot;
    } else if (type === EDraggableOptions.MOVEMENT) {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(90, -10, 0),
            type: EDraggableOptions.ANSWER,
            contents: {
                preClickContent: {
                    icon: "Movement",
                },
            },
            actions: {
                movement: {
                    sceneId: "Scene Undefined",
                },
            },
        } as IHotspot;
    } else if (type === EDraggableOptions.TEXT_HOTSPOT) {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(90, -10, 0),
            type: EDraggableOptions.TEXT_HOTSPOT,
            contents: {
                preClickContent: {
                    contentData: `<div class="rte-container"> Paragraph </div>`,
                },
            },
        } as IHotspot;
    } else if (type === EDraggableOptions.IMMERSIVE_MODEL) {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(0, -2, 2),
            type: EDraggableOptions.IMMERSIVE_MODEL,
            contents: {
                preClickContent: {
                    contentData: params,
                },
            },
            actions: {
                movement: {
                    sceneId: "Scene Undefined",
                },
            },
        } as IHotspot;
    } else if (type === EDraggableOptions.INTERFACE) {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(0, 0, 0),
            type: EDraggableOptions.INTERFACE,
            contents: {
                preClickContent: {
                    title: "New User Interface",
                    userInterface: [
                        {
                            sectionTitle: "New User Input",
                        },
                    ],
                },
            },
        } as IHotspot;
    } else if (type === EDraggableOptions.COMPLETE) {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(-90, 0, 0),
            type: EDraggableOptions.ANSWER,

            contents: {
                preClickContent: {
                    title: "Complete Scenario",
                },
            },
            actions: {
                navigate: "/",
            },
        } as IHotspot;
    } else if (type === EDraggableOptions.PROCEED) {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(-90, 0, 0),
            type: EDraggableOptions.ANSWER,

            contents: {
                preClickContent: {
                    title: "Proceed",
                },
                postClickContent: {
                    title: "Proceeding...",
                },
            },
            actions: {
                appFeedback: {
                    proceed: true,
                    delay: 2,
                },

                closeable: false,
            },
        } as IHotspot;
    } else if (type === EDraggableOptions.IMAGE) {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(90, -10, 0),
            type: EDraggableOptions.INFORMATION,
            contents: {
                preClickContent: {
                    contentData: `<div class="rte-container"> <img src=${params} loading="lazy" style="object-fit: cover; border-radius: 5px; height: 200px; width: 200px; margin: 0px; position: relative;" draggable="true" id="I_50150" class="rte-image"> </div>`,
                },
            },
        } as IHotspot;
    } else if (type === EDraggableOptions.VIDEO) {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(90, -10, 0),
            type: EDraggableOptions.INFORMATION,
            contents: {
                preClickContent: {
                    contentData: `<div class="rte-container"> <video id="${params}" width="400px" height="240" controls> <source src="${
                        params!.split(".mp4")[0] + "/" + params!.split(".mp4")[0].split("video/")[1] + ".mp4"
                    }" type="video/mp4">Your browser does not support the video tag. </video> </div>`,
                },
            },
        } as IHotspot;
    } else if (type === EDraggableOptions.ANSWER) {
        // ## Declares ##
        if (params === "correct") {
            return {
                id: uuid(),
                location: new Vector3(-90, 0, 0),
                type: EDraggableOptions.ANSWER,
                styles: {
                    preClickStyles: { colours: { background: { r: 48, g: 48, b: 48, a: 1 } } },
                    postClickStyles: {
                        colours: { background: { r: 178, g: 222, b: 39, a: 1 } },
                        hover: { colours: { background: { r: 158, g: 202, b: 19, a: 1 } }, scale: 1.2 },
                    },
                },
                contents: {
                    preClickContent: {
                        title: "Correct Answer Hotspot",
                    },
                    postClickContent: {
                        title: "Correct Answer Hotspot Feedback",
                    },
                },
                actions: {
                    appFeedback: {
                        proceed: true,
                        delay: 3,
                    },

                    closeable: false,
                },
            } as IHotspot;
        } else if (params === "incorrect") {
            return {
                id: uuid(),
                location: new Vector3(-90, 0, 0),
                type: EDraggableOptions.ANSWER,
                styles: {
                    preClickStyles: { colours: { background: { r: 48, g: 48, b: 48, a: 1 } } },
                    postClickStyles: {
                        colours: { background: { r: 255, g: 0, b: 0, a: 1 } },
                        hover: { colours: { background: { r: 235, g: 0, b: 0, a: 1 } }, scale: 1.2 },
                    },
                },
                contents: {
                    preClickContent: {
                        title: "Incorrect Answer Hotspot",
                    },
                    postClickContent: {
                        title: "Incorrect Answer Hotspot Feedback",
                    },
                },
                actions: {
                    closeable: false,
                },
            } as IHotspot;
        } else if (params === "invisible") {
            return {
                id: uuid(),
                location: new Vector3(-90, 0, 0),
                type: EDraggableOptions.ANSWER,
                styles: {
                    preClickStyles: {
                        colours: { background: { r: 0, g: 0, b: 0, a: 0 }, text: { r: 0, g: 0, b: 0, a: 0 } },
                    },
                },
                contents: {
                    preClickContent: {
                        title: " ",
                    },
                    postClickContent: {
                        title: "Hotspot feedback",
                    },
                },
                actions: {
                    closeable: false,
                },
            } as IHotspot;
        } else if (params === "continue") {
            return {
                id: uuid(),
                location: new Vector3(-90, 0, 0),
                type: EDraggableOptions.ANSWER,
                styles: {
                    preClickStyles: {
                        colours: { background: { r: 65, g: 117, b: 5, a: 1 } },
                        hover: { colours: { background: { r: 65, g: 117, b: 5, a: 1 } } },
                    },
                    postClickStyles: {
                        colours: { background: { r: 65, g: 117, b: 5, a: 1 } },
                        hover: { colours: { background: { r: 65, g: 117, b: 5, a: 1 } } },
                    },
                },
                contents: {
                    preClickContent: {
                        title: "Continue",
                    },
                    postClickContent: {
                        title: "Restarting ...",
                    },
                },
                actions: {
                    appFeedback: {
                        proceed: true,
                        delay: 2,
                    },

                    closeable: false,
                },
            } as IHotspot;
        } else {
            return {
                id: uuid(),
                location: new Vector3(-90, 0, 0),
                type: EDraggableOptions.ANSWER,
                // styles: {
                //     preClickStyles: { colours: { background: "#303030" } },
                //     postClickStyles: { colours: { background: "#303030" } },
                // },
                contents: {
                    preClickContent: {
                        title: "Answer Hotspot",
                    },
                    postClickContent: {
                        title: "Answer Feedback",
                    },
                },
                actions: {
                    closeable: false,
                },
            } as IHotspot;
        }
    } else {
        // ## Declares ##
        return {
            id: uuid(),
            location: new Vector3(0, 0, -5),
            type: EDraggableOptions.INFORMATION,
            styles: {},
            contents: {
                preClickContent: {
                    title: "Hotspot",
                },
            },
            actions: {
                closeable: true,
            },
        } as IHotspot;
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
}

export function blankQuestionHotspot(time: number): IHotspot {
    //-------------------------------------------------------------------------------------------------
    // ## Declares ##
    const newHotspot: IHotspot = {
        id: uuid(),
        location: new Vector3(0, 0, 0),
        type: EDraggableOptions.QUESTION,
        contents: {
            preClickContent: {
                title: "",
                question: {
                    time: time,
                    answers: [],
                },
            },
        },
    } as IHotspot;

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return newHotspot;
}
