//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports

import { Grid, MenuItem, Paper, Select, Slider, TextField, Tooltip } from "@mui/material";
import { VTButton, VTMenu, VTTextField, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import { useDispatch } from "react-redux";
import { setCurrentHotspot } from "../../store/slices/current.slice";
import {
    AddRounded,
    SettingsRounded,
    CloseRounded,
    InfoRounded,
    KeyboardDoubleArrowUpRounded,
    RadioButtonCheckedRounded,
    SaveRounded,
    CancelRounded,
} from "@mui/icons-material";
import { useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import { Params, useParams } from "react-router";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { title } from "process";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IScenario, IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import {
    HotspotApi,
    useGetHotspotsQuery,
    useUpdateGroupHotspotMutation,
    useUpdateHotspotMutation,
} from "../../services/hotspot.service";
import { useGetScenesQuery } from "../../services/scene.service";
import VTIconDropDown from "./VTIconDropDown";

// Custom components imports (and hooks and helpers)

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    videoCurrentState: string | undefined;
    currentInterface: IHotspot | undefined;
    updateHotspot: any;
    setHotspotLoading: (hotspotLoading: string) => void;
    currentAttachedObject: any;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTHotspotQuickEdit({
    videoCurrentState,
    currentInterface,
    updateHotspot,
    setHotspotLoading,
    currentAttachedObject,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useDispatch();
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const [updateQuestionHotspot] = useUpdateGroupHotspotMutation();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentHotspot, currentQuestion, currentlyDragging, currentAttachedHotspot, currentSidePanel } =
        useAppSelector((state) => state.currentReducer);
    const { data: scenes } = useGetScenesQuery(scenarioId ? scenarioId : "");

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [preClickTitle, setPreClickTitle] = useState<string | undefined>(undefined);
    const [postClickTitle, setPostClickTitle] = useState<string | undefined>(undefined);
    const [preClickIcon, setPreClickIcon] = useState<string | undefined>(undefined);
    const [postClickIcon, setPostClickIcon] = useState<string | undefined>(undefined);

    const [scale, setScale] = useState<number | undefined>(undefined);
    const [hoverScale, setHoverScale] = useState<number | undefined>(undefined);


    const [actionSceneId, setActionSceneId] = useState<string | undefined>("");
    const [actionSceneDelay, setActionSceneDelay] = useState<number | undefined>(0);
    const [actionProceed, setActionProceed] = useState<boolean | undefined>(undefined);
    const [actionFeedbackDelay, setActionFeedbackDelay] = useState<number>(0);

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [menuOptions, setMenuOptions] = useState<any[]>([]);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    //Get current data to populate

    function getHotspotData() {
        if (currentHotspot && currentHotspot.currentHotspot) {
            setPreClickTitle(
                currentHotspot?.currentHotspot?.contents?.preClickContent.title
                    ? currentHotspot?.currentHotspot.contents.preClickContent.title
                    : undefined,
            );

            setPostClickTitle(
                currentHotspot?.currentHotspot?.contents?.postClickContent?.title
                    ? currentHotspot?.currentHotspot.contents.postClickContent.title
                    : undefined,
            );

            setPreClickIcon(
                currentHotspot?.currentHotspot?.contents?.preClickContent.icon
                    ? currentHotspot?.currentHotspot.contents.preClickContent.icon
                    : undefined,
            );

            setPostClickIcon(
                currentHotspot?.currentHotspot?.contents?.postClickContent?.icon
                    ? currentHotspot?.currentHotspot.contents.postClickContent.icon
                    : undefined,
            );

            setActionSceneId(
                currentHotspot?.currentHotspot?.actions?.movement?.sceneId
                    ? currentHotspot?.currentHotspot.actions?.movement?.sceneId
                    : undefined,
            );

            setActionSceneDelay(
                currentHotspot?.currentHotspot?.actions?.movement?.delay
                    ? currentHotspot?.currentHotspot.actions?.movement?.delay
                    : undefined,
            );

            setScale(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.scale
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.scale
                    : undefined,
            );

            setHoverScale(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.hover?.scale
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.hover?.scale
                    : undefined,
            );

            setActionProceed(
                currentHotspot?.currentHotspot.actions?.appFeedback?.proceed
                    ? currentHotspot?.currentHotspot.actions?.appFeedback.proceed
                    : false,
            );

            setActionFeedbackDelay(
                currentHotspot?.currentHotspot.actions?.appFeedback?.delay
                    ? currentHotspot?.currentHotspot.actions?.appFeedback.delay
                    : 0,
            );

            setUnsavedChanges(false);
        }
    }

    useEffect(() => {
        getHotspotData();
    }, [currentHotspot]);

    useEffect(() => {
        if (menuOpen) {
            menuPopulate();
        }
    }, [menuOpen]);

    function menuPopulate() {
        let tempMenuOptions = [];

        if (preClickTitle === undefined) {
            tempMenuOptions.push({
                component: (
                    <VTButton
                        type={"list"}
                        onClick={() => {
                            setPreClickTitle("");
                            setMenuOpen(false);
                            setUnsavedChanges(true);
                            menuPopulate();
                        }}
                        sx={{ width: "100%", textAlign: "center" }}
                    >
                        Add preClick Title
                    </VTButton>
                ),
            });
        }

        if (postClickTitle === undefined) {
            tempMenuOptions.push({
                component: (
                    <VTButton
                        type={"list"}
                        onClick={() => {
                            setPostClickTitle("");
                            setMenuOpen(false);
                            setUnsavedChanges(true);
                            menuPopulate();
                        }}
                        sx={{ width: "100%", textAlign: "center" }}
                    >
                        Add postClick Title
                    </VTButton>
                ),
            });
        }

        if (preClickIcon === undefined || preClickIcon === "No Icon") {
            tempMenuOptions.push({
                component: (
                    <VTButton
                        type={"list"}
                        onClick={() => {
                            setPreClickIcon("");
                            setMenuOpen(false);
                            setUnsavedChanges(true);
                            menuPopulate();
                        }}
                        sx={{ width: "100%", textAlign: "center" }}
                    >
                        Add preClick Icon
                    </VTButton>
                ),
            });
        }

        if (postClickIcon === undefined || postClickIcon === "No Icon") {
            tempMenuOptions.push({
                component: (
                    <VTButton
                        type={"list"}
                        onClick={() => {
                            setPostClickIcon("");
                            setMenuOpen(false);
                            setUnsavedChanges(true);
                            menuPopulate();
                        }}
                        sx={{ width: "100%", textAlign: "center" }}
                    >
                        Add postClick Icon
                    </VTButton>
                ),
            });
        }
        if (actionSceneId === undefined) {
            tempMenuOptions.push({
                component: (
                    <VTButton
                        type={"list"}
                        onClick={() => {
                            setActionSceneId("");
                            if (videoCurrentState === "Question") {
                                setActionProceed(false);
                            }
                            setMenuOpen(false);
                            setUnsavedChanges(true);
                            menuPopulate();
                        }}
                        sx={{ width: "100%", textAlign: "center" }}
                    >
                        Change scene on Click
                    </VTButton>
                ),
            });
        }

        if (actionSceneDelay === undefined && actionSceneId !== undefined) {
            tempMenuOptions.push({
                component: (
                    <VTButton
                        type={"list"}
                        onClick={() => {
                            setActionSceneDelay(3);
                            setMenuOpen(false);
                            setUnsavedChanges(true);
                            menuPopulate();
                        }}
                        sx={{ width: "100%", textAlign: "center" }}
                    >
                        Delay while changing scene
                    </VTButton>
                ),
            });
        }

        if (scale === undefined) {
            tempMenuOptions.push({
                component: (
                    <VTButton
                        type={"list"}
                        onClick={() => {
                            setScale(1);
                            setMenuOpen(false);
                            setUnsavedChanges(true);
                            menuPopulate();
                        }}
                        sx={{ width: "100%", textAlign: "center" }}
                    >
                        Change hotspot scale
                    </VTButton>
                ),
            });
        }

        if (actionFeedbackDelay === 0 && actionProceed === true) {
            tempMenuOptions.push({
                component: (
                    <VTButton
                        type={"list"}
                        onClick={() => {
                            setActionFeedbackDelay(3);
                            setMenuOpen(false);
                            setUnsavedChanges(true);
                            menuPopulate();
                        }}
                        sx={{ width: "100%", textAlign: "center" }}
                    >
                        Add delay to proceeding the video
                    </VTButton>
                ),
            });
        }

        setMenuOptions(tempMenuOptions);
    }

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Save current data

    function createNewHotspot() {
        if (currentHotspot.currentHotspot) {
            return {
                id: currentHotspot.currentHotspot.id,
                location: currentHotspot?.currentHotspot.location,
                type: currentHotspot?.currentHotspot.type,
                styles: {
                    preClickStyles: {
                        ...currentHotspot.currentHotspot.styles?.preClickStyles,
                        scale: scale !== 1 ? scale : undefined,
                        hover: {
                            ...currentHotspot.currentHotspot.styles?.preClickStyles?.hover,
                            scale: hoverScale
                        }
                    },
                    postClickStyles: {
                        ...currentHotspot.currentHotspot.styles?.postClickStyles,
                        scale:
                            scale !== 1 && currentHotspot.currentHotspot.styles?.postClickStyles?.scale === undefined
                                ? scale
                                : undefined,
                    },
                },
                contents: {
                    preClickContent: {
                        ...currentHotspot.currentHotspot.contents?.preClickContent,
                        title: preClickTitle !== "" && preClickTitle !== undefined ? preClickTitle : undefined,
                        icon: preClickIcon !== undefined && preClickIcon !== undefined ? preClickIcon : undefined,
                    },
                    postClickContent: {
                        ...currentHotspot.currentHotspot.contents?.postClickContent,
                        title: postClickTitle !== "" && postClickTitle !== undefined ? postClickTitle : undefined,
                        icon: postClickIcon !== undefined && postClickIcon !== undefined ? postClickIcon : undefined,
                    },
                },
                actions: {
                    ...currentHotspot.currentHotspot.actions,
                    movement:
                        actionSceneId || actionSceneDelay
                            ? {
                                  sceneId:
                                      actionSceneId !== "" && actionSceneId !== undefined ? actionSceneId : undefined,
                                  delay:
                                      actionSceneDelay !== 0 && actionSceneDelay !== undefined
                                          ? actionSceneDelay
                                          : undefined,
                              }
                            : undefined,

                    appFeedback: {
                        ...currentHotspot.currentHotspot.actions?.appFeedback,
                        proceed: actionProceed !== false ? actionProceed : undefined,
                        delay: actionProceed && actionFeedbackDelay !== 0 ? actionFeedbackDelay : undefined,
                    },
                },
            };
        }
    }

    function saveHotspot() {
        if (currentHotspot.currentHotspot) {
            const newHotspot: IHotspot = createNewHotspot()!;

            //--------------------------------------------------------------------------------------------------------
            // If Answer hotspot
            if (videoCurrentState === "Question") {
                dispatch(
                    setCurrentHotspot({
                        currentHotspot: newHotspot,
                        open: false,
                    }),
                );


                // Pushes the new scenario
                updateQuestionHotspot(newHotspot);
            }
            //--------------------------------------------------------------------------------------------------------
            // If scene hotspot
            else {
                setHotspotLoading(newHotspot.id);
                dispatch(
                    setCurrentHotspot({
                        currentHotspot: newHotspot,
                        open: false,
                    }),
                );

                // Pushes the new scenario

                updateHotspot(newHotspot);
            }
        }
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available images
    return (
        <>
            {currentlyDragging === undefined && currentAttachedObject === undefined && (
                <>
                    <Paper
                        style={{
                            width: "100px",
                            height: "40px",
                            position: "absolute",
                            left: "40px",
                            top: unsavedChanges ? "75px" : 0,
                            zIndex: 100,
                            overflow: "hidden",
                            borderRadius: "0px 0px 30px 30px",
                            // transition: "0.5s",
                        }}
                    >
                        <Grid container sx={{ width: "100%", marginTop: "5px" }} justifyContent={"space-evenly"}>
                            <Grid
                                item
                                onClick={() => {
                                    saveHotspot();
                                }}
                            >
                                <SaveRounded color="success" sx={{ cursor: "pointer" }} />
                            </Grid>

                            <Grid
                                item
                                onClick={() => {
                                    getHotspotData();
                                }}
                            >
                                <CloseRounded color="error" sx={{ cursor: "pointer" }} />
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper
                        style={{
                            width: "calc(100% - " + (currentInterface ? 400 : 0) + "px)",
                            height: "75px",
                            position: "absolute",
                            borderRadius: 0,
                            top: 0,
                            zIndex: 101,
                            // opacity: 0,
                            overflow: "hidden",
                        }}
                    >
                        <Grid container sx={{ height: "100%" }} alignItems="center">
                            <Grid
                                item
                                container
                                alignItems="center"
                                sx={{
                                    width: `calc(100vw - 200px - ${currentSidePanel.currentWidth}px)`,
                                    height: "70px",
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                }}
                            >
                                {videoCurrentState === "Question" && (
                                    <>
                                        <Tooltip title={"Proceed video on Click"} placement={"top"}>
                                            <Grid item>
                                                <VTButton
                                                    type={"icon"}
                                                    onClick={() => {
                                                        {
                                                            setActionProceed(!actionProceed);
                                                            setUnsavedChanges(true);
                                                            setActionSceneId(undefined);
                                                        }
                                                    }}
                                                    sx={
                                                        actionProceed
                                                            ? {
                                                                  margin: "14px",
                                                                  backgroundColor: "#008800",
                                                                  ":hover": {
                                                                      backgroundColor: "#00aa00",
                                                                  },
                                                              }
                                                            : {
                                                                  margin: "14px",
                                                                  backgroundColor: "#cc0000",
                                                                  ":hover": {
                                                                      backgroundColor: "#ff0000",
                                                                  },
                                                              }
                                                    }
                                                >
                                                    {actionProceed ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
                                                </VTButton>
                                            </Grid>
                                        </Tooltip>

                                        {actionProceed && actionFeedbackDelay !== 0 && (
                                            <Tooltip
                                                title={"Delay proceeding video on click (seconds)"}
                                                placement={"top"}
                                            >
                                                <Grid item>
                                                    <TextField
                                                        sx={{
                                                            margin: "3px",
                                                            borderRadius: "15px",
                                                            width: "80px",
                                                        }}
                                                        type={"number"}
                                                        value={actionFeedbackDelay}
                                                        onChange={(e) => {
                                                            setActionFeedbackDelay(parseInt(e.target.value));
                                                            setUnsavedChanges(true);
                                                        }}
                                                    />
                                                </Grid>
                                            </Tooltip>
                                        )}
                                    </>
                                )}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Pre Click title */}
                                {preClickTitle !== undefined && (
                                    <Tooltip title={"PreClick title"} placement={"top"}>
                                        <Grid item>
                                            <form
                                                onSubmit={(event: any) => {
                                                    event.preventDefault();
                                                    saveHotspot();
                                                }}
                                            >
                                                <VTTextField
                                                    autoFocus
                                                    value={preClickTitle}
                                                    onChange={(e) => {
                                                        setPreClickTitle(e);
                                                        setUnsavedChanges(true);
                                                    }}
                                                    sx={{ width: "200px", marginLeft: "10px" }}
                                                ></VTTextField>
                                            </form>
                                        </Grid>
                                    </Tooltip>
                                )}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Post Click title */}
                                {postClickTitle !== undefined && (
                                    <Tooltip title={"PostClick title"} placement={"top"}>
                                        <Grid item>
                                            <form
                                                onSubmit={(event: any) => {
                                                    event.preventDefault();
                                                    saveHotspot();
                                                }}
                                            >
                                                <VTTextField
                                                    value={postClickTitle}
                                                    onChange={(e) => {
                                                        setPostClickTitle(e);
                                                        setUnsavedChanges(true);
                                                    }}
                                                    sx={{ width: "200px", marginLeft: "10px" }}
                                                />
                                            </form>
                                        </Grid>
                                    </Tooltip>
                                )}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Pre Click Icon */}
                                {preClickIcon !== undefined && preClickIcon !== "No Icon" && (
                                    <Tooltip title={"PreClick icon"} placement={"top"}>
                                        <VTIconDropDown
                                            currentIcon={preClickIcon}
                                            setCurrentIcon={setPreClickIcon}
                                            setUnsavedChanges={setUnsavedChanges}
                                            sx={{ marginLeft: "10px" }}
                                        />
                                    </Tooltip>
                                )}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Post Click title */}
                                {postClickIcon !== undefined && postClickIcon !== "No Icon" && (
                                    <Tooltip title={"PostClick icon"} placement={"top"}>
                                        <VTIconDropDown
                                            currentIcon={postClickIcon}
                                            setCurrentIcon={setPostClickIcon}
                                            setUnsavedChanges={setUnsavedChanges}
                                            sx={{ marginLeft: "10px" }}
                                        />
                                    </Tooltip>
                                )}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Movement scene id */}
                                {actionSceneId !== undefined && (
                                    <Tooltip title={"Move to scene on Click"} placement={"top"}>
                                        <Grid item>
                                            <Select
                                                value={actionSceneId}
                                                onChange={(e) => {
                                                    setActionSceneId(e.target.value);
                                                    setUnsavedChanges(true);
                                                }}
                                                sx={{
                                                    width: "200px",
                                                    marginLeft: "10px",
                                                    marginTop: "3px",
                                                }}
                                            >
                                                {scenes &&
                                                    scenes
                                                        .filter((sceneElement: IScene) => sceneElement.id !== sceneId)
                                                        .map((scene: IScene) => (
                                                            <MenuItem value={scene.id}>{scene.name}</MenuItem>
                                                        ))}
                                                <MenuItem value={undefined}>{"No Scene"}</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Tooltip>
                                )}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Movement scene change delay */}
                                {actionSceneId && actionSceneDelay && actionSceneDelay !== 0 && (
                                    <Tooltip title={"Delay move to scene on Click"} placement={"top"}>
                                        <Grid item>
                                            <VTTextField
                                                sx={{
                                                    width: "90px",
                                                    marginLeft: "10px",
                                                    marginTop: "1px",
                                                }}
                                                type={"number"}
                                                value={actionSceneDelay.toString()}
                                                onChange={(e) => {
                                                    setActionSceneDelay(parseInt(e));
                                                    setUnsavedChanges(true);
                                                }}
                                            />
                                        </Grid>
                                    </Tooltip>
                                )}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Hotspot scale */}
                                {scale !== undefined && (
                                    <Tooltip title={"Scale hotspot"} placement={"top"}>
                                        <Grid item>
                                            <Slider
                                                sx={{
                                                    marginLeft: "15px",
                                                    marginTop: "30px",
                                                    width: "200px",
                                                }}
                                                defaultValue={1}
                                                getAriaValueText={() => scale.toString()}
                                                step={0.1}
                                                valueLabelDisplay="auto"
                                                max={2}
                                                min={0.5}
                                                value={scale}
                                                // @ts-ignore
                                                onChange={(e) => {
                                                      setScale(
                                                        // @ts-ignore
                                                        e.target.value,
                                                    );
                                                    // @ts-ignore
                                                    if (e.target.value > hoverScale) {
                                                        // @ts-ignore
                                                        setHoverScale(e.target.value);

                                                    }



                                                }}
                                                onChangeCommitted={() => setUnsavedChanges(true)}
                                            />
                                        </Grid>
                                    </Tooltip>
                                )}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Open editor button */}
                                {currentHotspot.currentHotspot?.type !== EDraggableOptions.MOVEMENT_3D && (
                                    <Tooltip title={"Add hotspot option"} placement={"top"}>
                                        <Grid item>
                                            <VTMenu
                                                type={"icon"}
                                                sx={{
                                                    margin: "14px",
                                                }}
                                                menuOptions={
                                                    menuOptions.length > 0
                                                        ? menuOptions
                                                        : [
                                                              {
                                                                  title: "Open Editor for more options",
                                                                  onClick: () => {
                                                                      setMenuOpen(false);
                                                                      dispatch(
                                                                          setCurrentHotspot({
                                                                              currentHotspot:
                                                                                  currentHotspot.currentHotspot,
                                                                              open: true,
                                                                          }),
                                                                      );
                                                                  },
                                                              },
                                                          ]
                                                }
                                                popOutMenuZIndex={1000}
                                                menuOpen={menuOpen}
                                                setMenuOpen={() => setMenuOpen}
                                                onClick={() => {
                                                    setMenuOpen(!menuOpen);
                                                }}
                                                onCloseFunction={() => setMenuOpen(false)}
                                            >
                                                <AddRounded />
                                            </VTMenu>
                                        </Grid>
                                    </Tooltip>
                                )}
                            </Grid>

                            <Grid
                                container
                                sx={{ width: "120px" }}
                                justifyContent={"space-evenly"}
                                alignContent={"center"}
                            >
                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Open editor button */}
                                {currentHotspot.currentHotspot?.type !== EDraggableOptions.MOVEMENT_3D && (
                                    <Tooltip title={"Open the hotspot editor"} placement={"top"}>
                                        <Grid item>
                                            <VTButton
                                                type={"icon"}
                                                onClick={() =>
                                                    dispatch(
                                                        setCurrentHotspot({
                                                            currentHotspot: createNewHotspot(),
                                                            open: true,
                                                        }),
                                                    )
                                                }
                                            >
                                                <SettingsRounded />
                                            </VTButton>
                                        </Grid>
                                    </Tooltip>
                                )}

                                {/*-------------------------------------------------------------------------------------*/}
                                {/* Close quick editor button */}

                                <Tooltip title={"Close the edit panel"} placement={"top-start"}>
                                    <Grid item>
                                        <VTButton
                                            type={"icon"}
                                            onClick={() =>
                                                dispatch(
                                                    setCurrentHotspot({
                                                        currentHotspot: undefined,
                                                        open: false,
                                                    }),
                                                )
                                            }
                                        >
                                            <CloseRounded />
                                        </VTButton>
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Paper>
                </>
            )}
        </>
    );
}
