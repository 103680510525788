//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import {
    VTButton,
    VTCanvas,
    VTCube,
    VTSphere,
    VTTextField,
    VTVideoControls,
} from "@virtus-tech-repository/virtus-tech-repository";

// Material ui Imports
import { CircularProgress, Grid, LinearProgress, Switch, SxProps, Tooltip, Typography, useTheme } from "@mui/material";

// Custom components imports (and hooks and helpers)
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllAudioMutation,
    useCreateAllImagesMutation,
    useCreateAllImmersiveImagesMutation,
    useCreateAllVideosMutation,
    usePreSignedUrlMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import { Params, useParams } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import VTAudioRecord from "./VTAudioRecord";
import * as THREE from "three";
import { Svg } from "@react-three/drei";
import VTVideo from "./VTVideo";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##

// Layout stuff remains inline, other styling pulled into its own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sx?: SxProps;
    context: "Immersive Images" | "Immersive Videos" | "Images" | "Videos" | "Audio";
    onComplete?: () => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTMediaUploader({ sx, context, onComplete }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const { scenarioId }: Readonly<Params<string>> = useParams();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const theme = useTheme();

    const [createAllImmersiveImages] = useCreateAllImmersiveImagesMutation();
    const [createImmersiveVideos] = useCreateAllImagesMutation();
    const [createAllImages] = useCreateAllImagesMutation();
    const [createAllVideos] = useCreateAllVideosMutation();
    const [createAllAudio] = useCreateAllAudioMutation();

    const [selectedMedia, setSelectedMedia] = useState<File | null>(null);
    const [selectedMediaError, setSelectedMediaError] = useState<boolean>(false);
    const [mediaName, setMediaName] = useState<string>("");
    const [mediaId, setMediaId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);
    const [recordModelOpen, setRecordModelOpen] = useState<boolean>(false);

    const cameraControlsRef = useRef<any>(null);
    const [currentImageCapture, setCurrentImageCapture] = useState<string | null>(null);

    const dispatch = useAppDispatch();
    const [imageSphereTexture, setImageSphereTexture] = useState<any>();
    const [previewLoading, setPreviewLoading] = useState<boolean>(false);
    const [loadingState, setLoadingState] = useState<boolean>(false);

    const [video, setVideo] = useState<HTMLVideoElement>(document.createElement("video"));
    const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
    const [videoCurrentState, setVideoCurrentState] = useState<"Paused" | "Playing" | "Question" | undefined>(
        undefined,
    );
    const [videoChecked, setVideoChecked] = useState<boolean>(false);

    const svgRef = useRef<any>(null);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    useEffect(() => {
        dispatch(setMediaUploadCleanUp());
        setLoadingState(false);
    }, []);

    useEffect(() => {
        if (mediaUploadCompleted && selectedMedia) {
            if (context === "Immersive Images") {
                setPreviewLoading(true);
                const loader = new THREE.TextureLoader();

                setImageSphereTexture(
                    loader.load(
                        `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${mediaId}/raw.jpg`,
                        () => setPreviewLoading(false),
                    ),
                );
            } else if (context === "Images") {
                createAllImages({
                    id: userId,
                    media_id: mediaId,
                    media_type: "images",
                    filetype: selectedMedia.type.split("/")[1],
                    name: mediaName,
                    description: "",
                    date: Date.now(),
                    tags: [],
                    preview: "",
                });
            } else if (context === "Audio") {
                createAllAudio({
                    id: userId,
                    media_id: mediaId,
                    media_type: "audio",
                    filetype: selectedMedia.type.split("/")[1],
                    name: mediaName,
                    description: "",
                    date: Date.now(),
                    tags: [],
                    preview: false,
                });
            }
        }
    }, [mediaUploadCompleted]);

    useEffect(() => {
        if (uploadUrl && currentImageCapture) {
            handlePreviewUpload();
        }
    }, [uploadUrl]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    async function handlePreviewUpload() {
        if (currentImageCapture) {
            const file = await dataUrlToFile(currentImageCapture, "preview");

            if (uploadUrl && currentImageCapture) {
                dispatch(
                    uploadMedia({
                        media: file,
                        url: uploadUrl.url,
                        dispatch: dispatch,
                    }),
                );
            }

            handleUploadCleanUp();
        }
    }

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: "image/png" });
    }

    function takeScreenShot() {
        if (cameraControlsRef.current) {
            cameraControlsRef.current.takeScreenShot();
        }
    }

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(mediaFile: File) {
        console.log("DBG", mediaFile);
        // TODO: in the future only allow certain Media types
        if (context === "Immersive Images" && mediaFile.type.includes("image/") && !mediaFile.type.includes("/svg")) {
            setSelectedMediaError(false);
            setSelectedMedia(mediaFile);
            setMediaName(mediaFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation,
                media_type: "three_sixty_image",
                media_id: `${mediaId}/raw`,
                filetype: "jpg",
            });
        } else if (context === "Immersive Videos" && mediaFile.type.includes("video/")) {
            setSelectedMediaError(false);
            setSelectedMedia(mediaFile);
            setMediaName(mediaFile.name.split(".")[0]);

            if (videoChecked) {
                // Get presigned url to upload video
                await preSignedUrl({
                    organisation,
                    media_type: "three_sixty_video",
                    media_id: `${mediaId}/raw`,
                    filetype: mediaFile.type.split("/")[1],
                });
            } else {
                // Get presigned url to upload video
                await preSignedUrl({
                    organisation,
                    media_type: "three_sixty_video",
                    media_id: `${mediaId}/${mediaId}`,
                    filetype: mediaFile.type.split("/")[1],
                });
            }
        } else if (mediaFile.type.includes("image/") && !mediaFile.type.includes("/svg")) {
            setSelectedMediaError(false);
            setSelectedMedia(mediaFile);
            setMediaName(mediaFile.name.split(".")[0]);

            // Get presigned url to upload Media
            await preSignedUrl({
                organisation,
                media_type: "images",
                media_id: mediaId,
                filetype: mediaFile.type.split("/")[1],
            });
        } else if (mediaFile.type.includes("video/")) {
            setSelectedMediaError(false);
            setSelectedMedia(mediaFile);
            setMediaName(mediaFile.name.split(".")[0]);

            // Get presigned url to upload video
            await preSignedUrl({
                organisation,
                media_type: "video",
                media_id: `${mediaId}/${mediaId}`,
                filetype: mediaFile.type.split("/")[1],
            });
        } else if (mediaFile.type.includes("audio/")) {
            setSelectedMediaError(false);
            setSelectedMedia(mediaFile);
            setMediaName(mediaFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation,
                media_type: "audio",
                media_id: `${mediaId}`,
                filetype: mediaFile.type.split("/")[1],
            });
        } else {
            setSelectedMediaError(true);
        }
    }

    function handleUploadCleanUp() {
        setSelectedMedia(null);
        setMediaId(uuid());
        setMediaName("");
        dispatch(setMediaUploadCleanUp());
        setImageSphereTexture(false);
        setPreviewLoading(false);
        setCurrentImageCapture(null);
        setSelectedMediaError(false);
        if (onComplete) {
            onComplete();
        }
    }

    // Function that handle media added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle media added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleMediaUpload() {
        if (uploadUrl && selectedMedia) {
            dispatch(
                uploadMedia({
                    media: selectedMedia,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <Grid item container xs={12} justifyContent={"center"} sx={{ ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Media upload */}

            {loadingState ? (
                <CircularProgress sx={{ marginTop: "200px" }} />
            ) : (
                <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                    {(context === "Immersive Images" || context === "Immersive Videos" || context === "Videos") &&
                    mediaUploadCompleted ? (
                        <Grid container justifyContent={"center"} sx={{ maxWidth: "800px" }}>
                            <Grid item xs={12} container justifyContent={"center"} sx={{ margin: "10px 5px" }}>
                                <VTTextField
                                    value={mediaName}
                                    onChange={(value) => {}}
                                    disabled={true}
                                    sx={{ width: "100%", maxWidth: "800px" }}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ position: "relative" }}>
                                {previewLoading && (
                                    <Tooltip
                                        title={"Please wait for the preview to load, this may take awhile"}
                                        disableHoverListener={!previewLoading}
                                    >
                                        <CircularProgress
                                            sx={{ position: "absolute", top: "47%", left: "50%", zIndex: 100000 }}
                                        />
                                    </Tooltip>
                                )}

                                {selectedMedia && (
                                    <VTCanvas
                                        ref={cameraControlsRef}
                                        style={{
                                            backgroundColor: "#a9a9a9",
                                            height: "400px",
                                            width: "100%",
                                        }}
                                        screenShot={(capture) => setCurrentImageCapture(capture)}
                                        attachToCameraRef={svgRef}
                                        distanceFromCamera={1}
                                        disableCameraControls={context === "Videos"}
                                    >
                                        {context === "Immersive Images" && (
                                            <mesh rotation={[Math.PI, 0, 0]}>
                                                <sphereBufferGeometry args={[-200, 64, 800]} />
                                                <meshBasicMaterial map={imageSphereTexture} />
                                            </mesh>
                                        )}

                                        {context === "Immersive Videos" && (
                                            <VTSphere
                                                video={video}
                                                setVideo={setVideo}
                                                videoSrc={`${
                                                    process.env.REACT_APP_NINJA_MEDIA
                                                }/${organisation}/three_sixty_video/${mediaId}/${mediaId}.${
                                                    selectedMedia.type.split("/")[1]
                                                }`}
                                                setVideoCurrentTime={setVideoCurrentTime}
                                            />
                                        )}
                                        {context === "Videos" && (
                                            <VTVideo
                                                video={video}
                                                setVideo={setVideo}
                                                videoSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/video/${mediaId}/${mediaId}.mp4`}
                                                setVideoCurrentTime={setVideoCurrentTime}
                                            />
                                        )}

                                        <group ref={svgRef} position={[0, 10, -70]}>
                                            <meshStandardMaterial opacity={0} />
                                        </group>
                                    </VTCanvas>
                                )}

                                {(context === "Immersive Videos" || context === "Videos") && (
                                    <VTVideoControls
                                        videoCurrentTime={videoCurrentTime}
                                        video={video}
                                        videoCurrentState={videoCurrentState}
                                        setVideoCurrentState={setVideoCurrentState}
                                    />
                                )}
                            </Grid>

                            <>
                                <LinearProgress variant="determinate" value={mediaUploadPercentage} />
                                <Tooltip
                                    title={"Please wait for the preview to load, this may take awhile"}
                                    disableHoverListener={!previewLoading}
                                >
                                    <Grid
                                        xs={6}
                                        sx={{
                                            margin: "10px 0",
                                            display: "flex",
                                            justifyContent: "center",
                                            padding: "2px",
                                        }}
                                    >
                                        <VTButton
                                            color={"secondary"}
                                            onClick={() => {
                                                handleUploadCleanUp();
                                            }}
                                            fullWidth
                                            disabled={previewLoading}
                                        >
                                            Cancel Upload
                                        </VTButton>
                                    </Grid>
                                </Tooltip>
                                <Tooltip
                                    title={"Please wait for the preview to load, this may take awhile"}
                                    disableHoverListener={!previewLoading}
                                >
                                    <Grid
                                        xs={6}
                                        sx={{
                                            margin: "10px 0",
                                            display: "flex",
                                            justifyContent: "center",
                                            padding: "2px",
                                        }}
                                    >
                                        <VTButton
                                            color={"primary"}
                                            fullWidth={true}
                                            disabled={previewLoading}
                                            onClick={async () => {
                                                setLoadingState(true);
                                                takeScreenShot();
                                                setSelectedMediaError(false);
                                                if (context === "Immersive Images") {
                                                    createAllImmersiveImages({
                                                        id: userId,
                                                        media_id: mediaId,
                                                        media_type: "three_sixty_image",
                                                        filetype: "jpg",
                                                        name: mediaName,
                                                        description: "",
                                                        date: Date.now(),
                                                        tags: [],
                                                        preview: "",
                                                        chopping: "processing",
                                                        startLocation: [
                                                            svgRef.current.position.x,
                                                            svgRef.current.position.y,
                                                            svgRef.current.position.z,
                                                        ],
                                                    });

                                                    setMediaName(`${mediaId}_preview`);

                                                    // Get presigned url to upload image
                                                    await preSignedUrl({
                                                        organisation,
                                                        media_type: "three_sixty_image",
                                                        media_id: `${mediaId}/${mediaId}_preview`,
                                                        filetype: "jpg",
                                                    });
                                                } else if (context === "Immersive Videos") {
                                                    if (videoChecked) {
                                                        createImmersiveVideos({
                                                            id: userId,
                                                            media_id: mediaId,
                                                            media_type: "three_sixty_video",
                                                            filetype: "mp4",
                                                            name: mediaName,
                                                            description: "",
                                                            date: Date.now(),
                                                            tags: [],
                                                            preview: "",
                                                            organisation: organisation,
                                                            chopping: "processing",
                                                            startLocation: [
                                                                svgRef.current.position.x,
                                                                svgRef.current.position.y,
                                                                svgRef.current.position.z,
                                                            ],
                                                        });
                                                    } else {
                                                        createImmersiveVideos({
                                                            id: userId,
                                                            media_id: mediaId,
                                                            media_type: "three_sixty_video",
                                                            filetype: "mp4",
                                                            name: mediaName,
                                                            description: "",
                                                            date: Date.now(),
                                                            tags: [],
                                                            preview: "",
                                                            organisation: organisation,
                                                            startLocation: [
                                                                svgRef.current.position.x,
                                                                svgRef.current.position.y,
                                                                svgRef.current.position.z,
                                                            ],
                                                        });
                                                    }

                                                    setMediaName(`${mediaId}_preview`);

                                                    // Get presigned url to upload image
                                                    await preSignedUrl({
                                                        organisation,
                                                        media_type: "three_sixty_video",
                                                        media_id: `${mediaId}/${mediaId}_preview`,
                                                        filetype: "jpg",
                                                    });
                                                } else if (context === "Videos") {
                                                    createAllVideos({
                                                        id: userId,
                                                        media_id: mediaId,
                                                        media_type: "video",
                                                        filetype: "mp4",
                                                        name: mediaName,
                                                        description: "",
                                                        date: Date.now(),
                                                        tags: [],
                                                        preview: false,
                                                        startLocation: [
                                                            svgRef.current.position.x,
                                                            svgRef.current.position.y,
                                                            svgRef.current.position.z,
                                                        ],
                                                    });

                                                    setMediaName(`${mediaId}_preview`);

                                                    // Get presigned url to upload image
                                                    await preSignedUrl({
                                                        organisation,
                                                        media_type: "video",
                                                        media_id: `${mediaId}/${mediaId}_preview`,
                                                        filetype: "jpg",
                                                    });
                                                }
                                            }}
                                        >
                                            {context === "Videos" ? "Set Preview Image" : " Set Start location"}
                                        </VTButton>
                                    </Grid>
                                </Tooltip>
                            </>
                        </Grid>
                    ) : selectedMedia ? (
                        <Grid container justifyContent={"center"}>
                            <Grid item sx={{ margin: "10px 5px", width: "100%", maxWidth: "800px" }}>
                                <VTTextField
                                    value={mediaName}
                                    onChange={(value) => setMediaName(value)}
                                    disabled={mediaUploading || mediaUploadCompleted}
                                />
                            </Grid>
                            {(context === "Immersive Images" || context === "Images") && (
                                <Grid item sx={{ margin: "10px 5px", width: "100%", maxWidth: "800px" }}>
                                    <img
                                        src={URL.createObjectURL(selectedMedia)}
                                        alt="Selected"
                                        width={"100%"}
                                        style={{
                                            borderRadius: "15px",
                                            objectFit: "contain",
                                            maxHeight: "50vh",
                                        }}
                                    />
                                </Grid>
                            )}

                            {(context === "Immersive Videos" || context === "Videos") && (
                                <Grid sx={{ margin: "10px 5px", width: "100%", maxWidth: "800px" }}>
                                    {context === "Immersive Videos" && (
                                        <Grid
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Typography sx={{ marginTop: "6px" }}>Process 360 Video</Typography>
                                            <Switch
                                                checked={videoChecked}
                                                onChange={() => setVideoChecked(!videoChecked)}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        </Grid>
                                    )}
                                    <video
                                        width={"100%"}
                                        controls
                                        style={{
                                            borderRadius: "15px",
                                            objectFit: "contain",
                                            maxHeight: "50vh",
                                        }}
                                    >
                                        <source src={URL.createObjectURL(selectedMedia)} type="video/mp4" />
                                    </video>
                                </Grid>
                            )}

                            {context === "Audio" && (
                                <Grid xs={12} sx={{ margin: "10px 5px", maxWidth: "800px" }}>
                                    <ReactAudioPlayer
                                        src={URL.createObjectURL(selectedMedia)}
                                        autoPlay={false}
                                        controls
                                        style={{
                                            width: "100%",
                                            borderRadius: "15px",
                                            objectFit: "contain",
                                            maxHeight: "50vh",
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid
                                item
                                container
                                sx={{
                                    width: "100%",
                                    maxWidth: "800px",
                                }}
                            >
                                {preSignedUrlLoading ? (
                                    <Grid
                                        xs={12}
                                        sx={{
                                            margin: "10px 5px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    <>
                                        {mediaUploading || mediaUploadCompleted ? (
                                            <>
                                                {mediaUploadError ? (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                                align="center"
                                                            >
                                                                Error Uploading Media
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={"secondary"}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                Cancel
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <LinearProgress
                                                                variant="determinate"
                                                                value={mediaUploadPercentage}
                                                            />
                                                        </Grid>
                                                        {mediaUploadCompleted && (
                                                            <Grid
                                                                xs={6}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    padding: "2px",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={"secondary"}
                                                                    fullWidth={true}
                                                                    onClick={() => {
                                                                        setSelectedMedia(null);
                                                                        setMediaId(uuid());
                                                                        setMediaName("");
                                                                        dispatch(setMediaUploadCleanUp());
                                                                    }}
                                                                    sx={{ height: "35px" }}
                                                                >
                                                                    Add Another
                                                                </VTButton>
                                                            </Grid>
                                                        )}
                                                        <Grid
                                                            xs={mediaUploadCompleted ? 6 : 12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "2px",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={mediaUploadCompleted ? "primary" : "secondary"}
                                                                fullWidth={true}
                                                                onClick={() => handleUploadCleanUp()}
                                                                sx={{ height: "35px" }}
                                                            >
                                                                {mediaUploadCompleted ? "Done" : "Cancel"}
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Grid
                                                    xs={6}
                                                    item
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton
                                                        color={"secondary"}
                                                        onClick={() => setSelectedMedia(null)}
                                                        fullWidth={true}
                                                        sx={{ height: "35px" }}
                                                    >
                                                        Cancel
                                                    </VTButton>
                                                </Grid>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton
                                                        sx={{ height: "35px" }}
                                                        onClick={() => handleMediaUpload()}
                                                        fullWidth={true}
                                                    >
                                                        upload
                                                    </VTButton>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            //TODO: user this as an overlay
                            onDragEnter={() => setHighlight(true)}
                            onDragLeave={() => setHighlight(false)}
                            sx={{
                                border: highlight
                                    ? `3px dashed ${theme.palette.primary.main}`
                                    : `1px dashed ${theme.palette.primary.light}`,
                                borderRadius: "15px",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <Grid
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "50px 0px",
                                }}
                            >
                                <img
                                    src={"/media/images/upload.svg"}
                                    alt="upload media"
                                    style={{ maxHeight: "200px" }}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Typography align="center">
                                    Drop or choose {(context === "Immersive Images" || context === "Images") && "image"}{" "}
                                    {context === "Videos" && "video"} {context === "Audio" && "audio file"} to upload
                                </Typography>

                                {selectedMediaError && (
                                    <Typography align="center" sx={{ color: "red" }}>
                                        {context === "Immersive Images" && "File types accepted: JPG"}
                                        {context === "Immersive Videos" && "File types accepted: MP4"}
                                        {context === "Images" && "File types accepted: JPG, JPEG, PNG"}
                                        {context === "Videos" && "File types accepted: MP4"}
                                        {context === "Audio" && "File types accepted: MP4, MP3"}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                xs={12}
                                sx={{
                                    margin: "50px 0px",
                                }}
                                justifyContent={"center"}
                                container
                            >
                                {" "}
                                {context !== "Audio" && (
                                    <Grid item>
                                        <VTButton
                                            color={"secondary"}
                                            onClick={() => handleUploadCleanUp()}
                                            sx={{ height: "35px", marginRight: "10px" }}
                                        >
                                            Cancel
                                        </VTButton>
                                    </Grid>
                                )}
                                <input
                                    accept={
                                        context === "Immersive Images" || context === "Images"
                                            ? "image/*"
                                            : context === "Audio"
                                            ? "audio/*"
                                            : "video/mp4"
                                    }
                                    type="file"
                                    id="file"
                                    ref={inputFileRef}
                                    style={{ display: "none" }}
                                    onChange={(e) => handleSelect(e)}
                                />
                                <VTButton
                                    onClick={() => {
                                        if (inputFileRef && inputFileRef.current) {
                                            inputFileRef.current.click();
                                        }
                                    }}
                                    sx={{ maxHeight: "35px" }}
                                >
                                    Choose File
                                </VTButton>
                                {context === "Audio" && (
                                    <>
                                        <VTButton
                                            onClick={() => setRecordModelOpen(true)}
                                            disabled={recordModelOpen}
                                            color={"secondary"}
                                            sx={{ marginLeft: "10px" }}
                                        >
                                            <Typography>Record Audio </Typography>
                                        </VTButton>
                                        <Grid item sx={{ width: "100%" }} container justifyContent={"center"}>
                                            <VTButton
                                                color={"secondary"}
                                                onClick={() => handleUploadCleanUp()}
                                                sx={{
                                                    height: "35px",
                                                    marginRight: "10px",
                                                    width: "200px",
                                                    marginTop: "15px",
                                                }}
                                            >
                                                Cancel
                                            </VTButton>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    <VTAudioRecord
                        open={recordModelOpen}
                        handleClose={() => {
                            setRecordModelOpen(false);
                            if (onComplete) {
                                onComplete();
                            }
                        }}
                    />
                </div>
            )}
        </Grid>
    );
}
