//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports

import { Box, CircularProgress, Grid, Skeleton, Tooltip, Typography } from "@mui/material";
import { VTButton, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { useGetScenesQuery } from "../../services/scene.service";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Params, useNavigate, useParams } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useRef, useState } from "react";
import VTVideoTimelineElement from "./VTVideoTimelineElement";
import { blankQuestionHotspot } from "../../helpers/hotspotDataGenerator";
import { useDispatch } from "react-redux";
import { setCurrentHotspot, setCurrentQuestion } from "../../store/slices/current.slice";

// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sortedQuestions: IHotspot[];
    videoNextQuestion: IHotspot | undefined;
    handleTimelineItemClicked: (time: number) => void;
    bottomDrawWidth: number;
    videoTimelineLoading: string;
    setVideoTimelineLoading: (videoTimelineLoading: string) => void;
    video: any;
    videoCurrentTime: any;
    createHotspot: any;
    setVideoNextQuestion: any;
    setVideoCurrentState: any;
    setTimelineMode: any;
    videoCurrentState: string;
    timeOfCurrentlySelectedQuestion: number | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function VTVideoTimeline({
    sortedQuestions,
    videoNextQuestion,
    handleTimelineItemClicked,
    bottomDrawWidth,
    videoTimelineLoading,
    setVideoTimelineLoading,
    video,
    videoCurrentTime,
    createHotspot,
    setVideoNextQuestion,
    setVideoCurrentState,
    setTimelineMode,
    videoCurrentState,
    timeOfCurrentlySelectedQuestion,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useDispatch();
    const { scenarioId }: Readonly<Params<string>> = useParams();
    const { data: scenes } = useGetScenesQuery(scenarioId ? scenarioId : "");
    const navigate = useNavigate();
    const vtCreatorTimelineRef = useRef<HTMLDivElement>(null);

    const [disableVideoTimelineElementMainButtonGlow, setDisableVideoTimelineElementMainButtonGlow] =
        useState<boolean>(true);

    useEffect(() => {
        if (videoCurrentState === "Question") {
            setDisableVideoTimelineElementMainButtonGlow(false);
        }
    }, [videoCurrentState]);

    useEffect(() => {
        // if (videoTimelineLoading === "createStopPoint") {
        //     window.setTimeout(() => {
        //         vtCreatorTimelineRef.current?.scrollBy({
        //             left: 1000000,
        //             top: 0,
        //             behavior: "smooth",
        //         });
        //     }, 100);
        // } else
        if (videoTimelineLoading === "unload") {
            window.setTimeout(() => {
                vtCreatorTimelineRef.current?.scrollBy({
                    left: 1000000,
                    top: 0,
                    behavior: "smooth",
                });
            }, 500);
        }
    }, [videoTimelineLoading]);

    const indexOfNextVideoQuestion = sortedQuestions.findIndex(
        (question: IHotspot) => question.id === videoNextQuestion?.id,
    );

    const idOfVideoQuestionPreviousToNext =
        indexOfNextVideoQuestion !== -1 && indexOfNextVideoQuestion !== 0
            ? sortedQuestions[indexOfNextVideoQuestion - 1].id
            : undefined;

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return sortedQuestions.length !== 0 ? (
        <Box
            ref={vtCreatorTimelineRef}
            component="div"
            sx={{
                zIndex: 120,
                height: "100%",
                overflowX: "scroll",
                overflowY: "scroll",
                paddingTop: "30px",
                width: "100%",
                // background: "lime",
                paddingLeft: "30px",
                display: "flex",
                // flexDirection:

                // marginBottom: "30px",
                // marginRight: "20px",
                // background: "lime"
            }}
            // direction={"column"}
            // alignContent={"flex-start"}
            // xs={12}
        >
            {sortedQuestions.map((questionElement: IHotspot, index: number) => {
                return (
                    <VTVideoTimelineElement
                        videoCurrentState={videoCurrentState}
                        sortedQuestions={sortedQuestions}
                        videoNextQuestion={videoNextQuestion}
                        questionElement={questionElement}
                        index={index}
                        handleTimelineItemClicked={handleTimelineItemClicked}
                        idOfVideoQuestionPreviousToNext={idOfVideoQuestionPreviousToNext}
                        timeOfCurrentlySelectedQuestion={timeOfCurrentlySelectedQuestion}
                        disableMainButtonGlow={disableVideoTimelineElementMainButtonGlow}
                    />
                );
            })}
            {videoTimelineLoading === "createStopPoint" && (
                <Grid
                    item
                    container
                    sx={{
                        marginLeft: "-40px",
                        marginRight: "30px",
                        height: "100%",
                        width: "84px",
                    }}
                    alignContent={"flex-start"}
                    justifyContent={"flex-start"}
                >
                    <Skeleton
                        variant="circular"
                        width={40}
                        height={3}
                        sx={{
                            marginTop: "18.5px",
                        }}
                    />
                    <Skeleton variant="circular" width={40} height={40} />
                </Grid>
            )}

            {/* {videoCurrentState !== "Question" && videoTimelineLoading !== "createStopPoint" && (
                <Tooltip title={"Add stop point"} placement={"top"}>
                    <Grid
                        item
                        container
                        sx={{
                            marginRight: "30px",
                            height: "100%",
                            width: "89px",
                        }}
                        alignContent={"flex-start"}
                        justifyContent={"flex-start"}
                    >
                        <VTButton
                            type={"icon"}
                            sx={{
                                width: "40px",
                                height: "40px",
                                background: "linear-gradient(to bottom, #0ab5a7,#8e25fa)",
                                transition: ".5s",
                                ":hover": {
                                    boxShadow: "0 0 25px #fff",
                                },
                            }}
                            onClick={() => {
                                video.pause();
                                setVideoTimelineLoading("createStopPoint");
                                const newStopPoint = blankQuestionHotspot(videoCurrentTime);
                                createHotspot({ sceneId: sceneId!, hotspot: newStopPoint });
                                dispatch(setCurrentQuestion(newStopPoint));
                                setVideoNextQuestion(newStopPoint);
                                setVideoCurrentState("Question");
                                dispatch(
                                    setCurrentHotspot({
                                        currentHotspot: undefined,
                                        open: false,
                                    }),
                                );
                                setTimelineMode("Timeline");
                            }}
                        >
                            <VTTypography sx={{ marginTop: "-5px" }}>+</VTTypography>
                        </VTButton>
                    </Grid>
                </Tooltip>
            )} */}
        </Box>
    ) : (
        <Grid container sx={{ height: "100%", width: "100%" }} justifyContent={"center"} alignContent={"center"}>
            <CircularProgress />
        </Grid>
    );
}
