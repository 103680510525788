import { alpha, Box, Breakpoint, Grid, SxProps, Tooltip } from "@mui/material";
import { useGetMuiBreakPointLabel, VTButton, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import { InfoRounded } from "@mui/icons-material";
import { useAppSelector } from "../../store/hooks";

interface IHomeSettingsItem {
    title: string;
    value: boolean;
    setValue: (value: boolean) => void;
    info?: string;
    heading1: string;
    heading2: string;
    sx?: SxProps;
}

export default function HomeSettingsItem({ title, value, setValue, info, heading1, heading2, sx }: IHomeSettingsItem) {
    const { currentSidePanel } = useAppSelector((state) => state.currentReducer);
    const breakPoint = useGetMuiBreakPointLabel();

    function getLeftAndRightPaddingValueDependingOnScreenWidth(breakPoint: Breakpoint): number {
        switch (breakPoint) {
            case "xl":
                return 105;
            case "lg":
                return 70;
            default:
                return 0;
        }
    }

    const additionalPaddingOffset = getLeftAndRightPaddingValueDependingOnScreenWidth(breakPoint);

    return (
        <Box
            component="div"
            sx={{
                border: `1px solid ${alpha("#777", 0.5)}`,
                height: "60px",
                borderRadius: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: `${13 + (currentSidePanel.currentTab !== "Images" ? additionalPaddingOffset : 0)}px`,
                paddingRight: `${13 + (currentSidePanel.currentTab !== "Images" ? additionalPaddingOffset : 0)}px`,
                ...sx,
            }}
        >
            <VTTypography type="Subtitle1" sx={{ display: "flex", alignItems: "center" }}>
                {title}
                {info && (
                    <Tooltip title={info}>
                        <InfoRounded sx={{ fontSize: "1.2em", marginLeft: "5px" }} />
                    </Tooltip>
                )}
            </VTTypography>

            <Box
                component="div"
                sx={{
                    // if screen size is xl or lg this will center buttons
                    ...(additionalPaddingOffset > 0 &&
                        currentSidePanel.currentTab !== "Images" && {
                            width: "480px",
                            display: "flex",
                            justifyContent: "center",
                        }),
                }}
            >
                <Box component="div">
                    <VTButton
                        color={!value ? "primary" : "secondary"}
                        sx={{
                            borderRadius: "15px 0 0 15px",
                        }}
                        onClick={() => setValue(false)}
                    >
                        {heading1}
                    </VTButton>
                    <VTButton
                        color={value ? "primary" : "secondary"}
                        sx={{
                            borderRadius: "0 15px 15px 0",
                        }}
                        onClick={() => setValue(true)}
                    >
                        {heading2}
                    </VTButton>
                </Box>
            </Box>
        </Box>
    );
}
