import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetScenesQuery } from "../../services/scene.service";
import { Params, useParams } from "react-router-dom";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { useAppSelector } from "../../store/hooks";
import { VTHTMLHotspot } from "@virtus-tech-repository/virtus-tech-repository";
import { useGetHotspotsQuery } from "../../services/hotspot.service";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IconSwitch } from "../../helpers/IconSwitchHelper";
import { hotspotHasPostClickContent } from "./VTHotspotHTMLWrapper";

interface IProps {
    hotspotOpen: boolean;
    setHotspotOpen: (hotspotOpen: boolean) => void;
    currentHotspotElement: IHotspot;
}

export default function VTHotspotPreview({ currentHotspotElement, hotspotOpen, setHotspotOpen }: IProps) {
    const [hotspotHovered, setHotspotHovered] = useState<boolean>(false);
    const { currentScenario } = useAppSelector((state) => state.currentReducer);

    return (
        <>
            {hotspotOpen &&
            hotspotHasPostClickContent(currentHotspotElement) &&
            currentHotspotElement.contents.postClickContent ? (
                <VTHTMLHotspot
                    onClose={undefined}
                    onClick={() => setHotspotOpen(false)}
                    title={currentHotspotElement.contents.postClickContent.title}
                    // @ts-ignore
                    icon={IconSwitch(currentHotspotElement.contents.postClickContent.icon)}
                    onHover={(mouseEvent: boolean) => {
                        setHotspotHovered(mouseEvent);
                    }}
                    sx={
                        hotspotHovered
                            ? {
                                  "> div:nth-child(1) > div": {
                                      border: `3px solid ${
                                          currentHotspotElement.styles?.postClickStyles?.hover?.colours?.border &&
                                          `rgba(${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.border.r}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.border.g}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.border.b}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.border.a})`
                                      }`,
                                      background: `${
                                          currentHotspotElement.styles?.postClickStyles?.hover?.colours?.background
                                              ? `rgba(${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.background.r}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.background.g}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.background.b}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.background.a})`
                                              : "rgba(30,30,30,0.5)"
                                      } `,
                                      h3: {
                                          color: `${
                                              currentHotspotElement.styles?.postClickStyles?.hover?.colours?.text
                                                  ? `rgba(${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.text.r}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.text.g}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.text.b}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.text.a})`
                                                  : "#fff"
                                          } !important`,
                                      },
                                  },
                                  "> div:nth-child(2) > div": {
                                      background: `${
                                          currentHotspotElement.styles?.postClickStyles?.hover?.colours?.background
                                              ? `rgba(${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.background.r}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.background.g}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.background.b}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.background.a})`
                                              : "rgba(30,30,30,0.5)"
                                      } `,
                                      border: `3px solid ${
                                          currentHotspotElement.styles?.postClickStyles?.hover?.colours?.border &&
                                          `rgba(${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.border.r}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.border.g}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.border.b}, ${currentHotspotElement.styles?.postClickStyles?.hover?.colours?.border.a})`
                                      }`,
                                      div: {
                                          color: `${
                                              currentHotspotElement.styles?.postClickStyles?.colours?.text
                                                  ? `rgba(${currentHotspotElement.styles?.postClickStyles?.colours?.text.r}, ${currentHotspotElement.styles?.postClickStyles?.colours?.text.g}, ${currentHotspotElement.styles?.postClickStyles?.colours?.text.b}, ${currentHotspotElement.styles?.postClickStyles?.colours?.text.a})`
                                                  : "#fff"
                                          }`,
                                      },
                                  },
                              }
                            : {
                                  "> div:nth-child(1) > div": {
                                      border: `3px solid ${
                                          currentHotspotElement.styles?.postClickStyles?.colours?.border &&
                                          `rgba(${currentHotspotElement.styles?.postClickStyles?.colours?.border.r}, ${currentHotspotElement.styles?.postClickStyles?.colours?.border.g}, ${currentHotspotElement.styles?.postClickStyles?.colours?.border.b}, ${currentHotspotElement.styles?.postClickStyles?.colours?.border.a})`
                                      }`,
                                      background: `${
                                          currentHotspotElement.styles?.postClickStyles?.colours?.background
                                              ? `rgba(${currentHotspotElement.styles?.postClickStyles?.colours?.background.r}, ${currentHotspotElement.styles?.postClickStyles?.colours?.background.g}, ${currentHotspotElement.styles?.postClickStyles?.colours?.background.b}, ${currentHotspotElement.styles?.postClickStyles?.colours?.background.a})`
                                              : "rgba(30,30,30,0.5)"
                                      } `,
                                      h3: {
                                          color: `${
                                              currentHotspotElement.styles?.postClickStyles?.colours?.text
                                                  ? `rgba(${currentHotspotElement.styles?.postClickStyles?.colours?.text.r}, ${currentHotspotElement.styles?.postClickStyles?.colours?.text.g}, ${currentHotspotElement.styles?.postClickStyles?.colours?.text.b}, ${currentHotspotElement.styles?.postClickStyles?.colours?.text.a})`
                                                  : "#fff"
                                          } !important`,
                                      },
                                  },
                                  "> div:nth-child(2) > div": {
                                      background: `${
                                          currentHotspotElement.styles?.postClickStyles?.colours?.background
                                              ? `rgba(${currentHotspotElement.styles?.postClickStyles?.colours?.background.r}, ${currentHotspotElement.styles?.postClickStyles?.colours?.background.g}, ${currentHotspotElement.styles?.postClickStyles?.colours?.background.b}, ${currentHotspotElement.styles?.postClickStyles?.colours?.background.a})`
                                              : "rgba(30,30,30,0.5)"
                                      } `,
                                      border: `3px solid ${
                                          currentHotspotElement.styles?.postClickStyles?.colours?.border &&
                                          `rgba(${currentHotspotElement.styles?.postClickStyles?.colours?.border.r}, ${currentHotspotElement.styles?.postClickStyles?.colours?.border.g}, ${currentHotspotElement.styles?.postClickStyles?.colours?.border.b}, ${currentHotspotElement.styles?.postClickStyles?.colours?.border.a})`
                                      }`,
                                      div: {
                                          color: `${
                                              currentHotspotElement.styles?.postClickStyles?.colours?.text
                                                  ? `rgba(${currentHotspotElement.styles?.postClickStyles?.colours?.text.r}, ${currentHotspotElement.styles?.postClickStyles?.colours?.text.g}, ${currentHotspotElement.styles?.postClickStyles?.colours?.text.b}, ${currentHotspotElement.styles?.postClickStyles?.colours?.text.a})`
                                                  : "#fff"
                                          }`,
                                      },
                                  },
                              }
                    }
                    scale2d={
                        hotspotHovered
                            ? currentHotspotElement.styles?.postClickStyles?.hover?.scale
                                ? currentHotspotElement.styles?.postClickStyles?.hover?.scale * 0.65
                                : 0.65
                            : currentHotspotElement.styles?.postClickStyles?.scale
                            ? currentHotspotElement.styles?.postClickStyles?.scale * 0.65
                            : 0.65
                    }
                    vtRichTextEditorContent={
                        currentScenario && currentScenario.settings && !currentScenario.settings.vrEnabled
                            ? currentHotspotElement.contents.postClickContent.contentData &&
                              typeof currentHotspotElement.contents.postClickContent.contentData === "string" &&
                              currentHotspotElement.contents.postClickContent.contentData.replace(/\s+/g, "") !==
                                  `<divclass="rte-container"></div>`
                                ? {
                                      content: currentHotspotElement.contents.postClickContent.contentData,
                                  }
                                : Array.isArray(currentHotspotElement.contents.postClickContent?.contentData)
                                ? {
                                      content: currentHotspotElement.contents.postClickContent?.contentData,
                                      width: currentHotspotElement.contents.postClickContent
                                          ?.presentationModeContentWidthHeight?.w
                                          ? currentHotspotElement.contents.postClickContent
                                                ?.presentationModeContentWidthHeight.w
                                          : 350,
                                      height: currentHotspotElement.contents.postClickContent
                                          ?.presentationModeContentWidthHeight?.h
                                          ? currentHotspotElement.contents.postClickContent
                                                ?.presentationModeContentWidthHeight.h
                                          : 220,
                                  }
                                : undefined
                            : undefined
                    }
                />
            ) : (
                <VTHTMLHotspot
                    onClose={undefined}
                    onClick={() => setHotspotOpen(true)}
                    title={currentHotspotElement.contents.preClickContent.title}
                    icon={IconSwitch(currentHotspotElement.contents.preClickContent.icon)}
                    onHover={(mouseEvent: boolean) => {
                        setHotspotHovered(mouseEvent);
                    }}
                    sx={
                        hotspotHovered
                            ? {
                                  "> div:nth-child(1) > div": {
                                      border: `3px solid ${
                                          currentHotspotElement.styles?.preClickStyles?.hover?.colours?.border &&
                                          `rgba(${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.border.r}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.border.g}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.border.b}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.border.a})`
                                      }`,
                                      background: `${
                                          currentHotspotElement.styles?.preClickStyles?.hover?.colours?.background
                                              ? `rgba(${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.background.r}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.background.g}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.background.b}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.background.a})`
                                              : "rgba(30,30,30,0.5)"
                                      } `,
                                      h3: {
                                          color: `${
                                              currentHotspotElement.styles?.preClickStyles?.hover?.colours?.text
                                                  ? `rgba(${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.text.r}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.text.g}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.text.b}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.text.a})`
                                                  : "#fff"
                                          } !important`,
                                      },
                                  },
                                  "> div:nth-child(2) > div": {
                                      background: `${
                                          currentHotspotElement.styles?.preClickStyles?.hover?.colours?.background
                                              ? `rgba(${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.background.r}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.background.g}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.background.b}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.background.a})`
                                              : "rgba(30,30,30,0.5)"
                                      } `,
                                      border: `3px solid ${
                                          currentHotspotElement.styles?.preClickStyles?.hover?.colours?.border &&
                                          `rgba(${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.border.r}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.border.g}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.border.b}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.border.a})`
                                      }`,
                                      div: {
                                          color: `${
                                              currentHotspotElement.styles?.preClickStyles?.hover?.colours?.text
                                                  ? `rgba(${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.text.r}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.text.g}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.text.b}, ${currentHotspotElement.styles?.preClickStyles?.hover?.colours?.text.a})`
                                                  : "#fff"
                                          }`,
                                      },
                                  },
                              }
                            : {
                                  "> div:nth-child(1) > div":
                                      (currentHotspotElement.styles?.preClickStyles?.colours?.border === undefined ||
                                          (currentHotspotElement.styles?.preClickStyles?.colours?.border !==
                                              undefined &&
                                              currentHotspotElement.styles?.preClickStyles?.colours?.border.a === 0)) &&
                                      currentHotspotElement.styles?.preClickStyles?.colours?.background !== undefined &&
                                      currentHotspotElement.styles?.preClickStyles?.colours?.background.a === 0 &&
                                      currentHotspotElement.styles?.preClickStyles?.colours?.text !== undefined &&
                                      currentHotspotElement.styles?.preClickStyles?.colours?.text.a === 0
                                          ? {
                                                border: `3px solid ${
                                                    currentHotspotElement.styles?.preClickStyles?.colours?.border &&
                                                    `rgba(${currentHotspotElement.styles?.preClickStyles?.colours?.border.r}, ${currentHotspotElement.styles?.preClickStyles?.colours?.border.g}, ${currentHotspotElement.styles?.preClickStyles?.colours?.border.b}, ${currentHotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                }`,
                                                background: `${
                                                    currentHotspotElement.styles?.preClickStyles?.colours?.background
                                                        ? `rgba(${currentHotspotElement.styles?.preClickStyles?.colours?.background.r}, ${currentHotspotElement.styles?.preClickStyles?.colours?.background.g}, ${currentHotspotElement.styles?.preClickStyles?.colours?.background.b}, ${currentHotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                        : "rgba(30,30,30,0.5)"
                                                } `,
                                                h3: {
                                                    color: `${
                                                        currentHotspotElement.styles?.preClickStyles?.colours?.text
                                                            ? `rgba(${currentHotspotElement.styles?.preClickStyles?.colours?.text.r}, ${currentHotspotElement.styles?.preClickStyles?.colours?.text.g}, ${currentHotspotElement.styles?.preClickStyles?.colours?.text.b}, ${currentHotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                            : "#fff"
                                                    } !important`,
                                                },
                                                boxShadow: "0 0 25px #fff",
                                            }
                                          : {
                                                border: `3px solid ${
                                                    currentHotspotElement.styles?.preClickStyles?.colours?.border &&
                                                    `rgba(${currentHotspotElement.styles?.preClickStyles?.colours?.border.r}, ${currentHotspotElement.styles?.preClickStyles?.colours?.border.g}, ${currentHotspotElement.styles?.preClickStyles?.colours?.border.b}, ${currentHotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                }`,
                                                background: `${
                                                    currentHotspotElement.styles?.preClickStyles?.colours?.background
                                                        ? `rgba(${currentHotspotElement.styles?.preClickStyles?.colours?.background.r}, ${currentHotspotElement.styles?.preClickStyles?.colours?.background.g}, ${currentHotspotElement.styles?.preClickStyles?.colours?.background.b}, ${currentHotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                        : "rgba(30,30,30,0.5)"
                                                } `,
                                                h3: {
                                                    color: `${
                                                        currentHotspotElement.styles?.preClickStyles?.colours?.text
                                                            ? `rgba(${currentHotspotElement.styles?.preClickStyles?.colours?.text.r}, ${currentHotspotElement.styles?.preClickStyles?.colours?.text.g}, ${currentHotspotElement.styles?.preClickStyles?.colours?.text.b}, ${currentHotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                            : "#fff"
                                                    } !important`,
                                                },
                                            },
                                  "> div:nth-child(2) > div": {
                                      background: `${
                                          currentHotspotElement.styles?.preClickStyles?.colours?.background
                                              ? `rgba(${currentHotspotElement.styles?.preClickStyles?.colours?.background.r}, ${currentHotspotElement.styles?.preClickStyles?.colours?.background.g}, ${currentHotspotElement.styles?.preClickStyles?.colours?.background.b}, ${currentHotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                              : "rgba(30,30,30,0.5)"
                                      } `,
                                      border: `3px solid ${
                                          currentHotspotElement.styles?.preClickStyles?.colours?.border &&
                                          `rgba(${currentHotspotElement.styles?.preClickStyles?.colours?.border.r}, ${currentHotspotElement.styles?.preClickStyles?.colours?.border.g}, ${currentHotspotElement.styles?.preClickStyles?.colours?.border.b}, ${currentHotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                      }`,
                                      div: {
                                          color: `${
                                              currentHotspotElement.styles?.preClickStyles?.colours?.text
                                                  ? `rgba(${currentHotspotElement.styles?.preClickStyles?.colours?.text.r}, ${currentHotspotElement.styles?.preClickStyles?.colours?.text.g}, ${currentHotspotElement.styles?.preClickStyles?.colours?.text.b}, ${currentHotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                  : "#fff"
                                          }`,
                                      },
                                  },
                              }
                    }
                    scale2d={
                        hotspotHovered
                            ? currentHotspotElement.styles?.preClickStyles?.hover?.scale
                                ? currentHotspotElement.styles?.preClickStyles?.hover.scale * 0.65
                                : 0.65
                            : currentHotspotElement.styles?.preClickStyles?.scale
                            ? currentHotspotElement.styles?.preClickStyles?.scale * 0.65
                            : 0.65
                    }
                    vtRichTextEditorContent={
                        currentScenario && currentScenario.settings && !currentScenario.settings.vrEnabled
                            ? currentHotspotElement.contents.preClickContent.contentData &&
                              typeof currentHotspotElement.contents.preClickContent.contentData === "string" &&
                              currentHotspotElement.contents.preClickContent.contentData.replace(/\s+/g, "") !==
                                  `<divclass="rte-container"></div>`
                                ? {
                                      content: currentHotspotElement.contents.preClickContent.contentData,
                                  }
                                : Array.isArray(currentHotspotElement.contents.preClickContent?.contentData)
                                ? {
                                      content: currentHotspotElement.contents.preClickContent?.contentData,
                                      width: currentHotspotElement.contents.preClickContent
                                          ?.presentationModeContentWidthHeight?.w
                                          ? currentHotspotElement.contents.preClickContent
                                                ?.presentationModeContentWidthHeight.w
                                          : 350,
                                      height: currentHotspotElement.contents.preClickContent
                                          ?.presentationModeContentWidthHeight?.h
                                          ? currentHotspotElement.contents.preClickContent
                                                ?.presentationModeContentWidthHeight.h
                                          : 220,
                                  }
                                : undefined
                            : undefined
                    }
                />
            )}
        </>
    );
}
