//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Card, CardContent, CardMedia, Grid, Paper, Skeleton, Tooltip } from "@mui/material";
import { current } from "@reduxjs/toolkit";
import { VTButton, VTTextField, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import { useEffect, useState } from "react";
import { AddRounded, CancelRounded, SaveRounded, EditRounded, TimerRounded } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useAppSelector } from "../../store/hooks";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { useUpdateHotspotMutation } from "../../services/hotspot.service";
// Custom components imports (and hooks and helpers)

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    hotspot: IHotspot;
    marginTop: string;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorContainer({ hotspot, marginTop }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const [updateHotspot] = useUpdateHotspotMutation();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    const { currentlyPreview } = useAppSelector((state) => state.currentReducer);
    const [editing, setEditing] = useState<boolean>(false);
    const [currentTitle, setCurrentTitle] = useState<string>("");

    const [editingTimer, setEditingTimer] = useState<boolean>(false);
    const [currentTimer, setCurrentTimer] = useState<number>(0);
    const [previewTimer, setPreviewTimer] = useState<number>(0);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (hotspot.contents.preClickContent.title) {
            setCurrentTitle(hotspot.contents.preClickContent.title);
        } else {
            setCurrentTitle("");
        }
    }, [hotspot.contents.preClickContent.title]);

    useEffect(() => {
        if (hotspot.contents.preClickContent.question?.countdown) {
            setCurrentTimer(hotspot.contents.preClickContent.question?.countdown);
        } else {
            setCurrentTimer(0);
        }
    }, [hotspot.contents.preClickContent.question?.countdown]);

    useEffect(() => {
        setPreviewTimer(currentTimer);
    }, [currentTimer, currentlyPreview]);

    useEffect(() => {
        if (previewTimer > 0) {
            const timer = setTimeout(() => {
                setPreviewTimer(previewTimer - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [previewTimer]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Render skeleton cards
    return (
        <>
            <Grid
                container
                sx={{
                    position: "absolute",
                    top: marginTop,
                    left: "10%",
                    width: "80%",
                    zIndex: 10,
                }}
                justifyContent={"center"}
            >
                {!(currentlyPreview && currentTitle === "") && (
                    <Paper
                        sx={{
                            backgroundColor: "rgba(0,0,0,0.6)",
                            padding: "15px",
                            zIndex: 1000,
                            width: "50%",
                            marginRight: "20px",
                        }}
                    >
                        <Grid item container xs={12} justifyContent={"center"}>
                            {editing ? (
                                <Grid item xs={11}>
                                    <form
                                        onSubmit={() => {
                                            updateHotspot({
                                                id: hotspot.id,
                                                contents: {
                                                    preClickContent: {
                                                        ...hotspot.contents.preClickContent,
                                                        title: currentTitle,
                                                    },
                                                },
                                            });
                                            setEditing(false);
                                        }}
                                    >
                                        <VTTextField
                                            value={currentTitle}
                                            onChange={(e) => setCurrentTitle(e)}
                                            autoFocus
                                            endIcon={
                                                <>
                                                    <VTButton
                                                        type={"icon"}
                                                        sx={{
                                                            backgroundColor: "rgba(0,0,0,0)",
                                                            color: "#f44336",
                                                            ":hover": {
                                                                backgroundColor: "rgba(100,100,100,4)",
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            setCurrentTitle(
                                                                hotspot.contents.preClickContent.title
                                                                    ? hotspot.contents.preClickContent.title
                                                                    : "",
                                                            );
                                                            setEditing(false);
                                                        }}
                                                    >
                                                        <CloseRoundedIcon />
                                                    </VTButton>
                                                    <VTButton
                                                        type={"icon"}
                                                        sx={{
                                                            backgroundColor: "rgba(0,0,0,0)",
                                                            color: "#66bb6a",
                                                            ":hover": {
                                                                backgroundColor: "rgba(100,100,100,4)",
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            updateHotspot({
                                                                id: hotspot.id,
                                                                contents: {
                                                                    preClickContent: {
                                                                        ...hotspot.contents.preClickContent,
                                                                        title: currentTitle,
                                                                    },
                                                                },
                                                            });
                                                            setEditing(false);
                                                        }}
                                                    >
                                                        <SaveRounded />
                                                    </VTButton>
                                                </>
                                            }
                                        />
                                    </form>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    onClick={() => {
                                        if (!currentlyPreview && !hotspot.id.includes("demo")) {
                                            setEditing(true);
                                        }
                                    }}
                                >
                                    {currentTitle === "" ? (
                                        <Tooltip title={"Add question posed to the use on the stop point"}>
                                            <Grid item>
                                                <VTButton
                                                    type={"icon"}
                                                    sx={{
                                                        backgroundColor: "rgba(0,0,0,0)",
                                                        ":hover": {
                                                            backgroundColor: "rgba(100,100,100,4)",
                                                        },
                                                        marginTop: "-10px",
                                                        marginBottom: "-10px",
                                                    }}
                                                >
                                                    <AddRounded />
                                                </VTButton>
                                            </Grid>
                                        </Tooltip>
                                    ) : (
                                        <>
                                            <Tooltip
                                                title={"Edit question posed to the use on the stop point"}
                                                disableHoverListener={currentlyPreview}
                                            >
                                                <Grid item>
                                                    <VTTypography>
                                                        {currentTitle}
                                                        {!currentlyPreview && !hotspot.id.includes("demo") && (
                                                            <EditRounded
                                                                sx={{
                                                                    marginLeft: "10px",
                                                                    marginBottom: "-5px",
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                        )}
                                                    </VTTypography>
                                                </Grid>
                                            </Tooltip>
                                        </>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                )}

                {!(currentlyPreview && currentTimer === 0) && (
                    <Paper
                        sx={{
                            zIndex: 1000,
                            backgroundColor: "rgba(0,0,0,0.6)",
                            padding: "15px",
                        }}
                    >
                        <Grid item container xs={12} justifyContent={"center"}>
                            {editingTimer ? (
                                <Grid item sx={{ width: "180px" }}>
                                    {" "}
                                    <form
                                        onSubmit={() => {
                                            updateHotspot({
                                                id: hotspot.id,
                                                contents: {
                                                    preClickContent: {
                                                        ...hotspot.contents.preClickContent,
                                                        question: {
                                                            ...hotspot.contents.preClickContent.question,
                                                            countdown: currentTimer!,
                                                            time: hotspot.contents.preClickContent.question!.time,
                                                            answers: hotspot.contents.preClickContent.question!.answers,
                                                        },
                                                    },
                                                },
                                            });
                                            setEditing(false);
                                        }}
                                    >
                                        <VTTextField
                                            value={currentTimer.toString()}
                                            onChange={(e) => {
                                                if (/^-?\d+$/.test(e)) {
                                                    setCurrentTimer(parseInt(e));
                                                } else if (e === "") {
                                                    setCurrentTimer(0);
                                                }
                                            }}
                                            autoFocus
                                            type="number"
                                            endIcon={
                                                <>
                                                    <VTButton
                                                        type={"icon"}
                                                        sx={{
                                                            backgroundColor: "rgba(0,0,0,0)",
                                                            color: "#f44336",
                                                            ":hover": {
                                                                backgroundColor: "rgba(100,100,100,4)",
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            setCurrentTimer(
                                                                hotspot.contents.preClickContent.question?.countdown
                                                                    ? hotspot.contents.preClickContent.question
                                                                          ?.countdown
                                                                    : 0,
                                                            );
                                                            setEditingTimer(false);
                                                        }}
                                                    >
                                                        <CloseRoundedIcon />
                                                    </VTButton>
                                                    <VTButton
                                                        type={"icon"}
                                                        sx={{
                                                            backgroundColor: "rgba(0,0,0,0)",
                                                            color: "#66bb6a",
                                                            ":hover": {
                                                                backgroundColor: "rgba(100,100,100,4)",
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            updateHotspot({
                                                                id: hotspot.id,
                                                                contents: {
                                                                    preClickContent: {
                                                                        ...hotspot.contents.preClickContent,
                                                                        title: currentTitle,
                                                                        question: {
                                                                            ...hotspot.contents.preClickContent
                                                                                .question,
                                                                            countdown: currentTimer!,
                                                                            time: hotspot.contents.preClickContent
                                                                                .question!.time,
                                                                            answers:
                                                                                hotspot.contents.preClickContent
                                                                                    .question!.answers,
                                                                        },
                                                                    },
                                                                },
                                                            });
                                                            setEditingTimer(false);
                                                        }}
                                                    >
                                                        <SaveRounded />
                                                    </VTButton>
                                                </>
                                            }
                                        />
                                    </form>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    onClick={() => {
                                        if (!currentlyPreview) {
                                            setEditingTimer(true);
                                        }
                                    }}
                                >
                                    {currentTimer === 0 ? (
                                        <Tooltip title={"Add countdown posed to the use on the stop point"}>
                                            <Grid item>
                                                <VTButton
                                                    type={"icon"}
                                                    sx={{
                                                        backgroundColor: "rgba(0,0,0,0)",
                                                        ":hover": {
                                                            backgroundColor: "rgba(100,100,100,4)",
                                                        },
                                                        marginTop: "-10px",
                                                        marginBottom: "-10px",
                                                    }}
                                                >
                                                    <TimerRounded />
                                                </VTButton>
                                            </Grid>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title={"Edit countdown posed to the use on the stop point"}
                                            disableHoverListener={currentlyPreview}
                                        >
                                            <Grid item>
                                                <VTTypography>
                                                    {currentlyPreview ? previewTimer : currentTimer}
                                                    {!currentlyPreview && !hotspot.id.includes("demo") && (
                                                        <TimerRounded
                                                            sx={{
                                                                marginLeft: "10px",
                                                                marginBottom: "-6px",
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    )}
                                                </VTTypography>
                                            </Grid>
                                        </Tooltip>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                )}
            </Grid>
        </>
    );
}
