//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports

import { Grid, SxProps, Tooltip } from "@mui/material";
import { VTMenu } from "@virtus-tech-repository/virtus-tech-repository";
import { InfoRounded, ArrowDropDownRounded, BlockRounded } from "@mui/icons-material";

import { useState } from "react";
import { IconSwitch } from "../../helpers/IconSwitchHelper";

// Custom components imports (and hooks and helpers)

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    currentIcon: string;
    setCurrentIcon: (preClickIcon: string) => void;
    setUnsavedChanges?: (unsavedChanges: boolean) => void;
    sx?: SxProps;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTIconDropDown({ currentIcon, setCurrentIcon, setUnsavedChanges = () => {}, sx }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    //Get current data to populate

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available images
    return (
        <Grid item>
            <VTMenu
                sx={{
                    ...sx,
                    ".MuiIconButton-root": {
                        backgroundColor: "#444547",
                        border: "1px solid grey",
                        borderRadius: "15px",
                        paddingBlock: "18px",
                        ":hover": {
                            backgroundColor: "#444547",
                            border: "1px solid white",
                        },
                    },
                }}
                type="icon"
                menuOptions={[
                    {
                        component: (
                            <>
                                <Grid container sx={{ width: "200px", marginBottom: "5px", paddingInline: "5px" }}>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Info");

                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Info"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Info")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Movement");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Movement"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Movement")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Arrows");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Arrows"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Arrows")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Precision");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Precision"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Precision")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Support");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Support"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Support")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Architecture");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Architecture"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Architecture")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Engineering");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Engineering"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Engineering")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("HealthAndSafety");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "HealthAndSafety"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("HealthAndSafety")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Help");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Help"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Help")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Fire");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Fire"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Fire")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Dual");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Dual"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Dual")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Report");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Report"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Report")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Stairs");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Stairs"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Stairs")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Tablet");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Tablet"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Tablet")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Task");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Task"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Task")}
                                        />
                                    </Grid>{" "}
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("Warning");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "Warning"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("Warning")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent={"center"}
                                        alignContent={"center"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCurrentIcon("ArrowHead");
                                            setUnsavedChanges(true);
                                        }}
                                    >
                                        <img
                                            style={
                                                currentIcon === "ArrowHead"
                                                    ? {
                                                          backgroundColor: "rgba(132,38,223, 0.4)",
                                                          boxShadow: "0 0 15px #8426df",
                                                          borderRadius: "15px",
                                                          transition: "0.5s",
                                                      }
                                                    : {}
                                            }
                                            src={IconSwitch("ArrowHead")}
                                        />
                                    </Grid>
                                    <Tooltip title={"No Icon"}>
                                        <Grid
                                            item
                                            xs={2}
                                            container
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setCurrentIcon("No Icon");
                                                setUnsavedChanges(true);
                                            }}
                                        >
                                            <BlockRounded sx={{ color: "red" }} />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </>
                        ),
                    },
                ]}
                popOutMenuZIndex={100000}
            >
                {<img src={IconSwitch(currentIcon)} />}

                <ArrowDropDownRounded />
            </VTMenu>
        </Grid>
    );
}
