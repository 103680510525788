//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { v4 as uuid } from "uuid";

// Virtus tech Repo
import { IScenarioOverview } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { VTLandingOptions } from "@virtus-tech-repository/virtus-tech-repository";

// Material ui Imports
import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Grid, useMediaQuery } from "@mui/material";

// Custom components imports (and hooks and helpers)
import HomeScenarioDialog from "../components/HomeScenarioDialog";
import HomeSidePanelContainer from "../containers/HomeSidePanelContainer";
import HomeCardGridContainer from "../containers/HomeCardGridContainer";
import { useGetAllGroupsQuery } from "../../services/users.service";
import { useDuplicateMediaMutation, useGetAllImagesQuery } from "../../services/media.service";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentPage, setCurrentPreviewImage, setCurrentSidePanel } from "../../store/slices/current.slice";
import { blankScenarioOverview } from "../../helpers/scenarioDataGenerator";
import {
    useCreateScenarioMutation,
    useDeleteScenarioMutation,
    useDuplicateDemoScenarioMutation,
    useDuplicateScenarioMutation,
    useGetScenariosQuery,
    useUpdateScenarioMutation,
} from "../../services/scenario.service";
import { EOrganisation } from "../../models/organisations.model";
import WordingHelper from "../../helpers/wordingHelper";

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function Home() {
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    const matches = useMediaQuery("(min-width:900px)");
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const { currentSidePanel, currentPreviewImage, currentSidePannelDesiredWidth } = useAppSelector(
        (state) => state.currentReducer,
    );
    const [createScenario, { isLoading: isScenarioCreateLoading }] = useCreateScenarioMutation();
    const [updateScenario, { isLoading: isScenarioUpdateLoading }] = useUpdateScenarioMutation();
    const [deleteScenario, { isLoading: isScenarioDeleteLoading }] = useDeleteScenarioMutation();
    const [duplicateDemoScenario] = useDuplicateDemoScenarioMutation();
    const [duplicateMedia] = useDuplicateMediaMutation();

    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();
    const groups = useGetAllGroupsQuery(userId);
    const images = useGetAllImagesQuery(userId);
    const [dialogActiveStep, setDialogActiveStep] = useState<number>(0);

    const { data: scenarios } = useGetScenariosQuery(userId ? userId : "");

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [searchValue, setSearchValue] = useState<string>("");
    const [filterValue, setFilterValue] = useState<
        "All Scenarios" | "Your Scenarios" | "Group Scenarios" | "New Scenario"
    >("All Scenarios");

    const [scenarioDialog, setScenarioDialog] = useState<IScenarioOverview | undefined>(undefined);
    const [scenarioDialogFirstView, setScenarioDialogFirstView] = useState<boolean>(false);
    const [currentPreviewImage_, setCurrentPreviewImage_] = useState<string>("");

    useEffect(() => {
        if (currentPreviewImage !== "") {
            setCurrentPreviewImage_(currentPreviewImage);
            dispatch(setCurrentPreviewImage(""));
        }
    }, [currentPreviewImage]);

    const [scenarioLoading, setScenarioLoading] = useState<string>("");

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (!isScenarioCreateLoading && !isScenarioDeleteLoading) {
            if (scenarioLoading === "CreateScenario") {
                setScenarioLoading("");
            }
            if (scenarioLoading !== "") {
                setScenarioLoading("");
            }
        }
    }, [isScenarioCreateLoading, scenarioLoading]);

    useEffect(() => {
        dispatch(setCurrentPage("home"));
        if (matches) {
            dispatch(
                setCurrentSidePanel({
                    currentTab: "Routes",
                    currentWidth: 200,
                    option: undefined,
                }),
            );
        }

        ReactGA.send({ hitType: "pageview", page: "/Home", title: "Home page" });
    }, []);

    useEffect(() => {
        if (scenarios?.length === 0 && process.env.REACT_APP_DEMO === "true") {
            //duplicateDemoScenario({ userId: userId, scenarioId: "5d5f7c12-604b-4f53-ab02-a6431dc91e16" });
            duplicateMedia({ userId: userId, scenarioId: "eb4d07c7-8b7c-4b12-8f80-c967ea1749a9" });
        }
    }, [scenarios]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    let filters = [
        {
            title: `All ${WordingHelper(organisation, true)}`,
            icon: (
                <AppsRoundedIcon
                    style={{
                        fontSize: "1.7em",
                    }}
                />
            ),
            disabled: false,
            handleClick: () => {
                setFilterValue("All Scenarios");
            },
        },
        {
            title: `Your ${WordingHelper(organisation, true)}`,
            icon: (
                <PersonRoundedIcon
                    style={{
                        fontSize: "1.7em",
                    }}
                />
            ),
            disabled: false,
            handleClick: () => {
                setFilterValue("Your Scenarios");
            },
        },
        // {
        //     title: "Groups",
        //     disabled: false,
        //     icon: (
        //         <PeopleRoundedIcon
        //             style={{
        //                 fontSize: "1.7em",
        //             }}
        //         />
        //     ),
        //     handleClick: () => {
        //         setFilterValue("Group Scenarios");
        //     },
        // },
        {
            title: `New ${WordingHelper(organisation)}`,
            disabled: false,
            icon: (
                <AddRoundedIcon
                    style={{
                        fontSize: "1.7em",
                    }}
                />
            ),
            handleClick: () => {
                setScenarioDialogFirstView(true);
                dispatch(
                    setCurrentSidePanel({
                        currentTab: undefined,
                        currentWidth: 0,
                        option: undefined,
                    }),
                );
                setScenarioDialog(blankScenarioOverview(userId));
            },
        },
    ];

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <>
            <Grid
                xs={12}
                container
                sx={{ overflowY: "auto", overflowX: "hidden", height: "100%" }}
                alignContent={"flex-start"}
            >
                {/*-----------------------------------------------------------------------------------------*/}
                {/* Home landing section; search, filters and new scenario button */}
                <VTLandingOptions
                    reactiveWidth={750}
                    filters={filters}
                    filterValue={filterValue}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    sideDrawWidth={currentSidePanel.currentWidth}
                    sx={{ height: "450px" }}
                    title={organisation === EOrganisation.ECO_GOAL ? "Blog" : "Scenarios"}
                />

                {/*-----------------------------------------------------------------------------------------*/}
                {/* Home side panel; page routes, preview image list */}
                <HomeCardGridContainer
                    leftDrawWidth={currentSidePanel.currentWidth}
                    searchValue={searchValue}
                    setScenarioDialog={setScenarioDialog}
                    filterValue={filterValue}
                    scenarioLoading={scenarioLoading}
                    setScenarioLoading={setScenarioLoading}
                    deleteScenario={deleteScenario}
                />
            </Grid>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Home side panel; page routes, preview image list */}
            <HomeSidePanelContainer
                images={images.data}
                scenarioDialog={scenarioDialog}
                setCurrentPreviewImage={setCurrentPreviewImage_}
                activeStep={dialogActiveStep}
            />
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Scenario details / new scenario dialog */}
            {scenarioDialog && (
                <HomeScenarioDialog
                    scenarioDialog={scenarioDialog}
                    setScenarioDialog={setScenarioDialog}
                    groups={groups.data}
                    currentPreviewImage={currentPreviewImage_}
                    setCurrentPreviewImage={setCurrentPreviewImage_}
                    scenarioLoading={scenarioLoading}
                    setScenarioLoading={setScenarioLoading}
                    createScenario={createScenario}
                    updateScenario={updateScenario}
                    scenarioDialogFirstView={scenarioDialogFirstView}
                    setScenarioDialogFirstView={setScenarioDialogFirstView}
                    activeStep={dialogActiveStep}
                    setActiveStep={setDialogActiveStep}
                />
            )}
        </>
    );
}
