//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import ReactGA from "react-ga4";

// Material ui Imports
import { CssBaseline, Theme, ThemeProvider } from "@mui/material";
import { darkTheme } from "./themes/dark.theme";
import { lightTheme } from "./themes/light.theme";
import { SelectedTheme } from "./store/slices/theme.slice";

// Custom components imports (and hooks and helpers)
import AppRoutes from "./views/AppRoutes";
import { ecogoalTheme } from "./themes/ecogoal.theme";
import { useGetMuiBreakPointLabel } from "@virtus-tech-repository/virtus-tech-repository";
import { setCurrentSidePanelDesiredWidth } from "./store/slices/current.slice";

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
function DigiDeck() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();
    const { theme } = useAppSelector((state) => state.themeReducer);
    const currentScreenWidthBreakpoint = useGetMuiBreakPointLabel();

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        ReactGA.initialize([
            {
                trackingId: process.env.REACT_APP_DEMO === "true" ? "G-DQZQBDZ5MP" : "G-5YHHTR9NKB",
            },
        ]);
    }, []);

    useEffect(() => {
        if (currentScreenWidthBreakpoint === "md") {
            dispatch(setCurrentSidePanelDesiredWidth(275));
        } else if (currentScreenWidthBreakpoint === "sm") {
            dispatch(setCurrentSidePanelDesiredWidth(250));
        } else if (currentScreenWidthBreakpoint === "xs") {
            dispatch(setCurrentSidePanelDesiredWidth(250));
        } else {
            dispatch(setCurrentSidePanelDesiredWidth(400));
        }
    }, [currentScreenWidthBreakpoint]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    function handleTheme(): Theme {
        if (process.env.REACT_APP_ECOGOAL === "true") {
            return ecogoalTheme;
        } else if (theme !== SelectedTheme.DARK) {
            return lightTheme;
        } else {
            return darkTheme;
        }
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Gets the user details from the login and stores in redux
    return (
        <ThemeProvider theme={handleTheme()}>
            <CssBaseline />
            <AppRoutes />
        </ThemeProvider>
    );
}

export default DigiDeck;
