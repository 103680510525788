//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useLocation, useNavigate } from "react-router-dom";
// Material ui Imports
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import DocumentScannerRoundedIcon from "@mui/icons-material/DocumentScannerRounded";
import LocalGroceryStoreRoundedIcon from "@mui/icons-material/LocalGroceryStoreRounded";
import PowerRoundedIcon from "@mui/icons-material/PowerRounded";
import Rotate90DegreesCwRoundedIcon from "@mui/icons-material/Rotate90DegreesCwRounded";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import VideoLibraryRoundedIcon from "@mui/icons-material/VideoLibraryRounded";
import AudiotrackRoundedIcon from "@mui/icons-material/AudiotrackRounded";
// Custom components imports (and hooks and helpers)
import { VTList, VTSidePanel } from "@virtus-tech-repository/virtus-tech-repository";
import { ICurrentSidePanel } from "../../models/current.models";
import VTPanel from "./VTPanel";
import { useAppSelector } from "../../store/hooks";
import Icon360Image from "../../assets/icons/Icon360Image";
import Icon360Video from "../../assets/icons/Icon360Video";
import Models3DIcon from "@mui/icons-material/ViewInArRounded";
import { EOrganisation } from "../../models/organisations.model";

// Media imports
//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components
//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function HomeRouteSidePanel() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const navigate = useNavigate();
    const { currentSidePanel } = useAppSelector((state) => state.currentReducer);
    const { organisation } = useAppSelector((state) => state.userReducer);
    const location = useLocation();
    const currentSelectedPageNamePath: string = location.pathname;

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Side panel with routes
    return (
        <div style={{ overflowY: "hidden" }}>
            {/*--------------------------------------------------------------------------------------*/}
            {/* Pages List */}
            <VTList
                sx={{ width: "200px" }}
                title={"Pages"}
                listItems={
                    organisation === EOrganisation.ECO_GOAL
                        ? [
                              {
                                  name: "Home",
                                  icon: <HomeRoundedIcon />,
                                  onClick: () => navigate("/"),
                                  selected: currentSelectedPageNamePath === "/",
                              },
                          ]
                        : [
                              {
                                  name: "Home",
                                  icon: <HomeRoundedIcon />,
                                  onClick: () => navigate("/"),
                                  selected: currentSelectedPageNamePath === "/",
                              },
                              {
                                  name: "Templates",
                                  icon: <DocumentScannerRoundedIcon />,
                                  onClick: () => navigate("/Templates"),
                                  disabled: true,
                                  toolTipOptions: {
                                      toolTipText: "Coming Soon",
                                      toolTipPlacement: "right",
                                  },
                              },
                              {
                                  name: "Marketplace",
                                  icon: <LocalGroceryStoreRoundedIcon />,
                                  onClick: () => navigate("/Marketplace"),
                                  disabled: true,
                                  toolTipOptions: {
                                      toolTipText: "Coming Soon",
                                      toolTipPlacement: "right",
                                  },
                              },
                              {
                                  name: "Plugins",
                                  icon: <PowerRoundedIcon />,
                                  onClick: () => navigate("/Plugins"),
                                  disabled: true,
                                  toolTipOptions: {
                                      toolTipText: "Coming Soon",
                                      toolTipPlacement: "right",
                                  },
                              },
                          ]
                }
            />
            {/*--------------------------------------------------------------------------------------*/}
            {/* Media List */}
            <VTList
                sx={{ width: "200px" }}
                title={"Media"}
                listItems={
                    organisation === EOrganisation.ECO_GOAL
                        ? [
                              {
                                  name: "360 Images",
                                  icon: <Icon360Image sx={{ color: "white" }} />,
                                  onClick: () => navigate("/Media/ImmersiveImages"),
                                  selected: currentSelectedPageNamePath === "/Media/ImmersiveImages",
                              },
                              {
                                  name: "Images",
                                  icon: <ImageRoundedIcon />,
                                  onClick: () => navigate("/Media/Images"),
                                  selected: currentSelectedPageNamePath === "/Media/Images",
                              },

                              {
                                  name: "Videos",
                                  icon: <VideoLibraryRoundedIcon />,
                                  onClick: () => navigate("/Media/Videos"),
                                  selected: currentSelectedPageNamePath === "/Media/Videos",
                              },

                              {
                                  name: "Audio",
                                  icon: <AudiotrackRoundedIcon />,
                                  onClick: () => navigate("/Media/Audio"),
                                  selected: currentSelectedPageNamePath === "/Media/Audio",
                              },
                          ]
                        : [
                              {
                                  name: "360 Images",
                                  icon: <Icon360Image sx={{ color: "white" }} />,
                                  onClick: () => navigate("/Media/ImmersiveImages"),
                                  selected: currentSelectedPageNamePath === "/Media/ImmersiveImages",
                              },
                              {
                                  name: "360 Videos",
                                  icon: <Icon360Video sx={{ color: "white" }} />,
                                  onClick: () => navigate("/Media/ImmersiveVideos"),
                                  selected: currentSelectedPageNamePath === "/Media/ImmersiveVideos",
                              },
                              {
                                  name: "Images",
                                  icon: <ImageRoundedIcon />,
                                  onClick: () => navigate("/Media/Images"),
                                  selected: currentSelectedPageNamePath === "/Media/Images",
                              },

                              {
                                  name: "Videos",
                                  icon: <VideoLibraryRoundedIcon />,
                                  onClick: () => navigate("/Media/Videos"),
                                  selected: currentSelectedPageNamePath === "/Media/Videos",
                              },

                              {
                                  name: "Audio",
                                  icon: <AudiotrackRoundedIcon />,
                                  onClick: () => navigate("/Media/Audio"),
                                  selected: currentSelectedPageNamePath === "/Media/Audio",
                              },
                              {
                                  name: "3D Models",
                                  disabled: organisation !== EOrganisation.VIRTUS_TECH,
                                  icon: <Models3DIcon />,
                                  onClick: () => navigate("/Media/ImmersiveModels"),
                                  selected: currentSelectedPageNamePath === "/Media/ImmersiveModels",
                              },
                          ]
                }
            />
        </div>
    );
}
