import { Grid, SxProps } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { useGetAll360ImagesQuery, useGetAll360VideosQuery } from "../../services/media.service";
import { useEffect, useState } from "react";
import { EMediaOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { VTSidePanelContainer } from "@virtus-tech-repository/virtus-tech-repository";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import { Droppable } from "react-beautiful-dnd";

interface IProps {
    sx?: SxProps;
}

export default function VTImmersiveSidePanelWrapper({ sx }: IProps) {
    const { currentScenarioIsUpdating, currentlyDragging, currentSidePanel } = useAppSelector(
        (state) => state.currentReducer,
    );
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const { data: images, isLoading: imagesLoading } = useGetAll360ImagesQuery(userId);
    const { data: videos, isLoading: videosLoading } = useGetAll360VideosQuery(userId);

    const [allCards, setAllCards] = useState<IMediaItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (imagesLoading || videosLoading) {
            setLoading(true);
        } else if (!imagesLoading && !videosLoading) {
            setLoading(false);
        }
    }, [imagesLoading, videosLoading]);

    useEffect(() => {
        if (images && videos) {
            setAllCards([
                ...images.map((image) => {
                    return {
                        ...image,
                        src: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${image.id}`,
                        mediaType: EMediaOptions.IMMERSIVE_IMAGE,
                    };
                }),
                ...videos.map((video) => {
                    return {
                        ...video,
                        src: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${video.id}`,
                        mediaType: EMediaOptions.IMMERSIVE_VIDEO,
                    };
                }),
            ]);
        }
    }, [images, videos]);

    useEffect(() => {
        console.log("allCards", allCards);
    }, [allCards]);

    return (
        <Droppable droppableId={EDroppableContainerId.MEDIA_360_IMAGES} type="DROPPABLE_MEDIA">
            {(provided, snapshot) => (
                <Grid ref={provided.innerRef} {...provided.droppableProps} item container>
                    <VTSidePanelContainer
                        contentLoading={loading}
                        width={currentSidePanel.currentWidth}
                        views={["view 1", "view 2", "view 3 login name that will be cut off"]}
                        cards={allCards}
                        organisation={organisation!}
                        droppableId={EDroppableContainerId.MEDIA_360_IMAGES}
                        filters={[
                            { id: EMediaOptions.IMMERSIVE_IMAGE, name: "360 Images" },
                            { id: EMediaOptions.IMMERSIVE_VIDEO, name: "360 Videos" },
                        ]}
                    />
                </Grid>
            )}
        </Droppable>
    );
}
