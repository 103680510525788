//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import {
    Card,
    CardHeader,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { v4 as uuid } from "uuid";
import {
    VTButton,
    VTDialog,
    VTMenu,
    VTRichTextEditor,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";

import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect, useRef, useState } from "react";
import {
    AddRounded,
    DeleteRounded,
    SaveRounded,
    CancelRounded,
    SettingsRounded,
    Diversity1,
} from "@mui/icons-material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Params, useParams } from "react-router-dom";
import VTVideoDropper from "./VTVideoDropper";
import VTAudioDropper from "./VTAudioDropper";
import { useAppSelector } from "../../store/hooks";
import VTImageDropper from "./VTImageDropper";

import { idText } from "typescript";
import { IUser } from "../../models/user.model";
import { IHotspot, IUserInterface } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IScenario, IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import {
    useDeleteGroupHotspotMutation,
    useDeleteHotspotMutation,
    useUpdateGroupHotspotMutation,
    useUpdateHotspotMutation,
} from "../../services/hotspot.service";
import { useGetScenesQuery } from "../../services/scene.service";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    currentInterface: IHotspot;
    setCurrentInterface: (currentInteface: IHotspot | undefined) => void;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function VTUserInterface({ currentInterface, setCurrentInterface, videoCurrentState }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const [updateHotspot] = useUpdateHotspotMutation();
    const [updateQuestionHotspot] = useUpdateGroupHotspotMutation();
    const [deleteHotspot] = useDeleteHotspotMutation();
    const [deleteQuestionHotspot] = useDeleteGroupHotspotMutation();
    const { data: scenes } = useGetScenesQuery(scenarioId ? scenarioId : "");
    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentSidePanel, currentQuestion } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [localUserInterface, setLocalUserInterface] = useState<IHotspot>(currentInterface);
    const [userInterfaceIndex, setUserInterfaceIndex] = useState<number>(0);
    const [interfaceSettingsModel, setInterfaceSettingsModel] = useState<boolean>(false);
    const [localUserInterfaceSection, setLocalUserInterfaceSection] = useState<IUserInterface>(
        currentInterface.contents.preClickContent!.userInterface![userInterfaceIndex],
    );

    const [multipleChoiceDialog, setMultipleChoiceDialog] = useState<string | undefined>(undefined);
    const [multipleChoiceText, setMultipleChoiceText] = useState<string>("");

    const [actionSceneId, setActionSceneId] = useState<string | undefined>("");
    const [actionProceed, setActionProceed] = useState<boolean | undefined>(undefined);
    const [actionSceneDelay, setActionSceneDelay] = useState<number>(0);
    const [actionFeedbackImage, setActionFeedbackImage] = useState<string>("");
    const [actionFeedbackVideo, setActionFeedbackVideo] = useState<string>("");
    const [actionFeedbackAudio, setActionFeedbackAudio] = useState<string>("");
    const [actionFeedbackInput, setActionFeedbackInput] = useState<string>("");
    const [actionNavigate, setActionNavigate] = useState<string>("");
    const [actionCloseable, setActionCloseable] = useState<boolean>(false);
    const [actionFeedbackDelay, setActionFeedbackDelay] = useState<number>(0);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        setActionSceneId(
            currentInterface.actions?.movement?.sceneId ? currentInterface.actions?.movement?.sceneId : "",
        );
        setActionSceneDelay(currentInterface.actions?.movement?.delay ? currentInterface.actions?.movement?.delay : 0);
        setActionFeedbackImage(
            currentInterface.actions?.appFeedback?.image ? currentInterface.actions?.appFeedback?.image : "",
        );
        setActionFeedbackVideo(
            currentInterface.actions?.appFeedback?.video ? currentInterface.actions?.appFeedback?.video : "",
        );
        setActionFeedbackInput(
            currentInterface.actions?.appFeedback?.input ? currentInterface.actions?.appFeedback?.input : "",
        );
        setActionNavigate(currentInterface.actions?.navigate ? currentInterface.actions?.navigate : "");

        setActionFeedbackAudio(
            currentInterface.actions?.appFeedback?.audio ? currentInterface.actions?.appFeedback?.audio : "",
        );
        setActionCloseable(currentInterface.actions?.closeable ? currentInterface.actions?.closeable : false);
        setActionProceed(
            currentInterface.actions?.appFeedback?.proceed ? currentInterface.actions?.appFeedback.proceed : false,
        );
        setActionFeedbackDelay(
            currentInterface.actions?.appFeedback?.delay ? currentInterface.actions?.appFeedback.delay : 0,
        );
    }, [currentInterface]);

    useEffect(() => {
        setLocalUserInterfaceSection(
            //@ts-ignore
            localUserInterface.contents.preClickContent.userInterface[userInterfaceIndex]!,
        );
    }, [userInterfaceIndex]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Add new Section to interface
    function handlesAddSectionToLocalInterface() {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: [
                        ...localUserInterface.contents.preClickContent.userInterface!,
                        {
                            sectionTitle: `New Section ${localUserInterface.contents.preClickContent.userInterface
                                ?.length!}`,
                        },
                    ],
                },
            },
        });
    }

    // Delete Section from interface
    function handlesDeleteSectionToLocalInterface() {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: localUserInterface.contents.preClickContent.userInterface!.filter(
                        (section: IUserInterface, index: number) => index !== userInterfaceIndex,
                    ),
                },
            },
        });
    }

    // Update interfaces overall title
    function handleUpdateInterfaceTitle(newTitle: string) {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    title: newTitle,
                },
            },
        });
    }

    function handleAudioDropped(audioItem: string) {
        setActionFeedbackAudio(audioItem);
        if (audioItem !== "") {
            const storedAudio = new Audio(audioItem);
            storedAudio.addEventListener("loadedmetadata", function () {
                if (storedAudio.duration) {
                    setActionFeedbackDelay(Math.ceil(storedAudio.duration));
                    setActionSceneDelay(Math.ceil(storedAudio.duration));
                }
            });
        }
    }

    function generateHotspot() {
        return {
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: [
                        ...localUserInterface.contents.preClickContent.userInterface!.map(
                            (
                                saveElement: {
                                    sectionTitle?: string;
                                    sectionContent?: { id: string; field: string; value: string }[];
                                    sectionDescription?: string;
                                    sectionFeedback?: string;
                                },
                                saveIndex: number,
                            ) => (saveIndex === userInterfaceIndex ? localUserInterfaceSection : saveElement),
                        ),
                    ],
                },
            },
            actions: {
                movement:
                    actionSceneId || actionSceneDelay
                        ? {
                              sceneId: actionSceneId !== "" ? actionSceneId : undefined,
                              delay: actionSceneDelay !== 0 ? actionSceneDelay : undefined,
                          }
                        : undefined,

                navigate: actionNavigate !== "" ? actionNavigate : undefined,
                appFeedback:
                    actionFeedbackImage ||
                    actionFeedbackVideo ||
                    actionFeedbackAudio ||
                    actionFeedbackInput ||
                    actionProceed
                        ? {
                              image: actionFeedbackImage !== "" ? actionFeedbackImage : undefined,
                              video: actionFeedbackVideo !== "" ? actionFeedbackVideo : undefined,
                              audio: actionFeedbackAudio !== "" ? actionFeedbackAudio : undefined,
                              input: actionFeedbackInput !== "" ? actionFeedbackInput : undefined,
                              proceed: actionProceed !== false ? actionProceed : undefined,
                              delay: actionProceed && actionFeedbackDelay !== 0 ? actionFeedbackDelay : undefined,
                          }
                        : undefined,

                closeable: actionCloseable ? actionCloseable : undefined,
            },
        };
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <Grid
            container
            sx={{
                position: "absolute",
                right: 0,
                width: "400px",
                height: "100%",
                zIndex: 10,
                padding: "15px",
            }}
            justifyContent={"center"}
            alignContent={"space-between"}
        >
            {/*------------------------------------------------------------------------------------*/}
            {/* Interface Title Section */}
            {/*------------------------------------------------------------------------------------*/}
            {/* Interface Title */}
            <Grid item container justifyContent={"center"} alignContent={"center"} sx={{ marginTop: "50px" }}>
                {/*--------------------------------------------------------------------------------*/}
                {/* If editing interface title show text field and data management options */}
                <VTTextField
                    value={localUserInterface.contents.preClickContent.title}
                    onChange={(e) => handleUpdateInterfaceTitle(e)}
                    sx={{ marginTop: "-8px" }}
                />

                {/*------------------------------------------------------------------------------------*/}
                {/* Interface Delete */}
                <Tooltip title={"Interface Settings"} sx={{ position: "absolute", top: "10px", right: "60px" }}>
                    <Grid item>
                        <VTButton
                            onClick={() => {
                                setInterfaceSettingsModel(true);
                            }}
                            type={"icon"}
                        >
                            <SettingsRounded />
                        </VTButton>
                    </Grid>
                </Tooltip>
                <Tooltip title={"Delete Interface"} sx={{ position: "absolute", top: "10px", right: "10px" }}>
                    <Grid item>
                        <VTButton
                            onClick={() => {
                                if (
                                    window.confirm("This will remove the current interface, any content will be lost")
                                ) {
                                    if (videoCurrentState === "Question") {
                                        deleteQuestionHotspot({
                                            groupId: currentQuestion.id,
                                            hotspotId: currentInterface.id,
                                        });
                                    } else {
                                        deleteHotspot({ sceneId: sceneId!, hotspotId: currentInterface.id });
                                    }
                                    setCurrentInterface(undefined);
                                }
                            }}
                            type={"icon"}
                            sx={{
                                backgroundColor: "#aa0000",
                                marginLeft: "15px",
                                ":hover": { backgroundColor: "#770000" },
                            }}
                        >
                            <DeleteRounded />
                        </VTButton>
                    </Grid>
                </Tooltip>
            </Grid>

            {/*----------------------------------------------------------------------------------------*/}
            {/* Interface Timeline section */}
            {/*----------------------------------------------------------------------------------------*/}

            <Grid
                container
                sx={{
                    marginTop: "-20px",
                    height: "100px",
                    padding: "30px",
                    width: "100%",
                    overflowX: "scroll",
                    overflowY: "hidden",
                }}
                direction={"column"}
                alignContent={
                    localUserInterface.contents.preClickContent.userInterface!.length < 4 ? "center" : "normal"
                }
            >
                {localUserInterface.contents.preClickContent.userInterface?.map(
                    (element: IUserInterface, index: number) => {
                        return (
                            <>
                                <VTButton
                                    type={"icon"}
                                    sx={{
                                        width: "40px",
                                        height: "40px",
                                        background: `linear-gradient(to bottom, ${theme.palette.info.light},${theme.palette.info.main})`,
                                        // transition: ".5s",
                                        boxShadow: userInterfaceIndex === index ? "0 0 25px #fff" : "none",
                                        ":hover": {
                                            boxShadow: "0 0 25px #fff",
                                        },
                                    }}
                                    onClick={() => {
                                        setLocalUserInterface({
                                            ...localUserInterface,
                                            contents: {
                                                ...localUserInterface.contents,
                                                preClickContent: {
                                                    ...localUserInterface.contents.preClickContent,
                                                    userInterface: [
                                                        ...localUserInterface.contents.preClickContent.userInterface!.map(
                                                            (saveElement: IUserInterface, saveIndex: number) =>
                                                                saveIndex === userInterfaceIndex
                                                                    ? localUserInterfaceSection
                                                                    : saveElement,
                                                        ),
                                                    ],
                                                },
                                            },
                                        });
                                        setUserInterfaceIndex(index);
                                    }}
                                >
                                    {index}
                                </VTButton>
                                <hr
                                    style={{
                                        color: "#fff",
                                        backgroundColor: "#fff",
                                        height: "3px",
                                        width: "40px",
                                        borderRadius: "40%",
                                        marginTop: "18.5px",
                                    }}
                                />
                            </>
                        );
                    },
                )}
                <VTButton
                    type={"icon"}
                    sx={{
                        width: "40px",
                        height: "40px",
                        background: `linear-gradient(to bottom, ${theme.palette.info.light},${theme.palette.info.main})`,
                        // transition: ".5s",
                        ":hover": {
                            boxShadow: "0 0 25px #fff",
                        },
                    }}
                    onClick={() => handlesAddSectionToLocalInterface()}
                >
                    <AddRounded />
                </VTButton>
            </Grid>

            {/*----------------------------------------------------------------------------------------*/}
            {/* Section Title area */}
            {/*----------------------------------------------------------------------------------------*/}
            <Grid container item xs={12} sx={{ height: "calc(100% - 200px)", overflowY: "auto" }}>
                <Grid item container xs={12}>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* If editing section title */}

                    <VTTextField
                        value={localUserInterfaceSection.sectionTitle}
                        onChange={(e) =>
                            setLocalUserInterfaceSection({ ...localUserInterfaceSection, sectionTitle: e })
                        }
                        sx={{ width: "300px" }}
                    />

                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Delete section */}
                    <Tooltip title={"Delete Section"}>
                        <Grid
                            item
                            sx={{
                                height: "35px",
                                marginTop: "10px",
                                width: "70px",
                            }}
                        >
                            <VTButton
                                onClick={() => {
                                    if (userInterfaceIndex !== 0) {
                                        setUserInterfaceIndex(userInterfaceIndex - 1);
                                    } else {
                                        setLocalUserInterfaceSection(
                                            //@ts-ignore
                                            localUserInterface.contents.preClickContent.userInterface[1]!,
                                        );
                                    }
                                    handlesDeleteSectionToLocalInterface();
                                }}
                                type={"icon"}
                                sx={{
                                    backgroundColor: "#990000",
                                    marginLeft: "15px",
                                    ":hover": { backgroundColor: "#770000" },
                                    ":disabled": { backgroundColor: "#993333" },
                                }}
                                disabled={localUserInterface.contents.preClickContent.userInterface?.length! === 1}
                            >
                                <DeleteRounded />
                            </VTButton>
                        </Grid>
                    </Tooltip>
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    sx={{
                        height: "calc(100% - 100px)",
                        marginTop: "-20px",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                    justifyContent={"center"}
                >
                    <VTMenu
                        popOutMenuZIndex={100}
                        sx={{ height: "40px", marginTop: "10px", marginBottom: "10px" }}
                        menuOptions={[
                            {
                                component: (
                                    <VTButton
                                        sx={{ width: "205px" }}
                                        type="list"
                                        onClick={() => {
                                            setLocalUserInterfaceSection({
                                                ...localUserInterfaceSection,
                                                sectionContent: localUserInterfaceSection.sectionContent
                                                    ? [
                                                          ...localUserInterfaceSection.sectionContent,
                                                          { id: uuid(), field: "Question", value: "" },
                                                      ]
                                                    : [{ id: uuid(), field: "Question", value: "" }],
                                            });
                                        }}
                                    >
                                        <VTTypography align="center">Question</VTTypography>
                                    </VTButton>
                                ),
                            },
                            {
                                component: (
                                    <VTButton
                                        sx={{ width: "205px" }}
                                        type="list"
                                        onClick={() => {
                                            setLocalUserInterfaceSection({
                                                ...localUserInterfaceSection,
                                                sectionContent: localUserInterfaceSection.sectionContent
                                                    ? [
                                                          ...localUserInterfaceSection.sectionContent,
                                                          { id: uuid(), field: "User Text Input", value: "" },
                                                      ]
                                                    : [{ id: uuid(), field: "User Text Input", value: "" }],
                                            });
                                        }}
                                    >
                                        <VTTypography align="center">User Text Input</VTTypography>
                                    </VTButton>
                                ),
                            },
                            {
                                component: (
                                    <VTButton
                                        sx={{ width: "205px" }}
                                        type="list"
                                        onClick={() => {
                                            setLocalUserInterfaceSection({
                                                ...localUserInterfaceSection,
                                                sectionContent: localUserInterfaceSection.sectionContent
                                                    ? [
                                                          ...localUserInterfaceSection.sectionContent,
                                                          {
                                                              id: uuid(),
                                                              field: "Multiple Choice",
                                                              value: "",
                                                          },
                                                      ]
                                                    : [
                                                          {
                                                              id: uuid(),
                                                              field: "Multiple Choice",
                                                              value: "",
                                                          },
                                                      ],
                                            });
                                        }}
                                    >
                                        <VTTypography align="center">Multiple Choice</VTTypography>
                                    </VTButton>
                                ),
                            },
                            {
                                component: (
                                    <VTButton
                                        sx={{ width: "205px" }}
                                        type="list"
                                        onClick={() => {
                                            setLocalUserInterfaceSection({
                                                ...localUserInterfaceSection,
                                                sectionContent: localUserInterfaceSection.sectionContent
                                                    ? [
                                                          ...localUserInterfaceSection.sectionContent,
                                                          { id: uuid(), field: "Model Answer", value: "" },
                                                      ]
                                                    : [{ id: uuid(), field: "Model Answer", value: "" }],
                                            });
                                        }}
                                    >
                                        <VTTypography align="center">Model Answer</VTTypography>
                                    </VTButton>
                                ),
                            },
                        ]}
                    >
                        Add to section
                        <AddRounded />
                    </VTMenu>

                    {localUserInterfaceSection.sectionContent &&
                        localUserInterfaceSection.sectionContent!.map(
                            (element: { id: string; field: string; value: string }, index: number) => {
                                if (element.field === "Question" || element.field === "Model Answer") {
                                    return (
                                        <Grid item container xs={12} sx={{ height: "150px" }}>
                                            <VTButton
                                                type="icon"
                                                sx={{
                                                    height: "25px",
                                                    width: "25px",
                                                    marginRight: "5px",

                                                    backgroundColor: "#990000",
                                                    ":hover": { backgroundColor: "#770000" },
                                                }}
                                                onClick={() => {
                                                    setLocalUserInterfaceSection({
                                                        ...localUserInterfaceSection,
                                                        sectionContent: [
                                                            ...localUserInterfaceSection.sectionContent!.filter(
                                                                (
                                                                    deleteElement: {
                                                                        id: string;
                                                                        field: string;
                                                                        value: string;
                                                                    },
                                                                    deleteIndex: number,
                                                                ) => deleteIndex !== index,
                                                            ),
                                                        ],
                                                    });
                                                }}
                                            >
                                                <DeleteRounded sx={{ marginLeft: "-0.5px" }} />
                                            </VTButton>
                                            <VTTypography>{element.field}</VTTypography>

                                            <VTTextField
                                                value={localUserInterfaceSection.sectionContent![index].value}
                                                sx={{ width: "95%" }}
                                                multiline
                                                rows={3}
                                                onChange={(e) =>
                                                    setLocalUserInterfaceSection({
                                                        ...localUserInterfaceSection,
                                                        sectionContent: [
                                                            ...localUserInterfaceSection.sectionContent!.map(
                                                                (interfaceElement: {
                                                                    id: string;
                                                                    field: string;
                                                                    value: string;
                                                                }) => {
                                                                    if (interfaceElement.id === element.id) {
                                                                        return { ...interfaceElement, value: e };
                                                                    } else {
                                                                        return interfaceElement;
                                                                    }
                                                                },
                                                            ),
                                                        ],
                                                    })
                                                }
                                            />
                                        </Grid>
                                    );
                                } else if (element.field === "User Text Input") {
                                    return (
                                        <Grid item container xs={12} sx={{ height: "150px", width: "100%" }}>
                                            <VTButton
                                                type="icon"
                                                sx={{
                                                    height: "25px",
                                                    width: "25px",
                                                    marginRight: "5px",

                                                    backgroundColor: "#990000",
                                                    ":hover": { backgroundColor: "#770000" },
                                                }}
                                                onClick={() => {
                                                    setLocalUserInterfaceSection({
                                                        ...localUserInterfaceSection,
                                                        sectionContent: [
                                                            ...localUserInterfaceSection.sectionContent!.filter(
                                                                (
                                                                    deleteElement: {
                                                                        id: string;
                                                                        field: string;
                                                                        value: string;
                                                                    },
                                                                    deleteIndex: number,
                                                                ) => deleteIndex !== index,
                                                            ),
                                                        ],
                                                    });
                                                }}
                                            >
                                                <DeleteRounded sx={{ marginLeft: "-0.5px" }} />
                                            </VTButton>{" "}
                                            <VTTypography>{element.field}</VTTypography>
                                            <VTTextField sx={{ width: "100%" }} multiline rows={3} disabled />
                                        </Grid>
                                    );
                                } else {
                                    const splitArray: string[] = [
                                        ...element.value.split(";¬;").filter((element: string) => element !== ""),
                                    ];
                                    const numOfCorrect: number = splitArray.filter((correctElement: string) =>
                                        correctElement.includes("%^%"),
                                    ).length;

                                    return (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            sx={{
                                                height: "250px",
                                                width: "100%",
                                                zIndex: 1000,
                                                overflowY: "hidden",
                                                position: "relative",
                                            }}
                                        >
                                            <VTButton
                                                type="icon"
                                                sx={{
                                                    height: "25px",
                                                    width: "25px",
                                                    marginRight: "5px",

                                                    backgroundColor: "#990000",
                                                    ":hover": { backgroundColor: "#770000" },
                                                }}
                                                onClick={() => {
                                                    setLocalUserInterfaceSection({
                                                        ...localUserInterfaceSection,
                                                        sectionContent: [
                                                            ...localUserInterfaceSection.sectionContent!.filter(
                                                                (
                                                                    deleteElement: {
                                                                        id: string;
                                                                        field: string;
                                                                        value: string;
                                                                    },
                                                                    deleteIndex: number,
                                                                ) => deleteIndex !== index,
                                                            ),
                                                        ],
                                                    });
                                                }}
                                            >
                                                <DeleteRounded sx={{ marginLeft: "-0.5px" }} />
                                            </VTButton>
                                            <VTTypography>{element.field}</VTTypography>
                                            <Card>
                                                <CardHeader
                                                    sx={{ px: 12, py: 1 }}
                                                    subheader={`${numOfCorrect}/${splitArray.length} correct answers`}
                                                />
                                                <VTButton
                                                    type={"icon"}
                                                    sx={{
                                                        position: "absolute",
                                                        top: "30px",
                                                        right: "50px",
                                                        height: "30px",
                                                        width: "30px",
                                                        background: `linear-gradient(to bottom, ${theme.palette.info.light},${theme.palette.info.main})`,
                                                    }}
                                                    onClick={() => {
                                                        setMultipleChoiceText("");
                                                        setMultipleChoiceDialog(element.id);
                                                    }}
                                                >
                                                    <AddRounded />
                                                </VTButton>
                                                <Divider />
                                                <List
                                                    sx={{
                                                        width: "400px",
                                                        height: "250px",
                                                        bgcolor: "background.paper",
                                                        overflow: "auto",
                                                        paddingBottom: "100px",
                                                    }}
                                                    dense
                                                    component="div"
                                                    role="list"
                                                >
                                                    {splitArray.map((value: string, index: number) => {
                                                        const labelId = `transfer-list-all-item-${value}-label`;
                                                        const valueArray = value.split("%^%");

                                                        return (
                                                            <ListItemButton key={value} role="listitem">
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        checked={valueArray.length > 1}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{
                                                                            "aria-labelledby": labelId,
                                                                        }}
                                                                        sx={{ color: "white" }}
                                                                        onClick={() => {
                                                                            if (valueArray.length > 1) {
                                                                                const temp = splitArray.map(
                                                                                    (
                                                                                        subElement: string,
                                                                                        subIndex: number,
                                                                                    ) =>
                                                                                        subIndex === index
                                                                                            ? subElement.split("%^%")[0]
                                                                                            : subElement,
                                                                                );
                                                                                setLocalUserInterfaceSection({
                                                                                    ...localUserInterfaceSection,
                                                                                    sectionContent: [
                                                                                        ...localUserInterfaceSection.sectionContent!.map(
                                                                                            (checkedElement: {
                                                                                                id: string;
                                                                                                field: string;
                                                                                                value: string;
                                                                                            }) =>
                                                                                                checkedElement.id ===
                                                                                                element.id
                                                                                                    ? {
                                                                                                          ...checkedElement,
                                                                                                          value: temp
                                                                                                              .toString()
                                                                                                              .replace(
                                                                                                                  /,/g,
                                                                                                                  ";¬;",
                                                                                                              ),
                                                                                                      }
                                                                                                    : checkedElement,
                                                                                        ),
                                                                                    ],
                                                                                });
                                                                            } else {
                                                                                const temp = splitArray.map(
                                                                                    (
                                                                                        subElement: string,
                                                                                        subIndex: number,
                                                                                    ) =>
                                                                                        subIndex === index
                                                                                            ? subElement + "%^%"
                                                                                            : subElement,
                                                                                );
                                                                                setLocalUserInterfaceSection({
                                                                                    ...localUserInterfaceSection,
                                                                                    sectionContent: [
                                                                                        ...localUserInterfaceSection.sectionContent!.map(
                                                                                            (checkedElement: {
                                                                                                id: string;
                                                                                                field: string;
                                                                                                value: string;
                                                                                            }) =>
                                                                                                checkedElement.id ===
                                                                                                element.id
                                                                                                    ? {
                                                                                                          ...checkedElement,
                                                                                                          value: temp
                                                                                                              .toString()
                                                                                                              .replace(
                                                                                                                  /,/g,
                                                                                                                  ";¬;",
                                                                                                              ),
                                                                                                      }
                                                                                                    : checkedElement,
                                                                                        ),
                                                                                    ],
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText id={labelId} primary={valueArray[0]} />
                                                                <VTButton
                                                                    type={"icon"}
                                                                    sx={{
                                                                        marginRight: "30px",
                                                                        height: "30px",
                                                                        width: "30px",
                                                                        backgroundColor: "#990000",
                                                                        ":hover": {
                                                                            backgroundColor: "#770000",
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        const temp = splitArray.filter(
                                                                            (subElement: string, subIndex: number) =>
                                                                                subIndex !== index,
                                                                        );

                                                                        setLocalUserInterfaceSection({
                                                                            ...localUserInterfaceSection,
                                                                            sectionContent: [
                                                                                ...localUserInterfaceSection.sectionContent!.map(
                                                                                    (updateElement: {
                                                                                        id: string;
                                                                                        field: string;
                                                                                        value: string;
                                                                                    }) =>
                                                                                        updateElement.id === element.id
                                                                                            ? {
                                                                                                  ...updateElement,
                                                                                                  value: temp
                                                                                                      .toString()
                                                                                                      .replace(
                                                                                                          /,/g,
                                                                                                          ";¬;",
                                                                                                      ),
                                                                                              }
                                                                                            : updateElement,
                                                                                ),
                                                                            ],
                                                                        });
                                                                    }}
                                                                >
                                                                    <DeleteRounded sx={{ marginLeft: "-2px" }} />
                                                                </VTButton>
                                                            </ListItemButton>
                                                        );
                                                    })}
                                                </List>
                                            </Card>
                                        </Grid>
                                    );
                                }
                            },
                        )}
                </Grid>
            </Grid>

            {/*------------------------------------------------------------------------------------------*/}
            {/* Closes the interface panel */}
            <VTButton
                sx={{
                    width: "10px",
                    height: "40px",
                    borderRadius: "15px 0px 0px 15px",
                    backgroundColor: "#a9a9a9",
                    ":hover": {
                        cursor: "pointer",
                        backgroundColor: "#888888",
                        boxShadow: "0 0 25px #2ad5c7",
                    },
                    marginBlock: "15px",
                    position: "absolute",
                    left: "-64px",
                    top: "42%",
                }}
                onClick={() => setCurrentInterface(undefined)}
            >
                <LogoutIcon />
            </VTButton>

            <Grid item container xs={12} justifyContent={"space-around"} sx={{ marginTop: "-35px" }}>
                {/*------------------------------------------------------------------------------------------*/}
                {/* Resets the data with the sever */}
                <Grid item>
                    <VTButton
                        sx={{ width: "150px" }}
                        disabled={
                            localUserInterface === currentInterface &&
                            localUserInterfaceSection ===
                                currentInterface.contents.preClickContent.userInterface![userInterfaceIndex]
                        }
                        onClick={() => {
                            setCurrentInterface(undefined);
                        }}
                        color={"secondary"}
                    >
                        Cancel
                    </VTButton>
                </Grid>
                {/*------------------------------------------------------------------------------------------*/}
                {/* sets data on the server */}
                <Grid item>
                    <VTButton
                        sx={{ width: "150px" }}
                        disabled={
                            localUserInterface === currentInterface &&
                            localUserInterfaceSection ===
                                currentInterface.contents.preClickContent.userInterface![userInterfaceIndex]
                        }
                        onClick={() => {
                            if (videoCurrentState === "Question") {
                                updateQuestionHotspot(generateHotspot());
                            } else {
                                updateHotspot(generateHotspot());
                            }

                            setCurrentInterface(localUserInterface);
                        }}
                    >
                        Save
                    </VTButton>
                </Grid>
            </Grid>

            <VTDialog
                title={"Add field to the multiple choice"}
                sx={{ marginLeft: currentSidePanel.currentWidth / 8 + 11 }}
                open={multipleChoiceDialog !== undefined}
                handleClose={() => setMultipleChoiceDialog(undefined)}
                primaryArea={
                    <>
                        <VTButton
                            onClick={() => {
                                setMultipleChoiceDialog(undefined);
                            }}
                            sx={{
                                backgroundColor: "#898989",
                                ":hover": {
                                    backgroundColor: "#696969",
                                },
                            }}
                        >
                            Cancel
                        </VTButton>
                        <VTButton
                            onClick={() => {
                                setLocalUserInterfaceSection({
                                    ...localUserInterfaceSection,
                                    sectionContent: [
                                        ...localUserInterfaceSection.sectionContent!.map(
                                            (element: { id: string; field: string; value: string }) =>
                                                element.id === multipleChoiceDialog
                                                    ? { ...element, value: `${element.value};¬;${multipleChoiceText}` }
                                                    : element,
                                        ),
                                    ],
                                });
                                setMultipleChoiceDialog(undefined);
                            }}
                        >
                            Add
                            <AddRounded sx={{ marginLeft: "10px" }} />
                        </VTButton>
                    </>
                }
            >
                <VTTextField value={multipleChoiceText} onChange={(e: string) => setMultipleChoiceText(e)} />
            </VTDialog>

            {/* ################################################################################################## */}
            <VTDialog
                title={"Interface Settings"}
                open={interfaceSettingsModel}
                handleClose={() => setInterfaceSettingsModel(false)}
                sx={{ marginLeft: currentSidePanel.currentWidth / 8 + 5, marginTop: "70px" }}
                primaryArea={
                    <VTButton
                        onClick={() => {
                            setInterfaceSettingsModel(false);
                        }}
                    >
                        Save
                        <SaveRounded sx={{ marginLeft: "10px" }} />
                    </VTButton>
                }
            >
                <Grid container justifyContent={"center"} spacing={2}>
                    {/* --------------------------------------------------------------- */}
                    {/* Title */}
                    <Grid item container xs={10} justifyContent={"space-between"}>
                        {/*-------------------------------------------------------------------------------*/}
                        {/* Move scene */}
                        <Grid item xs={9}>
                            <VTTypography>Move to scene</VTTypography>
                        </Grid>
                        {actionSceneId ? (
                            <Grid item xs={3}>
                                <VTTypography>Delay move to scene</VTTypography>
                            </Grid>
                        ) : (
                            <Grid item xs={3}>
                                <VTTypography>Closeable Content</VTTypography>
                            </Grid>
                        )}
                        <Grid item xs={8}>
                            <Select
                                value={actionSceneId}
                                onChange={(e) => {
                                    setActionSceneId(e.target.value);
                                    setLocalUserInterface({
                                        ...localUserInterface,
                                        actions: {
                                            ...localUserInterface.actions,
                                            movement: { sceneId: e.target.value },
                                        },
                                    });
                                }}
                                sx={{
                                    width: "100%",
                                    marginLeft: "10px",
                                    marginTop: "3px",
                                }}
                            >
                                {scenes &&
                                    scenes
                                        .filter((sceneElement: IScene) => sceneElement.id !== sceneId)
                                        .map((scene: IScene) => <MenuItem value={scene.id}>{scene.name}</MenuItem>)}
                                <MenuItem value={undefined}>{"No Scene"}</MenuItem>
                            </Select>
                        </Grid>
                        {actionSceneId ? (
                            <Grid item xs={3}>
                                <TextField
                                    type={"number"}
                                    value={actionSceneDelay}
                                    onChange={(e) => {
                                        if (!(parseInt(e.target.value) < 0)) {
                                            setActionSceneDelay(parseInt(e.target.value));
                                        }
                                    }}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={3}>
                                <VTButton
                                    type={actionCloseable ? "primary" : "underline"}
                                    onClick={() => setActionCloseable(!actionCloseable)}
                                    endIcon={actionCloseable ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
                                    sx={{ width: "100%", height: "100%" }}
                                >
                                    Closeable
                                </VTButton>
                            </Grid>
                        )}
                    </Grid>

                    {/* --------------------------------------------------------------- */}
                    {/* Feedback audio */}
                    <Grid item container xs={10}>
                        <Grid item>
                            <VTTypography>Navigate to Url</VTTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <VTTextField value={actionNavigate} onChange={(e) => setActionNavigate(e)} />
                        </Grid>
                    </Grid>

                    {/* --------------------------------------------------------------- */}
                    {/* Feedback image */}
                    <Grid item container xs={10}>
                        <Grid item>
                            <VTTypography>Feedback Image</VTTypography>
                        </Grid>
                        <VTImageDropper
                            currentImage={actionFeedbackImage}
                            setCurrentImage={setActionFeedbackImage}
                            disabled={currentSidePanel.currentTab !== "Images"}
                            droppableId={EDroppableContainerId.HOTSPOT_FEEDBACK_IMAGE_DROP}
                        />
                        <Grid
                            xs={12}
                            container
                            item
                            justifyContent={"center"}
                            alignItems={"center"}
                            marginBottom={"5px"}
                        >
                            <Typography>Drop and drag an image from the image sidebar</Typography>
                        </Grid>
                    </Grid>

                    {/* --------------------------------------------------------------- */}
                    {/* Feedback video */}
                    <Grid item container xs={10}>
                        <Grid item>
                            <VTTypography>Feedback Video</VTTypography>
                        </Grid>
                        {/*-------------------------------------------------------------------------------*/}
                        {/* Preview image */}
                        <VTVideoDropper
                            currentVideo={actionFeedbackVideo}
                            setCurrentVideo={setActionFeedbackVideo}
                            disabled={currentSidePanel.currentTab !== "Videos"}
                            droppableId={EDroppableContainerId.HOTSPOT_FEEDBACK_VIDEO_DROP}
                        />
                        <Grid
                            xs={12}
                            container
                            item
                            justifyContent={"center"}
                            alignItems={"center"}
                            marginBottom={"5px"}
                        >
                            <Typography>Drop and drag an video from the video sidebar</Typography>
                        </Grid>
                    </Grid>

                    {/* --------------------------------------------------------------- */}
                    {/* Feedback audio */}
                    <Grid item container xs={10}>
                        <Grid item>
                            <VTTypography>Feedback Audio</VTTypography>
                        </Grid>
                        {/*-------------------------------------------------------------------------------*/}
                        {/* Preview image */}
                        <VTAudioDropper
                            currentAudio={actionFeedbackAudio}
                            setCurrentAudio={handleAudioDropped}
                            disabled={currentSidePanel.currentTab !== "Audio"}
                            droppableId={EDroppableContainerId.HOTSPOT_FEEDBACK_AUDIO_DROP}
                        />
                        <Grid
                            xs={12}
                            container
                            item
                            justifyContent={"center"}
                            alignItems={"center"}
                            marginBottom={"5px"}
                        >
                            <Typography>Drop and drag an audio from the audio sidebar</Typography>
                        </Grid>
                    </Grid>

                    {/* --------------------------------------------------------------- */}
                    {/* Feedback audio */}
                    <Grid item container xs={10}>
                        <Grid item>
                            <VTTypography>Question for user input</VTTypography>
                        </Grid>
                        {/*-------------------------------------------------------------------------------*/}
                        {/* Preview image */}
                        <Grid item xs={12}>
                            <VTTextField
                                value={actionFeedbackInput}
                                onChange={(e) => setActionFeedbackInput(e)}
                                multiline
                                rows={2}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container xs={10} justifyContent={"space-evenly"} alignItems={"center"}>
                        {videoCurrentState &&
                            currentQuestion &&
                            currentQuestion.contents?.preClickContent?.question?.answers &&
                            currentQuestion.contents?.preClickContent?.question?.answers.includes(currentInterface) && (
                                <>
                                    <Grid item>
                                        <VTButton
                                            type={actionProceed ? "primary" : "underline"}
                                            onClick={() => setActionProceed(!actionProceed)}
                                            endIcon={actionProceed ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
                                        >
                                            Proceed video
                                        </VTButton>
                                    </Grid>

                                    {actionProceed && (
                                        <Grid item>
                                            <VTTypography>Delay Video Proceed</VTTypography>
                                            <TextField
                                                type={"number"}
                                                value={actionFeedbackDelay}
                                                onChange={(e) => {
                                                    if (!(parseInt(e.target.value) < 0)) {
                                                        setActionFeedbackDelay(parseInt(e.target.value));
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                    </Grid>
                </Grid>
            </VTDialog>
        </Grid>
    );
}
