//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import {
    Box,
    CircularProgress,
    Grid,
    LinearProgress,
    Paper,
    Skeleton,
    SxProps,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { v4 as uuid } from "uuid";

import { useEffect, useRef, useState } from "react";
import {
    VTButton,
    VTImmersiveImageSegment,
    VTSidePanel360MediaCard,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllImmersiveImagesMutation,
    useDeleteMediaMutation,
    useGetAll360ImagesQuery,
    usePreSignedUrlMutation,
    useUpdateAllImagesMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";

import NoScenarios from "../../assets/images/NoScenarios.svg";
import { Params, useParams } from "react-router-dom";
import SearchNotFound from "../../assets/images/NoVideos.svg";
import { CancelRounded } from "@mui/icons-material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useUpdateScenarioMutation } from "../../services/scenario.service";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import {
    EDraggableHotspotId,
    EDroppableContainerId,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import { EChoppingOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { setCurrentDoubleClick } from "../../store/slices/current.slice";
import VTMediaUploader from "./VTMediaUploader";
// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sideDrawWidth: number;
    immersiveImagesTitle?: string | undefined;
    sx?: SxProps;
    handleDragStart?: (mediaType: EDraggableOptions, src: string) => void;
    handleDragStop?: () => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImmersiveImageSidePanel({
    sideDrawWidth,
    handleDragStart,
    handleDragStop,
    immersiveImagesTitle,
    sx,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const [createAllImmersiveImages] = useCreateAllImmersiveImagesMutation();
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const { currentScenarioIsUpdating, currentlyDragging } = useAppSelector((state) => state.currentReducer);

    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [selectedImageError, setSelectedImageError] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>("");
    const [imageId, setImageId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const dispatch = useAppDispatch();

    const { data: images, isLoading: imagesLoading } = useGetAll360ImagesQuery(userId);
    const { data: scenarioImages, isLoading: scenarioImagesLoading } = useGetAll360ImagesQuery(scenarioId!);

    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();

    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedImage) {
            createAllImmersiveImages({
                id: userId,
                media_id: imageId,
                media_type: "three_sixty_image",
                filetype: "jpg",
                name: imageName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: "",
                chopping: true,
            });
        }
    }, [mediaUploadCompleted]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Handle the drag hooks

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(imageFile: File) {
        // TODO: in the future only allow certain image types
        if (imageFile.type.includes("image/") && !imageFile.type.includes("/svg")) {
            setSelectedImageError(false);
            setSelectedImage(imageFile);
            setImageName(imageFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation,
                media_type: "three_sixty_image",
                media_id: `${imageId}/raw`,
                filetype: "jpg",
            });
        } else {
            setSelectedImageError(true);
        }
    }

    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    // Function that handle image added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    function handleImageUpload() {
        if (uploadUrl && selectedImage) {
            dispatch(
                uploadMedia({
                    media: selectedImage,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedImage(null);
        setImageId(uuid());
        setImageName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available images

    const handleButtonClick = (buttonIndex: number) => {
        setActiveButton(buttonIndex);
    };

    return (
        <Grid
            container
            xs={12}
            justifyContent={"center"}
            // alignContent={"flex-start"}
            sx={{ overflowY: "hidden", userSelect: "none", ...sx }}
        >
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Images search */}
            <Grid item xs={11}>
                <VTTextField
                    sx={{
                        // height: "60px",
                        // borderRadius: "10px",
                        marginTop: "20px",
                    }}
                    value={searchValue}
                    onChange={(text: string) => {
                        setSearchValue(text);
                    }}
                    placeholder={"Search 360 Images"}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "15px",
                    display: "flex",
                    overflowX: "auto",
                }}
            >
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>All Images</Typography>
                    </VTButton>
                </Grid>
                <Grid item xs={4}>
                    {scenarioImages && (
                        <VTButton
                            onClick={() => handleButtonClick(2)}
                            sx={{
                                borderBottom: activeButton === 2 ? `3px solid ${theme.palette.primary.main}` : "none",
                                maxWidth: "100%",
                            }}
                            type="underline"
                        >
                            <Typography fontSize={"1em"}>Scenario Images</Typography>
                        </VTButton>
                    )}
                </Grid>

                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>Upload Images</Typography>
                    </VTButton>
                </Grid>
            </Grid>

            {activeButton === 1 && (
                <VTMediaUploader
                    context={"Immersive Images"}
                    onComplete={() => setActiveButton(0)}
                    sx={{ overflowX: "auto", height: "calc(100vh - 224px)" }}
                />
            )}
            {activeButton === 0 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Image list */}

                    {imagesLoading ? (
                        <Box
                            component="div"
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                                height: "calc(100vh - 230px)",
                            }}
                        >
                            <VTSidePanel360MediaCard sx={{ marginBottom: "8px" }} name={""} loading={true} />
                            <VTSidePanel360MediaCard sx={{ marginBottom: "8px" }} name={""} loading={true} />
                            <VTSidePanel360MediaCard sx={{}} name={""} loading={true} />
                        </Box>
                    ) : (
                        <>
                            {!images || images.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoScenarios} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first image file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Box
                                    // ref={provided.innerRef}
                                    // {...provided.droppableProps}
                                    component="div"
                                    sx={{
                                        marginTop: "5px",
                                        overflowY: "scroll",
                                        width: "100%",
                                        padding: "20px",
                                        height: "calc(100vh - 230px)",
                                    }}
                                >
                                    {images?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "250px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Droppable
                                            droppableId={EDroppableContainerId.MEDIA_360_IMAGES}
                                            type="DROPPABLE_MEDIA"
                                            // isDropDisabled={false}
                                            // renderClone={(provided, snapshot, rubric) => (
                                            //     <div
                                            //         {...provided.draggableProps}
                                            //         {...provided.dragHandleProps}
                                            //         ref={provided.innerRef}
                                            //     >
                                            //         Item id: {images[rubric.source.index].id}
                                            //     </div>
                                            // )}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    style={{ transform: "none !important" }}
                                                >
                                                    {images
                                                        ?.filter(
                                                            (item: IMediaItem) =>
                                                                searchValue === "" ||
                                                                item
                                                                    .name!.toLowerCase()
                                                                    .includes(searchValue.toLocaleLowerCase()),
                                                        )
                                                        .sort((a: IMediaItem, b: IMediaItem) => {
                                                            return (
                                                                new Date(b.date).valueOf() - new Date(a.date).valueOf()
                                                            );
                                                        })
                                                        .map((item: IMediaItem, i) => (
                                                            <Draggable
                                                                key={item.id}
                                                                draggableId={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${item.id}`}
                                                                index={i}
                                                                isDragDisabled={
                                                                    item.chopping === "error" ||
                                                                    item.chopping === "processing"
                                                                }
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <>
                                                                        <VTSidePanel360MediaCard
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                            sx={{
                                                                                marginBottom: "8px",
                                                                                // zIndex: 100000000,
                                                                                transform:
                                                                                    !snapshot.isDragging ||
                                                                                    (currentlyDragging &&
                                                                                        !snapshot.isDragging)
                                                                                        ? "none !important"
                                                                                        : "auto",
                                                                                ...(snapshot.isDragging && {
                                                                                    border: `4px dashed ${theme.palette.primary.light}`,
                                                                                    borderRadius: "16px",
                                                                                }),
                                                                            }}
                                                                            loading={currentScenarioIsUpdating}
                                                                            processingErrorMsg={
                                                                                item.chopping === EChoppingOptions.ERROR
                                                                                    ? "There has been an error processing this image, please try again"
                                                                                    : undefined
                                                                            }
                                                                            processingMsg={
                                                                                item.chopping ===
                                                                                EChoppingOptions.PROCESSING
                                                                                    ? "The image is being processed, please wait and refresh your page"
                                                                                    : undefined
                                                                            }
                                                                            // previewImageSrc={}
                                                                            pathTo360Images={
                                                                                item.chopping !==
                                                                                    EChoppingOptions.PROCESSING &&
                                                                                item.chopping !== EChoppingOptions.ERROR
                                                                                    ? `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${item.id}`
                                                                                    : undefined
                                                                            }
                                                                            name={item.name!}
                                                                            onHandleDoubleClick={() => {
                                                                                if (
                                                                                    item.chopping === "processing" ||
                                                                                    item.chopping === "error"
                                                                                ) {
                                                                                    return;
                                                                                }
                                                                                dispatch(
                                                                                    setCurrentDoubleClick({
                                                                                        src: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${item.id}`,
                                                                                        mediaType:
                                                                                            EDraggableOptions.IMMERSIVE_IMAGE,
                                                                                    } as IDragOptions),
                                                                                );
                                                                            }}
                                                                            onHandleDragStart={() => {
                                                                                // console.log("on handle drag start");
                                                                                // localHandleDrag(
                                                                                //     true,
                                                                                //     EDragContentOptions.IMAGE_360,
                                                                                //     `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${item.id}`,
                                                                                // );
                                                                            }}
                                                                            onHandleDragEnd={() => {
                                                                                // console.log("on handle drag end");
                                                                                // localHandleDrag(false);
                                                                            }}
                                                                        />
                                                                        {snapshot.isDragging && (
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        theme.palette.background.paper,
                                                                                    width: "100%",
                                                                                    height: "103px",
                                                                                    borderRadius: "15px",
                                                                                    transform: "none !important",
                                                                                    marginBottom: "10px",
                                                                                }}
                                                                            ></div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    )}
                                    {/* {provided.placeholder} */}
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}

            {activeButton === 2 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Image list */}
                    {scenarioImagesLoading ? (
                        <Box
                            component="div"
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                                height: "calc(100vh - 230px)",
                            }}
                        >
                            <VTSidePanel360MediaCard sx={{ marginBottom: "8px" }} name={""} loading={true} />
                            <VTSidePanel360MediaCard sx={{ marginBottom: "8px" }} name={""} loading={true} />
                            <VTSidePanel360MediaCard sx={{}} name={""} loading={true} />
                        </Box>
                    ) : (
                        <>
                            {!scenarioImages || scenarioImages.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoScenarios} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first image file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Box
                                    component="div"
                                    sx={{
                                        marginTop: "5px",
                                        overflowY: "scroll",
                                        width: "100%",
                                        padding: "20px",
                                        height: "calc(100vh - 230px)",
                                    }}
                                >
                                    {scenarioImages?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        scenarioImages
                                            ?.filter(
                                                (item: IMediaItem) =>
                                                    searchValue === "" ||
                                                    item
                                                        .name!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()) ||
                                                    item
                                                        .description!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()),
                                            )
                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                            })
                                            .map((item: any) => (
                                                // <>
                                                //     {(updateLoading || deleteLoading) &&
                                                //     currentItemUpdate == item.id ? (
                                                //         <Grid item xs={12}>
                                                //             <Skeleton
                                                //                 sx={{
                                                //                     height: "150px",
                                                //                     margin: "10px",
                                                //                     marginBlock: "-25px",
                                                //                 }}
                                                //             />
                                                //         </Grid>
                                                //     ) :
                                                //     (
                                                <VTSidePanel360MediaCard
                                                    sx={{ marginBottom: "8px", zIndex: 1 }}
                                                    loading={
                                                        (updateLoading || deleteLoading) && currentItemUpdate == item.id
                                                    }
                                                    processingErrorMsg={
                                                        item.chopping === "error"
                                                            ? "There has been an error processing this image, please try again"
                                                            : undefined
                                                    }
                                                    processingMsg={
                                                        item.chopping === "processing"
                                                            ? "The image is being processed, please wait and refresh your page"
                                                            : undefined
                                                    }
                                                    // previewImageSrc={}
                                                    pathTo360Images={
                                                        item.chopping !== "processing" && item.chopping !== "error"
                                                            ? `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${item.id}`
                                                            : undefined
                                                    }
                                                    name={item.name!}
                                                    onHandleDoubleClick={() => {}}
                                                    onHandleDragStart={() => {
                                                        // localHandleDrag(
                                                        //     true,
                                                        //     EDragContentOptions.IMAGE_360,
                                                        //     `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${item.id}`,
                                                        // );
                                                    }}
                                                    onHandleDragEnd={() => {
                                                        // localHandleDrag(false);
                                                    }}
                                                    onAddButtonClicked={() => {
                                                        setCurrentItemUpdate(item.id);
                                                        updateAllScenarioMedia({
                                                            id: userId,
                                                            media_type: EDraggableOptions.IMMERSIVE_IMAGE,
                                                            media_ids: [item.id],
                                                        });
                                                    }}
                                                />
                                            ))
                                    )}
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
