import { SvgIcon, SxProps, useTheme } from "@mui/material";

interface IProps {
    sx?: SxProps;
}

export default function IconImmersiveImage({ sx }: IProps) {
    const theme = useTheme();

    return (
        <SvgIcon sx={{ marginLeft: "-4px", ...sx }} viewBox="0 0 57.629 48.969">
            <g id="Group_237" data-name="Group 237" transform="translate(-900.186 -197.736)">
                <g id="Group_234" data-name="Group 234" transform="translate(645.108 -741)">
                    <path
                        id="downloading_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24"
                        d="M99.3-823.12a24.156,24.156,0,0,1-13.89-8.662A24.017,24.017,0,0,1,80-847.25a24.017,24.017,0,0,1,5.414-15.468A24.156,24.156,0,0,1,99.3-871.38a2.2,2.2,0,0,1,2.073.433,2.44,2.44,0,0,1,.9,1.98,2.564,2.564,0,0,1-.495,1.547,2.187,2.187,0,0,1-1.361.866,19.417,19.417,0,0,0-11.137,6.93A19.182,19.182,0,0,0,84.95-847.25a19.182,19.182,0,0,0,4.331,12.374,19.417,19.417,0,0,0,11.137,6.93,2.186,2.186,0,0,1,1.361.866,2.563,2.563,0,0,1,.495,1.547,2.44,2.44,0,0,1-.9,1.98A2.2,2.2,0,0,1,99.3-823.12Zm10.889,0a2.2,2.2,0,0,1-2.073-.433,2.44,2.44,0,0,1-.9-1.98,2.563,2.563,0,0,1,.495-1.547,2.186,2.186,0,0,1,1.361-.866,19.791,19.791,0,0,0,3.248-1.021,18.088,18.088,0,0,0,3-1.578,1.989,1.989,0,0,1,1.516-.371,2.538,2.538,0,0,1,1.454.742,2.368,2.368,0,0,1,.742,2.011,2.452,2.452,0,0,1-1.114,1.825,25.624,25.624,0,0,1-3.743,1.918A27.315,27.315,0,0,1,110.193-823.12Zm11.632-10.518a2.538,2.538,0,0,1-.742-1.454,1.989,1.989,0,0,1,.371-1.516,17.6,17.6,0,0,0,1.578-3.032,20.783,20.783,0,0,0,1.021-3.279,2.065,2.065,0,0,1,.866-1.33,2.681,2.681,0,0,1,1.547-.464,2.44,2.44,0,0,1,1.98.9,2.2,2.2,0,0,1,.433,2.073,27.319,27.319,0,0,1-1.3,3.991,25.618,25.618,0,0,1-1.918,3.743,2.452,2.452,0,0,1-1.825,1.114A2.368,2.368,0,0,1,121.825-833.638Zm4.64-16.086a2.563,2.563,0,0,1-1.547-.495,2.186,2.186,0,0,1-.866-1.361,19.791,19.791,0,0,0-1.021-3.248,18.088,18.088,0,0,0-1.578-3,2.184,2.184,0,0,1-.371-1.578,2.538,2.538,0,0,1,.742-1.454,2.369,2.369,0,0,1,2.011-.742,2.412,2.412,0,0,1,1.825,1.176,25.621,25.621,0,0,1,1.918,3.743,27.319,27.319,0,0,1,1.3,3.991,2.2,2.2,0,0,1-.433,2.073A2.44,2.44,0,0,1,126.465-849.725Zm-11.137-14.23a18.088,18.088,0,0,0-3-1.578,19.8,19.8,0,0,0-3.248-1.021,2.187,2.187,0,0,1-1.361-.866,2.564,2.564,0,0,1-.495-1.547,2.44,2.44,0,0,1,.9-1.98,2.2,2.2,0,0,1,2.073-.433,27.316,27.316,0,0,1,3.991,1.3,25.618,25.618,0,0,1,3.743,1.918,2.412,2.412,0,0,1,1.176,1.825,2.369,2.369,0,0,1-.742,2.011,2.538,2.538,0,0,1-1.454.742A2.184,2.184,0,0,1,115.328-863.955Z"
                        transform="translate(1135.735 1067.705) rotate(-90)"
                        fill={theme.palette.text.primary}
                    />
                    <path
                        id="downloading_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24-2"
                        data-name="downloading_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24"
                        d="M107.161-844.466l4.578-4.578a2.445,2.445,0,0,1,1.794-.742,2.445,2.445,0,0,1,1.794.742,2.341,2.341,0,0,1,.712,1.794,2.557,2.557,0,0,1-.773,1.794l-8.848,8.848a2.347,2.347,0,0,1-1.732.681,2.347,2.347,0,0,1-1.732-.681l-8.971-9.033a2.3,2.3,0,0,1-.712-1.763,2.524,2.524,0,0,1,.773-1.763,2.409,2.409,0,0,1,1.763-.742,2.409,2.409,0,0,1,1.763.742l4.64,4.7Z"
                        transform="translate(165.442 126.852) rotate(166)"
                        fill={theme.palette.text.primary}
                    />
                </g>
                <g id="image_black_24dp" transform="translate(923 210)">
                    <path id="Path_58" data-name="Path 58" d="M0,0H24V24H0Z" fill="none" />
                    <path
                        id="Path_59"
                        data-name="Path 59"
                        d="M21,19V5a2.006,2.006,0,0,0-2-2H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19A2.006,2.006,0,0,0,21,19ZM8.9,13.98,11,16.51l3.1-3.99a.5.5,0,0,1,.8.01l3.51,4.68a.5.5,0,0,1-.4.8H6.02a.5.5,0,0,1-.39-.81L8.12,14A.493.493,0,0,1,8.9,13.98Z"
                        fill={theme.palette.text.primary}
                    />
                </g>
            </g>
        </SvgIcon>
    );
}
