//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState } from "react";

// Custom components imports (and hooks and helpers)
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    setCurrentAudio,
    setCurrentHotspot,
    setCurrentPage,
    setCurrentScenario,
    setCurrentScenarioId,
    setCurrentScenarioName,
    setCurrentScene,
    setCurrentSidePanel,
    setEditHotspotDialogIsCurrentlyOpen,
} from "../../store/slices/current.slice";
import { Grid } from "@mui/material";
import CreatorTabsPanelContainer from "../containers/CreatorTabsPanelContainer";
import CreatorCanvasContainer from "../containers/CreatorCanvasContainer";
import CreatorFeedbackContainer from "../containers/CreatorFeedbackContainer";
import ReactGA from "react-ga4";
import { Params, useNavigate, useParams } from "react-router-dom";
import { useCreateSceneMutation, useGetScenesQuery } from "../../services/scene.service";
import { EOrganisation } from "../../models/organisations.model";
import { blankScene } from "../../helpers/scenarioDataGenerator";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IScenario, IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { useGetScenariosQuery } from "../../services/scenario.service";
import { v4 as uuid } from "uuid";

interface IProps {
    sceneLoading: string;
    setSceneLoading: (sceneLoading: string) => void;
    createScene: any;
    updateScene: any;
    hotspotLoading: string;
    setHotspotLoading: (hotpsotLoading: string) => void;
    createHotspot: any;
    updateHotspot: any;
    deleteHotspot: any;
    createSceneLoading: any;
    changeSceneBuffer: any;
    setChangeSceneBuffer: any;
    videoTimelineLoading: string;
    setVideoTimelineLoading: (videoTimelineLoading: string) => void;
}
//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Error Page, to display on breaking errors and allows home navigation
export default function Creator({
    sceneLoading,
    setSceneLoading,
    createScene,
    updateScene,
    hotspotLoading,
    setHotspotLoading,
    createHotspot,
    updateHotspot,
    deleteHotspot,
    createSceneLoading,
    changeSceneBuffer,
    setChangeSceneBuffer,
    videoTimelineLoading,
    setVideoTimelineLoading,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const { currentScene, currentlyDragging, currentQuestion, currentAudio } = useAppSelector(
        (state) => state.currentReducer,
    );

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [currentAttachedObject, setCurrentAttachedObject] = useState<any>(undefined);
    const [videoCurrentState, setVideoCurrentState] = useState<"Paused" | "Playing" | "Question" | undefined>(
        undefined,
    );

    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data: scenes, isLoading: isScenesLoading } = useGetScenesQuery(scenarioId ? scenarioId : "");
    const { data: scenarios } = useGetScenariosQuery(userId ? userId : "");

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        dispatch(
            setCurrentSidePanel({
                currentTab: undefined,
                currentWidth: 0,
                option: undefined,
            }),
        );

        dispatch(setCurrentPage("creator"));
        // on component dismount
        return () => {
            dispatch(setEditHotspotDialogIsCurrentlyOpen(false));
        };
    }, []);

    useEffect(() => {
        console.log("DBG:", changeSceneBuffer);
        if (changeSceneBuffer && !createSceneLoading && !isScenesLoading) {
            const timeoutId = setTimeout(() => {
                navigate(`/creator/${scenarioId}/${changeSceneBuffer}`);
                setChangeSceneBuffer(undefined);
            }, 300);

            // Cleanup function to clear the timeout if the component unmounts
            return () => clearTimeout(timeoutId);
        }
    }, [changeSceneBuffer, createSceneLoading, isScenesLoading]);

    //-------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (scenarioId) {
            ReactGA.send({ hitType: "pageview", page: `/creator/${scenarioId}`, title: scenarioId });
            dispatch(setCurrentScenarioId(scenarioId));

            try {
                if (
                    scenarios &&
                    scenarios.length > 0 &&
                    scenarios.find((findElement: IScenario) => findElement.id === scenarioId)
                ) {
                    dispatch(
                        setCurrentScenarioName(
                            scenarios!.find((findElement: IScenario) => findElement.id === scenarioId)!.title
                                ? scenarios.find((findElement: IScenario) => findElement.id === scenarioId)!.title
                                : "",
                        ),
                    );

                    dispatch(
                        setCurrentScenario(
                            scenarios!.find((findElement: IScenario) => findElement.id === scenarioId)!.title
                                ? scenarios.find((findElement: IScenario) => findElement.id === scenarioId)!
                                : ({} as IScenario),
                        ),
                    );
                }
            } catch {}
        }
    }, [scenarioId, scenarios]);

    //-------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (scenes) {
            if (scenes.length === 0) {
                if (organisation === EOrganisation.ECO_GOAL) {
                    createScene({
                        scenarioId: scenarioId!,
                        scene: blankScene({ newSrc: "", type: EDraggableOptions.DOCUMENT, id: uuid(), orderId: 0 }),
                    });
                } else {
                    setSceneLoading("CreateScene");
                    createScene({
                        scenarioId: scenarioId!,
                        scene: blankScene({
                            newSrc: "https://medias3.digi-sim-api.co.uk/app_defaults/default_360_image",
                            type: EDraggableOptions.IMMERSIVE_IMAGE,
                            id: uuid(),
                            orderId: 0,
                        }),
                    });
                }
            } else {
                if (!sceneId && scenes && scenes.length > 0) {
                    const orderedScenes = [...scenes].sort((element1: IScene, element2: IScene) => {
                        if (element1.order === undefined) {
                            return 1;
                        } else if (element2.order === undefined) {
                            return -1;
                        } else {
                            return element2.order < element1.order ? 1 : -1;
                        }
                    });

                    // If no scene is provided in the url load first scene in the array
                    navigate(`/creator/${scenarioId}/${orderedScenes[0].id}`);
                    dispatch(setCurrentScene(orderedScenes[0]));
                } else if (scenes) {
                    // If sceneId was provided find scene and set current scene
                    const foundScene: IScene | undefined = scenes.find((scene: IScene) => scene.id === sceneId);

                    if (foundScene) {
                        dispatch(setCurrentScene(foundScene));
                        if (currentAudio || foundScene.audio) {
                            dispatch(setCurrentAudio(foundScene.audio ? foundScene.audio : ""));
                        }
                    } else {
                        // If the sceneId could no be found load first scene
                        const orderedScenes = [...scenes].sort((element1: IScene, element2: IScene) => {
                            if (element1.order === undefined) {
                                return 1;
                            } else if (element2.order === undefined) {
                                return -1;
                            } else {
                                return element2.order < element1.order ? 1 : -1;
                            }
                        });

                        navigate(`/creator/${scenarioId}/${orderedScenes[0].id}`);
                        dispatch(setCurrentScene(orderedScenes[0]));
                    }
                    dispatch(setCurrentHotspot({ currentHotspot: undefined, open: false }));
                }
            }
        }
    }, [sceneId, scenes]);

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Displays return button and error message
    return (
        <>
            {/* Page content */}
            <Grid container xs={12} justifyContent={"flex-end"}>
                {/*-----------------------------------------------------------------------------------------*/}
                {/* Left Side panel */}
                <CreatorTabsPanelContainer
                    videoCurrentState={videoCurrentState}
                    setVideoCurrentState={setVideoCurrentState}
                />

                {/*-----------------------------------------------------------------------------------------*/}
                {/* Main page content */}

                <CreatorCanvasContainer
                    currentAttachedObject={currentAttachedObject}
                    setCurrentAttachedObject={setCurrentAttachedObject}
                    videoCurrentState={videoCurrentState}
                    setVideoCurrentState={setVideoCurrentState}
                    sceneLoading={sceneLoading}
                    setSceneLoading={setSceneLoading}
                    createScene={createScene}
                    updateScene={updateScene}
                    hotspotLoading={hotspotLoading}
                    setHotspotLoading={setHotspotLoading}
                    createHotspot={createHotspot}
                    updateHotspot={updateHotspot}
                    deleteHotspot={deleteHotspot}
                    createSceneLoading={createSceneLoading}
                    setChangeSceneBuffer={setChangeSceneBuffer}
                    videoTimelineLoading={videoTimelineLoading}
                    setVideoTimelineLoading={setVideoTimelineLoading}
                />

                {/*-----------------------------------------------------------------------------------------*/}
                {/* In app Feedback */}
                <CreatorFeedbackContainer />
            </Grid>
        </>
    );
}
