import VTTimelineItem from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTTimelineItem";

import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";

import NoPreview from "../../../assets/images/NoScenarioImage.svg";
import NoPreviewFile from "../../../assets/images/NoFilesImage.svg";
import { Node, NodeProps } from "@xyflow/react";
import { getImageForSceneOrDefaultImageIfPreviewImageDoesntExist } from "../../../utils/getImageForSceneOrDefaultImageIfPreviewImageDoesntExist";

type NodeData = {
    scene: IScene;
};

type CustomNode = Node<NodeData>;

export default function VTNodeScene({ data }: NodeProps<CustomNode>) {
    console.log("scene: ", data.scene);
    return (
        <VTTimelineItem
            onClicked={() => {}}
            mediaType={data.scene.mediaType}
            disableHover
            image={getImageForSceneOrDefaultImageIfPreviewImageDoesntExist(data.scene)}
            name={data.scene.name}
        />
    );
}
