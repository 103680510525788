//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports

import { Grid, SxProps, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";

import { AddRounded, DeleteRounded } from "@mui/icons-material";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";

// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    item: { id?: string; name?: string; filetype?: string; src?: string };
    organisation: any;
    localHandleDrag: any;
    handleDoubleClick: (mediaType: EDraggableOptions, src: string) => void;
    addFunction?: any;
    deleteFunction?: any;
    sx?: SxProps;
    xs?: number;
    type?: EDraggableOptions;
    displaySrc?: string;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImageDraggable({
    item,
    organisation,
    localHandleDrag,
    handleDoubleClick,
    addFunction,
    deleteFunction,
    sx,
    xs = 6,
    type = EDraggableOptions.IMAGE,
    displaySrc,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    const [dragging, setDragging] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);
    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Render for the tab buttons
    function textCutOffAddElipsis(text: string, cutOffPointCharIndex: number) {
        const charAtIndex = text.charAt(cutOffPointCharIndex);
        if (charAtIndex === " ") {
            return text.substring(0, cutOffPointCharIndex) + "...";
        } else if (charAtIndex === "") {
            return text;
        } else {
            return text.substring(0, cutOffPointCharIndex + 1) + "...";
        }
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <Grid
            item
            key={item.id}
            xs={xs}
            sx={{
                padding: "5px",
                height: "200px",
                position: "relative",
                ...sx,
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {hover && (
                <>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            position: "absolute",
                            top: 5,
                            left: 5,
                            right: 5,
                            borderRadius: "5px 5px 0px 0px",
                            userSelect: "none",
                            height: "35px",
                            backgroundColor: "rgba(22,22,22,0.8)",
                        }}
                    >
                        <Typography
                            sx={{
                                marginLeft: "5px",
                                marginTop: "5px",
                            }}
                        >
                            {item && item.name && item.name?.length > 14
                                ? textCutOffAddElipsis(item.name, 14)
                                : item.name}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            position: "absolute",
                            bottom: 5,
                            right: 15,
                            userSelect: "none",
                            height: "35px",
                            zIndex: 10000,
                        }}
                    >
                        {deleteFunction && (
                            <Tooltip title={"Delete from scenario media"}>
                                <DeleteRounded
                                    onClick={deleteFunction}
                                    sx={{
                                        backgroundColor: "rgba(22,22,22,0.8)",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                    }}
                                />
                            </Tooltip>
                        )}
                        {addFunction && (
                            <Tooltip title={"Add to your media"}>
                                <AddRounded
                                    onClick={addFunction}
                                    sx={{
                                        backgroundColor: "rgba(22,22,22,0.8)",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                </>
            )}
            <img
                // TODO: change to media type
                src={
                    displaySrc
                        ? displaySrc
                        : `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`
                }
                alt={item.name}
                loading="lazy"
                style={{
                    objectFit: type === EDraggableOptions.DOCUMENT ? "contain" : "cover",
                    borderRadius: "5px",
                    cursor: "grab",
                    height: "190px",
                    width: "97%",
                    zIndex: 1,
                    position: "absolute",
                    top: "5px",
                    left: "5px",
                    right: "5px",
                    opacity: dragging ? 1 : 0,
                }}
                draggable={true}
                onDragStart={(e) => {
                    e.dataTransfer.setData("mediaType", "image");

                    localHandleDrag(
                        true,
                        type,
                        item.src
                            ? item.src
                            : `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`,
                    );
                    setDragging(true);
                }}
                onDragEnd={() => {
                    localHandleDrag(false);
                    setDragging(false);
                }}
                onDoubleClick={() =>
                    handleDoubleClick(
                        type,
                        item.src
                            ? item.src
                            : `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`,
                    )
                }
            />
            <img
                // TODO: change to media type
                src={
                    displaySrc
                        ? displaySrc
                        : `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`
                }
                alt={item.name}
                loading="lazy"
                style={{
                    objectFit: type === EDraggableOptions.DOCUMENT ? "contain" : "cover",
                    borderRadius: "5px",
                    height: "100%",
                    width: "100%",
                    zIndex: 1,
                    backgroundColor: "#333333",
                }}
            />
        </Grid>
    );
}
