//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Params, useParams } from "react-router-dom";
import ReactGA from "react-ga4";

// Virtus tech Repo
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";

// Material ui Imports
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Grid, Paper, useTheme } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// Custom components imports (and hooks and helpers)
import Scenario360ImageCardsContainer from "../containers/Scenes360ImageCardsContainer";
import ImmersiveVideosRepoContainer from "../containers/ImmersiveVideosRepoContainer";
import ImmersiveModelsCardContainer from "../containers/ImmersiveModelsCardContainer";
import ImagesPageCardContainer from "../containers/ImagesPageCardContainer";
import AudioCardContainer from "../containers/AudioCardContainer";
import { setCurrentPage, setCurrentSidePanel } from "../../store/slices/current.slice";
import HomeRouteSidePanel from "../components/HomeRouteSidePanel";
import VideoCardContainer from "../containers/VideoCardContainer";
import { useAppSelector } from "../../store/hooks";
import {
    useDeleteMediaMutation,
    useGetAll360ImagesQuery,
    useGetAll360VideosQuery,
    useGetAllAudioQuery,
    useGetAllImagesQuery,
    useGetAllImmersiveModelsQuery,
    useGetAllVideos2dQuery,
} from "../../services/media.service";

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function Media() {
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { id: userId } = useAppSelector((state) => state.userReducer);
    const { currentSidePanel, currentSidePannelDesiredWidth } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { mediaPage }: Readonly<Params<string>> = useParams();
    const dispatch = useDispatch();
    const { data: immersiveImageData, isLoading: immersiveImagesLoading } = useGetAll360ImagesQuery(userId);
    const { data: immersiveVideoData, isLoading: immersiveVideosLoading } = useGetAll360VideosQuery(userId);
    const { data: imageData, isLoading: imagesLoading } = useGetAllImagesQuery(userId);
    const { data: videoData, isLoading: videosLoading } = useGetAllVideos2dQuery(userId);
    const { data: audioData, isLoading: audioLoading } = useGetAllAudioQuery(userId);
    const { data: modelData, isLoading: modelsLoading } = useGetAllImmersiveModelsQuery(userId);
    const [deleteMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();
    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const gridContainerRef = useRef<any>();

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        dispatch(setCurrentPage("media"));
        if (gridContainerRef.current) {
            gridContainerRef.current.scrollTo(0, 0);
        }

        ReactGA.send({ hitType: "pageview", page: "/Media", title: mediaPage });
    }, [mediaPage]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function deleteMediaItem(mediaType: EDraggableOptions, collectionId: string, mediaId: string) {
        deleteMedia({ mediaType: mediaType, collectionId: collectionId, mediaId: mediaId });
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <Grid container xs={12} sx={{ overflowY: "auto", height: "100%" }}>
            <Grid ref={gridContainerRef} xs={12} item container>
                {/*---------------------------------------------------------------------------------*/}
                {/* App routes side panel */}
                <Paper
                    sx={{
                        zIndex: 1330,
                        position: "absolute",
                        top: "68px",
                        left: currentSidePanel.currentWidth ? 0 : -200,
                        bottom: 0,
                        borderRadius: 0,
                        width: 200,
                        // transition: "0.5s",
                        backgroundColor: `#2f3032`,
                    }}
                >
                    {currentSidePanel.currentWidth === 0 ? (
                        <VTButton
                            type={"icon"}
                            toolTipOptions={{
                                toolTipPlacement: "right",
                                toolTipText: "Expand",
                            }}
                            sx={{
                                position: "absolute",
                                top: "5px",
                                left: 200,
                                width: "30px",
                                height: "30px",
                                borderRadius: "0px 15px 15px 0px",
                                zIndex: 10,
                                backgroundColor: `${theme.palette.background.paper}`,
                                fontSize: "0.3em",
                                fontWeight: "bold",
                                ":hover": {
                                    cursor: "pointer",
                                    backgroundColor: "#5a5b5d",
                                },
                            }}
                            onClick={() =>
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Routes",
                                        currentWidth: 200,
                                        option: undefined,
                                    }),
                                )
                            }
                        >
                            <KeyboardArrowRightRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                        </VTButton>
                    ) : (
                        <VTButton
                            type={"icon"}
                            toolTipOptions={{
                                toolTipPlacement: "top-end",
                                toolTipText: "Collapse",
                            }}
                            sx={{
                                position: "absolute",
                                left: currentSidePanel.currentWidth,
                                top: "5.5px",
                                width: "30px",
                                height: "30px",
                                borderRadius: "0px 15px 15px 0px",
                                zIndex: 10,
                                backgroundColor: `${theme.palette.background.paper}`,
                                fontSize: "0.3em",
                                ":hover": {
                                    cursor: "pointer",

                                    backgroundColor: "#5a5b5d",
                                },
                            }}
                            onClick={() =>
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: undefined,
                                        currentWidth: 0,

                                        option: undefined,
                                    }),
                                )
                            }
                        >
                            <CloseRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                        </VTButton>
                    )}

                    <HomeRouteSidePanel />
                </Paper>

                {/*---------------------------------------------------------------------------------*/}
                {/* Immersive Image page */}
                {mediaPage === "ImmersiveImages" && (
                    <Scenario360ImageCardsContainer
                        loading={immersiveImagesLoading}
                        images360={immersiveImageData}
                        leftDrawWidth={currentSidePanel.currentWidth}
                        deleteMediaItem={deleteMediaItem}
                        deleteLoading={deleteLoading}
                    />
                )}

                {/*---------------------------------------------------------------------------------*/}
                {/* Images */}
                {mediaPage === "Images" && (
                    <ImagesPageCardContainer
                        loading={imagesLoading}
                        leftDrawWidth={currentSidePanel.currentWidth}
                        images={imageData}
                        deleteMediaItem={deleteMediaItem}
                        deleteLoading={deleteLoading}
                    />
                )}

                {/*---------------------------------------------------------------------------------*/}
                {/* Immersive Video page */}
                {mediaPage === "ImmersiveVideos" && (
                    <ImmersiveVideosRepoContainer
                        loading={immersiveVideosLoading}
                        immersiveVideos={immersiveVideoData}
                        leftDrawWidth={currentSidePanel.currentWidth}
                        deleteMediaItem={deleteMediaItem}
                        deleteLoading={deleteLoading}
                    />
                )}

                {/*---------------------------------------------------------------------------------*/}
                {/* Video page */}
                {mediaPage === "Videos" && (
                    <VideoCardContainer
                        loading={videosLoading}
                        leftDrawWidth={currentSidePanel.currentWidth}
                        videos={videoData}
                        deleteMediaItem={deleteMediaItem}
                        deleteLoading={deleteLoading}
                    />
                )}

                {/*---------------------------------------------------------------------------------*/}
                {/* Immersive Model page */}
                {mediaPage === "ImmersiveModels" && (
                    <ImmersiveModelsCardContainer
                        leftDrawWidth={currentSidePanel.currentWidth}
                        deleteMediaItem={deleteMediaItem}
                        deleteLoading={deleteLoading}
                        loading={modelsLoading}
                        models={modelData}
                    />
                )}

                {/*---------------------------------------------------------------------------------*/}
                {/* Audio */}
                {mediaPage === "Audio" && (
                    <AudioCardContainer
                        audio={audioData}
                        leftDrawWidth={currentSidePanel.currentWidth}
                        deleteMediaItem={deleteMediaItem}
                        loading={audioLoading}
                        deleteLoading={deleteLoading}
                    />
                )}
            </Grid>
        </Grid>
    );
}
