import { ReactFlow, Controls, Background, NodeTypes, Node, FitViewOptions, DefaultEdgeOptions } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import VTNodeScene from "./VTNodes/VTNodeScene";
import { useEffect, useMemo, useState } from "react";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import VTNodeHotspot from "./VTNodes/VTNodeHotspot";
import { Params, useParams } from "react-router-dom";
import { useGetScenarioOverviewQuery } from "../../services/scenario.service";

const nodeTypes: NodeTypes = {
    scene: VTNodeScene,
    hotspot: VTNodeHotspot,
};
const fitViewOptions: FitViewOptions = {
    padding: 0.2,
};

const defaultEdgeOptions: DefaultEdgeOptions = {
    animated: true,
};

interface IVTNodeEditorProps {
    scenes: IScene[] | undefined;
    hotspots: IHotspot[] | undefined;
}

export default function VTNodeWrapper({ scenes, hotspots }: IVTNodeEditorProps) {
    const { scenarioId }: Readonly<Params<string>> = useParams();

    const { data, isLoading } = useGetScenarioOverviewQuery(scenarioId ? scenarioId : "");

    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        console.log("nodes: ", nodes, scenes, hotspots);
    }, [scenes, hotspots]);

    useEffect(() => {
        console.log("data: ", data);

        const SceneData = [];

        if (data && data.scenes) {
            data.scenes.map((scene: IScene, i: number) => SceneData.push(SceneToNode(scene, i)));
        }
    }, [data]);

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <ReactFlow
                nodes={nodes}
                fitView
                fitViewOptions={fitViewOptions}
                defaultEdgeOptions={defaultEdgeOptions}
                nodeTypes={nodeTypes}
            >
                <Background />
                <Controls />
            </ReactFlow>
        </div>
    );
}

function SceneToNode(scene: IScene, index: number): Node {
    return {
        id: scene.id,
        data: { scene: scene },
        position: { x: index * 200, y: 0 },
        type: "scene",
    };
}

function HotspotToNode(hotspot: IHotspot, index: number): Node {
    return {
        id: hotspot.id,
        data: { hotspot },
        position: { x: index * 200, y: 0 },
        type: "hotspot",
    };
}
