//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, SxProps, TextField, useMediaQuery, useTheme } from "@mui/material";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { Droppable } from "react-beautiful-dnd";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";

// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    currentImage: string;
    setCurrentImage: (currentPreviewImage: string) => void;
    disabled?: boolean;
    sx?: SxProps;
    droppableId: EDroppableContainerId;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImageDropper({ currentImage, setCurrentImage, disabled = false, sx, droppableId }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();
    const { currentSidePanel, currentSidePannelDesiredWidth } = useAppSelector((state) => state.currentReducer);
    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Render for the tab buttons

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "20px", ...sx }}>
            {/*----------------------------------------------------------------------------------------*/}
            {/* Relative container to allow the drop box to overlap the image */}
            <Grid item container sx={{ position: "relative" }} xs={12} justifyContent={"center"}>
                {(currentSidePanel.currentTab === "Images" ||
                    currentImage !== "" ||
                    droppableId === EDroppableContainerId.SCENARIO_PREVIEW_IMAGE_DROP) && (
                    <Droppable droppableId={droppableId} type="DROPPABLE_MEDIA" isDropDisabled={false}>
                        {(provided, snapshot) => {
                            // console.log("p", provided);
                            // console.log("s", snapshot);
                            return (
                                <img
                                    id="canvas-drop-container"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    src={
                                        currentImage === ""
                                            ? "https://virtus-ninja-dev.s3.eu-west-1.amazonaws.com/virtus_tech/images/noImage.png"
                                            : currentImage
                                    }
                                    style={{
                                        width: "80%",
                                        height: "193px",
                                        objectFit: "cover",
                                        borderRadius: 15,
                                        border: snapshot.isDraggingOver
                                            ? `4px dashed ${theme.palette.primary.light}`
                                            : `4px dashed ${theme.palette.primary.dark}`,
                                    }}
                                />
                            );
                        }}

                        {/*-------------------------------------------------------------------------------------*/}
                        {/* Drop field, accepts urls from dragged images to set image, style to be less visible */}
                    </Droppable>
                )}
                {/* {(currentSidePanel.currentTab === "Images" || currentImage !== "") && (
                    <img
                        id="canvas-drop-container"
                        src={
                            currentImage === ""
                                ? "https://virtus-ninja-dev.s3.eu-west-1.amazonaws.com/virtus_tech/images/noImage.png"
                                : currentImage
                        }
                        style={{
                            width: "80%",
                            height: "193px",
                            objectFit: "cover",
                            borderRadius: 15,
                        }}
                    />
                )} */}

                <Grid item container xs={12} justifyContent={"space-evenly"}>
                    {/*----------------------------------------------------------------------------*/}
                    {/* Button to open the image side panel */}
                    {(currentSidePanel.currentWidth === 0 || currentSidePanel.currentTab !== "Images") && (
                        <Grid item xs={5}>
                            <VTButton
                                sx={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    marginTop: "15px",
                                }}
                                onClick={() =>
                                    dispatch(
                                        setCurrentSidePanel({
                                            currentTab: "Images",
                                            currentWidth: currentSidePannelDesiredWidth,
                                            option: undefined,
                                        }),
                                    )
                                }
                            >
                                Choose image
                            </VTButton>
                        </Grid>
                    )}

                    {currentImage !== "" && (
                        <Grid item xs={5}>
                            <VTButton
                                sx={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    marginTop: "15px",
                                }}
                                onClick={() => setCurrentImage("")}
                            >
                                Remove image
                            </VTButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
