//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import {
    VTButton,
    VTSidePanelImage2dItem,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import NoScenarios from "../../assets/images/NoScenarios.svg";
// Material ui Imports
import {
    CircularProgress,
    Grid,
    ImageList,
    ImageListItem,
    LinearProgress,
    Skeleton,
    SxProps,
    Typography,
    useTheme,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

// Custom components imports (and hooks and helpers)
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllImagesMutation,
    useDeleteMediaMutation,
    useGetAllImagesQuery,
    usePreSignedUrlMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import VTImageDraggable from "./VTImageDraggable";
import { Params, useParams } from "react-router-dom";
// Media imports
import SearchNotFound from "../../assets/images/upload_image.svg";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import { setCurrentDoubleClick, setEditHotspotDialogMediaTransfer } from "../../store/slices/current.slice";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import VTMediaUploader from "./VTMediaUploader";
import { moveCursorToEnd } from "../../utils/moveCursorToEndRichTextEditor";
import WordingHelper from "../../helpers/wordingHelper";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##

// Layout stuff remains inline, other styling pulled into its own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sideDrawWidth: number;

    imagesTitle: string;
    sx?: SxProps;
    handleDragStart?: (mediaType: EDraggableOptions, src: string) => void;
    handleDragStop?: () => void;
    onHandleDoubleClickSetScenarioImagePreview?: (imageSrc: string) => void;
    videoCurrentState?: "Paused" | "Playing" | "Question" | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImageSidePanel({
    sideDrawWidth,
    imagesTitle,
    handleDragStart,
    handleDragStop,
    sx,
    videoCurrentState,
    onHandleDoubleClickSetScenarioImagePreview,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const theme = useTheme();

    const [createAllImages] = useCreateAllImagesMutation();

    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [selectedImageError, setSelectedImageError] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>("");
    const [imageId, setImageId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const { data: images, isLoading: imagesLoading } = useGetAllImagesQuery(userId);
    const { data: scenarioImages, isLoading: scenarioImagesLoading } = useGetAllImagesQuery(scenarioId!);

    const dispatch = useAppDispatch();
    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();
    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const {
        currentQuestion,
        currentRichTextEditorNormalModeId,
        editHotspotDialogIsCurrentlyOpen,
        editHotspotDialogTabCurrentlyOpen,
    } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedImage) {
            createAllImages({
                id: userId,
                media_id: imageId,
                media_type: "images",
                filetype: selectedImage.type.split("/")[1],
                name: imageName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: "",
            });
        }
    }, [mediaUploadCompleted]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    function handleButtonClick(buttonIndex: number) {
        setActiveButton(buttonIndex);
    }

    function localHandleDrag(dragged: boolean, mediaType?: EDraggableOptions, src?: string) {
        if (dragged && handleDragStart && mediaType && src) {
            handleDragStart(mediaType, src);
        } else if (!dragged && handleDragStop) {
            handleDragStop();
        }
    }

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(imageFile: File) {
        // TODO: in the future only allow certain image types
        if (imageFile.type.includes("image/") && !imageFile.type.includes("/svg")) {
            setSelectedImageError(false);
            setSelectedImage(imageFile);
            setImageName(imageFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation,
                media_type: "images",
                media_id: imageId,
                filetype: imageFile.type.split("/")[1],
            });
        } else {
            setSelectedImageError(true);
        }
    }

    // Function that handle image added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle image added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleImageUpload() {
        if (uploadUrl && selectedImage) {
            dispatch(
                uploadMedia({
                    media: selectedImage,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedImage(null);
        setImageId(uuid());
        setImageName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    function handleDoubleClick(mediaType: EDraggableOptions, src: string) {
        dispatch(setCurrentDoubleClick({ src: src, mediaType: mediaType }));
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <Grid container xs={12} justifyContent={"center"} sx={{ overflowY: "hidden", userSelect: "none", ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Images search */}
            <Grid item xs={11}>
                <VTTextField
                    placeholder={"Search Images"}
                    sx={{
                        marginTop: "20px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "15px",
                    display: "flex",
                    // justifyContent: "center",
                    // height: "80px",
                }}
            >
                <Grid item xs={scenarioId ? 4 : 6}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? `3px solid ${theme.palette.primary.main}` : "none",
                            width: "100%",
                            height: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>All Images</Typography>
                    </VTButton>
                </Grid>
                {scenarioId && (
                    <Grid item xs={4}>
                        {scenarioImages && (
                            <VTButton
                                onClick={() => handleButtonClick(2)}
                                sx={{
                                    borderBottom:
                                        activeButton === 2 ? `3px solid ${theme.palette.primary.main}` : "none",
                                    width: "100%",
                                }}
                                type="underline"
                            >
                                <Typography fontSize={"1em"}>{WordingHelper(organisation)} Images</Typography>
                            </VTButton>
                        )}
                    </Grid>
                )}
                <Grid item xs={scenarioId ? 4 : 6}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>Upload Image</Typography>
                    </VTButton>
                </Grid>
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Image upload */}
            {activeButton === 1 && (
                <VTMediaUploader
                    context={"Images"}
                    onComplete={() => setActiveButton(0)}
                    sx={{ overflowX: "auto", height: "calc(100vh - 224px)" }}
                />
            )}
            {activeButton === 0 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Image list */}
                    {imagesLoading ? (
                        <Grid
                            item
                            container
                            xs={12}
                            columnSpacing={1}
                            alignContent={"flex-start"}
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                            }}
                        >
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {/* @ts-ignore */}
                            {!images || images.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first image file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    // columnSpacing={1}
                                    sx={{
                                        marginTop: "5px",
                                        overflowY: "scroll",
                                        width: "100%",
                                        padding: "20px",
                                        height: "calc(100vh - 230px)",
                                    }}
                                    alignContent={"flex-start"}
                                >
                                    {images &&
                                    images?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Droppable
                                            droppableId={EDroppableContainerId.MEDIA_2D_IMAGES}
                                            type="DROPPABLE_MEDIA"
                                            // isDropDisabled={false}
                                            // renderClone={(provided, snapshot, rubric) => (
                                            //     <div
                                            //         {...provided.draggableProps}
                                            //         {...provided.dragHandleProps}
                                            //         ref={provided.innerRef}
                                            //     >
                                            //         Item id: {images[rubric.source.index].id}
                                            //     </div>
                                            // )}
                                        >
                                            {(provided, snapshot) => (
                                                // <Grid
                                                //     item
                                                //     container
                                                //     xs={12}
                                                //     // columnSpacing={1}
                                                //     sx={{
                                                //         // marginTop: "5px",
                                                //         // overflowY: "scroll",
                                                //         width: "100%",
                                                //         // padding: "20px",
                                                //         height: "calc(100vh - 230px)",
                                                //     }}
                                                //     alignContent={"flex-start"}
                                                // >
                                                <ImageList
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    sx={{ marginTop: "0px" }}
                                                    variant="masonry"
                                                    cols={2}
                                                    gap={8}
                                                >
                                                    {images &&
                                                        images
                                                            .filter(
                                                                (item: IMediaItem) =>
                                                                    searchValue === "" ||
                                                                    item
                                                                        .name!.toLowerCase()
                                                                        .includes(searchValue.toLocaleLowerCase()) ||
                                                                    item
                                                                        .description!.toLowerCase()
                                                                        .includes(searchValue.toLocaleLowerCase()),
                                                            )
                                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                                return (
                                                                    new Date(b.date).valueOf() -
                                                                    new Date(a.date).valueOf()
                                                                );
                                                            })
                                                            .map((item: IMediaItem, i) => {
                                                                return (
                                                                    <Draggable
                                                                        key={item.id}
                                                                        draggableId={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`}
                                                                        index={i}
                                                                        isDragDisabled={false}
                                                                    >
                                                                        {(provided, snapshot) => (
                                                                            <>
                                                                                <ImageListItem key={item.id}>
                                                                                    <VTSidePanelImage2dItem
                                                                                        nameCutOffPoint={12}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={provided.innerRef}
                                                                                        // loading
                                                                                        sx={{
                                                                                            // height: "100px",
                                                                                            // width: "100%",
                                                                                            ...(snapshot.isDragging && {
                                                                                                border: `4px dashed ${theme.palette.primary.light}`,
                                                                                                borderRadius: "16px",
                                                                                                // transform: "none !important"
                                                                                            }),
                                                                                        }}
                                                                                        name={
                                                                                            item.name
                                                                                                ? item.name
                                                                                                : "no name"
                                                                                        }
                                                                                        imgSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`}
                                                                                        onHandleDoubleClick={() => {
                                                                                            if (
                                                                                                onHandleDoubleClickSetScenarioImagePreview
                                                                                            ) {
                                                                                                onHandleDoubleClickSetScenarioImagePreview(
                                                                                                    `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`,
                                                                                                );
                                                                                            }
                                                                                            // this means rich text editor (normal mode) is open
                                                                                            else if (
                                                                                                editHotspotDialogIsCurrentlyOpen &&
                                                                                                editHotspotDialogTabCurrentlyOpen ===
                                                                                                    "content" &&
                                                                                                currentRichTextEditorNormalModeId &&
                                                                                                currentRichTextEditorNormalModeId.length >
                                                                                                    0
                                                                                            ) {
                                                                                                moveCursorToEnd(
                                                                                                    currentRichTextEditorNormalModeId!,
                                                                                                );
                                                                                                document.execCommand(
                                                                                                    "insertHTML",
                                                                                                    false,
                                                                                                    `<img src="${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}" draggable="true"/>`,
                                                                                                );
                                                                                                moveCursorToEnd(
                                                                                                    currentRichTextEditorNormalModeId!,
                                                                                                );
                                                                                            } else {
                                                                                                if (
                                                                                                    editHotspotDialogTabCurrentlyOpen ===
                                                                                                    "actions"
                                                                                                ) {
                                                                                                    dispatch(
                                                                                                        setEditHotspotDialogMediaTransfer(
                                                                                                            {
                                                                                                                src: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`,
                                                                                                                type: EDraggableOptions.IMAGE,
                                                                                                            },
                                                                                                        ),
                                                                                                    );
                                                                                                } else {
                                                                                                    handleDoubleClick(
                                                                                                        EDraggableOptions.IMAGE,
                                                                                                        item.src
                                                                                                            ? item.src
                                                                                                            : `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`,
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </ImageListItem>
                                                                                {snapshot.isDragging && (
                                                                                    <div
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                theme.palette.background
                                                                                                    .paper,
                                                                                            width: "100%",
                                                                                            height: "150px",
                                                                                            borderRadius: "15px",
                                                                                            transform:
                                                                                                "none !important",
                                                                                            marginBottom: "10px",
                                                                                        }}
                                                                                    ></div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Draggable>
                                                                    // <VTImageDraggable
                                                                    //     key={item.id}
                                                                    //     item={item}
                                                                    //     organisation={organisation}
                                                                    //     localHandleDrag={localHandleDrag}
                                                                    //     handleDoubleClick={handleDoubleClick}
                                                                    // />
                                                                );
                                                            })}
                                                </ImageList>
                                                // </Grid>
                                            )}
                                        </Droppable>
                                        // <Grid container>
                                        //     {images &&
                                        //         images
                                        //             .filter(
                                        //                 (item: IMediaItem) =>
                                        //                     searchValue === "" ||
                                        //                     item
                                        //                         .name!.toLowerCase()
                                        //                         .includes(searchValue.toLocaleLowerCase()) ||
                                        //                     item
                                        //                         .description!.toLowerCase()
                                        //                         .includes(searchValue.toLocaleLowerCase()),
                                        //             )
                                        //             .sort((a: IMediaItem, b: IMediaItem) => {
                                        //                 return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                        //             })
                                        //             .map((item: IMediaItem) => {
                                        //                 return (
                                        //                     <VTImageDraggable
                                        //                         key={item.id}
                                        //                         item={item}
                                        //                         organisation={organisation}
                                        //                         localHandleDrag={localHandleDrag}
                                        //                         handleDoubleClick={handleDoubleClick}
                                        //                     />
                                        //                 );
                                        //             })}
                                        // </Grid>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}

            {activeButton === 2 && (
                <>
                    {scenarioImagesLoading ? (
                        <Grid
                            item
                            container
                            xs={12}
                            columnSpacing={1}
                            alignContent={"flex-start"}
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                            }}
                        >
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!scenarioImages || scenarioImages.length === 0 ? (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignContent={"center"}
                                    justifyContent={"center"}
                                    sx={{
                                        paddingTop: "40px",
                                    }}
                                >
                                    {" "}
                                    <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                    <Typography sx={{ color: "grey", padding: "10px" }}>
                                        Add an image to the scenario
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    // columnSpacing={1}
                                    sx={{
                                        marginTop: "5px",
                                        overflowY: "scroll",
                                        width: "100%",
                                        padding: "20px",
                                        height: "calc(100vh - 230px)",
                                    }}
                                    alignContent={"flex-start"}
                                >
                                    {scenarioImages &&
                                    scenarioImages?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <ImageList sx={{ marginTop: "0px" }} variant="masonry" cols={2} gap={8}>
                                            {scenarioImages &&
                                                scenarioImages
                                                    .filter(
                                                        (item: IMediaItem) =>
                                                            searchValue === "" ||
                                                            item
                                                                .name!.toLowerCase()
                                                                .includes(searchValue.toLocaleLowerCase()) ||
                                                            item
                                                                .description!.toLowerCase()
                                                                .includes(searchValue.toLocaleLowerCase()),
                                                    )
                                                    .sort((a: IMediaItem, b: IMediaItem) => {
                                                        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                                    })
                                                    .map((item: IMediaItem, i) => {
                                                        return (
                                                            // <>
                                                            //     {(updateLoading || deleteLoading) &&
                                                            //     currentItemUpdate == item.id ? (
                                                            //         <Grid item xs={6}>
                                                            //             <Skeleton
                                                            //                 sx={{
                                                            //                     height: "300px",
                                                            //                     margin: "10px",
                                                            //                     marginBlock: "-25px",
                                                            //                     marginTop: "-55px",
                                                            //                 }}
                                                            //             />
                                                            //         </Grid>
                                                            //     ) : (
                                                            //         <VTImageDraggable
                                                            //             key={item.id}
                                                            //             item={item}
                                                            //             organisation={organisation}
                                                            //             localHandleDrag={localHandleDrag}
                                                            //             handleDoubleClick={handleDoubleClick}
                                                            //             addFunction={() => {
                                                            //                 setCurrentItemUpdate(item.id);
                                                            //                 updateAllScenarioMedia({
                                                            //                     id: userId,
                                                            //                     media_type: EDraggableOptions.IMAGE,
                                                            //                     media_ids: [item.id],
                                                            //                 });
                                                            //             }}
                                                            //             deleteFunction={() => {
                                                            //                 setCurrentItemUpdate(item.id);
                                                            //                 deleteAllScenarioMedia({
                                                            //                     mediaType: EDraggableOptions.IMAGE,
                                                            //                     collectionId: scenarioId!,
                                                            //                     mediaId: item.id,
                                                            //                 });
                                                            //             }}
                                                            //         />
                                                            //     )}
                                                            // </>
                                                            <ImageListItem key={item.id}>
                                                                <VTSidePanelImage2dItem
                                                                    nameCutOffPoint={12}
                                                                    // {...provided.draggableProps}
                                                                    // {...provided.dragHandleProps}
                                                                    // ref={provided.innerRef}
                                                                    sx={{
                                                                        minHeight: "100px",
                                                                        // height: "100px",
                                                                        // width: "100%",
                                                                        // ...(snapshot.isDragging && {
                                                                        //     border: `4px dashed ${theme.palette.primary.light}`,
                                                                        //     borderRadius: "16px",
                                                                        // }),
                                                                    }}
                                                                    name={item.name ? item.name : "no name"}
                                                                    imgSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`}
                                                                    onHandleDoubleClick={() => {
                                                                        // handleDoubleClick(
                                                                        //     EDraggableOptions.IMAGE,
                                                                        //     item.src
                                                                        //         ? item.src
                                                                        //         : `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${item.id}.${item.filetype}`,
                                                                        // );
                                                                    }}
                                                                    onAddButtonClicked={() => {
                                                                        setCurrentItemUpdate(item.id);
                                                                        updateAllScenarioMedia({
                                                                            id: userId,
                                                                            media_type: EDraggableOptions.IMAGE,
                                                                            media_ids: [item.id],
                                                                        });
                                                                    }}
                                                                />
                                                            </ImageListItem>
                                                        );
                                                    })}
                                        </ImageList>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
