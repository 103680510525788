//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import {
    VTButton,
    VTSidePanelImage2dItem,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import NoScenarios from "../../assets/images/NoScenarios.svg";
// Material ui Imports
import {
    CircularProgress,
    Grid,
    ImageList,
    ImageListItem,
    LinearProgress,
    Skeleton,
    SxProps,
    Typography,
    useTheme,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { ReactSVG } from "react-svg";

// Custom components imports (and hooks and helpers)
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllImagesMutation,
    useDeleteMediaMutation,
    useGetAllImagesQuery,
    usePreSignedUrlMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import VTImageDraggable from "./VTImageDraggable";
import { Params, useParams } from "react-router-dom";
// Media imports
import SearchNotFound from "../../assets/images/upload_image.svg";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import { setCurrentDoubleClick } from "../../store/slices/current.slice";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import { ImageLoader } from "three";
import * as THREE from "three";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##

// Layout stuff remains inline, other styling pulled into its own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sideDrawWidth: number;

    imagesTitle: string;
    sx?: SxProps;
    handleDragStart?: (mediaType: EDraggableOptions, src: string) => void;
    handleDragStop?: () => void;
    onHandleDoubleClickSetScenarioImagePreview?: (imageSrc: string) => void;
    videoCurrentState?: "Paused" | "Playing" | "Question" | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImageSidePanel({
    sideDrawWidth,
    imagesTitle,
    handleDragStart,
    handleDragStop,
    sx,
    videoCurrentState,
    onHandleDoubleClickSetScenarioImagePreview,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const theme = useTheme();

    const [createAllImages] = useCreateAllImagesMutation();

    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [selectedImageError, setSelectedImageError] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>("");
    const [imageId, setImageId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const { data: images, isLoading: imagesLoading } = useGetAllImagesQuery("icons");

    const dispatch = useAppDispatch();
    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();
    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentQuestion } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedImage) {
            createAllImages({
                id: "icons",
                media_id: imageId,
                media_type: "images",
                filetype: "svg",
                name: imageName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: "",
            });
        }
    }, [mediaUploadCompleted]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    function handleButtonClick(buttonIndex: number) {
        setActiveButton(buttonIndex);
    }

    function localHandleDrag(dragged: boolean, mediaType?: EDraggableOptions, src?: string) {
        if (dragged && handleDragStart && mediaType && src) {
            handleDragStart(mediaType, src);
        } else if (!dragged && handleDragStop) {
            handleDragStop();
        }
    }

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(imageFile: File) {
        // TODO: in the future only allow certain image types
        if (imageFile.type.includes("image/") && imageFile.type.includes("/svg")) {
            setSelectedImageError(false);
            setSelectedImage(imageFile);
            setImageName(imageFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation: "icons",
                media_type: "images",
                media_id: imageId,
                filetype: "svg",
            });
        } else {
            setSelectedImageError(true);
        }
    }

    // Function that handle image added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle image added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleImageUpload() {
        if (uploadUrl && selectedImage) {
            dispatch(
                uploadMedia({
                    media: selectedImage,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedImage(null);
        setImageId(uuid());
        setImageName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    function handleDoubleClick(mediaType: EDraggableOptions, src: string) {
        dispatch(setCurrentDoubleClick({ src: src, mediaType: mediaType }));
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <Grid container xs={12} justifyContent={"center"} sx={{ overflowY: "hidden", userSelect: "none", ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Images search */}
            <Grid item xs={11}>
                <VTTextField
                    placeholder={"Search Images"}
                    sx={{
                        marginTop: "20px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "15px",
                    display: "flex",
                    // justifyContent: "center",
                    // height: "80px",
                }}
            >
                <Grid item xs={6}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                            height: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>All Images</Typography>
                    </VTButton>
                </Grid>

                <Grid item xs={6}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>Upload Image</Typography>
                    </VTButton>
                </Grid>
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Image upload */}
            {activeButton === 1 && (
                <>
                    <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                        {selectedImage ? (
                            <Grid container>
                                <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <VTTextField
                                        value={imageName}
                                        onChange={(value) => setImageName(value)}
                                        disabled={mediaUploading || mediaUploadCompleted}
                                    />
                                </Grid>
                                <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <img
                                        src={URL.createObjectURL(selectedImage)}
                                        alt="Selected"
                                        width="100%"
                                        height="250px"
                                        style={{
                                            borderRadius: "15px",
                                            objectFit: "cover",
                                        }}
                                    />
                                </Grid>
                                {preSignedUrlLoading ? (
                                    <Grid
                                        xs={12}
                                        sx={{
                                            margin: "10px 5px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    <>
                                        {mediaUploading || mediaUploadCompleted ? (
                                            <>
                                                {mediaUploadError ? (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                                align="center"
                                                            >
                                                                Error Uploading Image
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={"secondary"}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                Cancel
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <LinearProgress
                                                                variant="determinate"
                                                                value={mediaUploadPercentage}
                                                            />
                                                        </Grid>
                                                        {mediaUploadCompleted && (
                                                            <Grid
                                                                xs={6}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    padding: "2px",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={"secondary"}
                                                                    fullWidth={true}
                                                                    onClick={() => {
                                                                        setSelectedImage(null);
                                                                        setImageId(uuid());
                                                                        setImageName("");
                                                                        dispatch(setMediaUploadCleanUp());
                                                                    }}
                                                                >
                                                                    Upload Another
                                                                </VTButton>
                                                            </Grid>
                                                        )}
                                                        <Grid
                                                            xs={mediaUploadCompleted ? 6 : 12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "2px",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={mediaUploadCompleted ? "primary" : "secondary"}
                                                                fullWidth={true}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                {mediaUploadCompleted ? "Done" : "Cancel"}
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton
                                                        color={"secondary"}
                                                        onClick={() => setSelectedImage(null)}
                                                        fullWidth={true}
                                                    >
                                                        Cancel
                                                    </VTButton>
                                                </Grid>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton onClick={() => handleImageUpload()} fullWidth={true}>
                                                        upload
                                                    </VTButton>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                container
                                //TODO: user this as an overlay
                                onDragEnter={() => setHighlight(true)}
                                onDragLeave={() => setHighlight(false)}
                                sx={{
                                    border: "15px",
                                    // border: highlight ? borderRadius : "15px",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "50px 0px",
                                    }}
                                >
                                    <img src={"/media/images/upload.svg"} alt="upload media" width={"50%"} />
                                </Grid>
                                <Grid xs={12}>
                                    <Typography align="center">Drop or choose image to upload</Typography>

                                    {selectedImageError && (
                                        <Typography align="center" sx={{ color: "red" }}>
                                            File types accepted: SVG
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "50px 0px",
                                    }}
                                >
                                    <input
                                        accept="image/*"
                                        type="file"
                                        id="file"
                                        ref={inputFileRef}
                                        style={{ display: "none" }}
                                        onChange={(e) => handleSelect(e)}
                                    />
                                    <VTButton
                                        onClick={() => {
                                            if (inputFileRef && inputFileRef.current) {
                                                inputFileRef.current.click();
                                            }
                                        }}
                                    >
                                        Choose File
                                    </VTButton>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </>
            )}
            {activeButton === 0 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Image list */}
                    {imagesLoading ? (
                        <Grid
                            item
                            container
                            xs={12}
                            columnSpacing={1}
                            alignContent={"flex-start"}
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                            }}
                        >
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                            <Grid item xs={6} sx={{ marginBottom: "8px" }}>
                                <VTSidePanelImage2dItem sx={{ width: "100%" }} loading name={""} imgSrc={""} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {/* @ts-ignore */}
                            {!images || images.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first image file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    // columnSpacing={1}
                                    sx={{
                                        marginTop: "5px",
                                        overflowY: "scroll",
                                        width: "100%",
                                        padding: "20px",
                                        height: "calc(100vh - 230px)",
                                    }}
                                    alignContent={"flex-start"}
                                >
                                    {images &&
                                    images?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Droppable
                                            droppableId={EDroppableContainerId.MEDIA_2D_IMAGES}
                                            type="DROPPABLE_MEDIA"
                                            // isDropDisabled={false}
                                            // renderClone={(provided, snapshot, rubric) => (
                                            //     <div
                                            //         {...provided.draggableProps}
                                            //         {...provided.dragHandleProps}
                                            //         ref={provided.innerRef}
                                            //     >
                                            //         Item id: {images[rubric.source.index].id}
                                            //     </div>
                                            // )}
                                        >
                                            {(provided, snapshot) => (
                                                // <Grid
                                                //     item
                                                //     container
                                                //     xs={12}
                                                //     // columnSpacing={1}
                                                //     sx={{
                                                //         // marginTop: "5px",
                                                //         // overflowY: "scroll",
                                                //         width: "100%",
                                                //         // padding: "20px",
                                                //         height: "calc(100vh - 230px)",
                                                //     }}
                                                //     alignContent={"flex-start"}
                                                // >
                                                <ImageList
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    sx={{ marginTop: "0px" }}
                                                    variant="masonry"
                                                    cols={2}
                                                    gap={8}
                                                >
                                                    {images &&
                                                        images
                                                            .filter(
                                                                (item: IMediaItem) =>
                                                                    searchValue === "" ||
                                                                    item
                                                                        .name!.toLowerCase()
                                                                        .includes(searchValue.toLocaleLowerCase()) ||
                                                                    item
                                                                        .description!.toLowerCase()
                                                                        .includes(searchValue.toLocaleLowerCase()),
                                                            )
                                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                                return (
                                                                    new Date(b.date).valueOf() -
                                                                    new Date(a.date).valueOf()
                                                                );
                                                            })
                                                            .map((item: IMediaItem, i) => {
                                                                return (
                                                                    <>
                                                                        {`${process.env.REACT_APP_NINJA_MEDIA}/icons/images/${item.id}.svg`}
                                                                        <img
                                                                            src={`${process.env.REACT_APP_NINJA_MEDIA}/icons/images/${item.id}.svg`}
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                backgroundImage: `url(${process.env.REACT_APP_NINJA_MEDIA}/icons/images/${item.id}.svg)`,
                                                                            }}
                                                                        ></div>
                                                                        <ReactSVG
                                                                            afterInjection={(svg) => {
                                                                                console.log(svg);
                                                                            }}
                                                                            beforeInjection={(svg) => {
                                                                                svg.classList.add("svg-class-name");
                                                                                svg.setAttribute(
                                                                                    "style",
                                                                                    "width: 200px",
                                                                                );
                                                                            }}
                                                                            className="wrapper-class-name"
                                                                            desc="Description"
                                                                            evalScripts="always"
                                                                            fallback={() => <span>Error!</span>}
                                                                            httpRequestWithCredentials={true}
                                                                            loading={() => <span>Loading</span>}
                                                                            onClick={() => {
                                                                                console.log("wrapper onClick");
                                                                            }}
                                                                            onError={(error) => {
                                                                                console.error(error);
                                                                            }}
                                                                            renumerateIRIElements={false}
                                                                            src={`${process.env.REACT_APP_NINJA_MEDIA}/icons/images/${item.id}.svg`}
                                                                            title="Title"
                                                                            useRequestCache={false}
                                                                            wrapper="span"
                                                                        />
                                                                        <img
                                                                            src={"https://s.cdpn.io/3/kiwi.svg"}
                                                                            width={"50%"}
                                                                        />
                                                                        <Draggable
                                                                            key={item.id}
                                                                            draggableId={`${process.env.REACT_APP_NINJA_MEDIA}/icons/images/${item.id}.svg`}
                                                                            index={i}
                                                                            isDragDisabled={false}
                                                                        >
                                                                            {(provided, snapshot) => (
                                                                                <ImageListItem key={item.id}>
                                                                                    <VTSidePanelImage2dItem
                                                                                        nameCutOffPoint={12}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={provided.innerRef}
                                                                                        sx={{
                                                                                            // height: "100px",
                                                                                            // width: "100%",
                                                                                            ...(snapshot.isDragging && {
                                                                                                border: `4px dashed ${theme.palette.primary.light}`,
                                                                                                borderRadius: "16px",
                                                                                            }),
                                                                                        }}
                                                                                        name={
                                                                                            item.name
                                                                                                ? item.name
                                                                                                : "no name"
                                                                                        }
                                                                                        imgSrc={`${process.env.REACT_APP_NINJA_MEDIA}/icons/images/${item.id}.svg`}
                                                                                        onHandleDoubleClick={() => {
                                                                                            if (
                                                                                                onHandleDoubleClickSetScenarioImagePreview
                                                                                            ) {
                                                                                                onHandleDoubleClickSetScenarioImagePreview(
                                                                                                    `${process.env.REACT_APP_NINJA_MEDIA}/icons/images/${item.id}.svg`,
                                                                                                );
                                                                                            } else {
                                                                                                handleDoubleClick(
                                                                                                    EDraggableOptions.IMAGE,
                                                                                                    item.src
                                                                                                        ? item.src
                                                                                                        : `${process.env.REACT_APP_NINJA_MEDIA}/icons/images/${item.id}.svg`,
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </ImageListItem>
                                                                            )}
                                                                        </Draggable>
                                                                    </>
                                                                    // <VTImageDraggable
                                                                    //     key={item.id}
                                                                    //     item={item}
                                                                    //     organisation={organisation}
                                                                    //     localHandleDrag={localHandleDrag}
                                                                    //     handleDoubleClick={handleDoubleClick}
                                                                    // />
                                                                );
                                                            })}
                                                </ImageList>
                                                // </Grid>
                                            )}
                                        </Droppable>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
