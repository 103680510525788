//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { ReactElement } from "react";

// Material ui Imports
import { Drawer, SvgIconProps, SxProps, useTheme } from "@mui/material";
import Rotate90DegreesCwRoundedIcon from "@mui/icons-material/Rotate90DegreesCwRounded";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import VideoLibraryRoundedIcon from "@mui/icons-material/VideoLibraryRounded";
import AudiotrackRoundedIcon from "@mui/icons-material/AudiotrackRounded";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TemplateIcon from "@mui/icons-material/FlipToFrontRounded";
import Model3DIcon from "@mui/icons-material/ViewInArRounded";
import TextFieldsRoundedIcon from "@mui/icons-material/TextFieldsRounded";

// Custom components imports (and hooks and helpers)
import VTCreatorTabOption from "./VTCreatorTabOption";
import { EditHotSpotDialogTab } from "../../models/current.models";
import { useAppSelector } from "../../store/hooks";

// Media imports
import Icon360Image from "../../assets/icons/Icon360Image";
import Icon360Video from "../../assets/icons/Icon360Video";
import { EOrganisation } from "../../models/organisations.model";
import { setCurrentRichTextEditorMode } from "../../store/slices/current.slice";
import IconImmersiveMedia from "../../assets/icons/IconImmersiveMedia";
import IconImmersiveImage from "../../assets/icons/IconImmersiveImage";
import IconImmersiveVideo from "../../assets/icons/IconImmersiveVideo";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##

// Tab options

interface IProps {
    setCloseDraw: () => void;
    setOpenDraw: any;
    disabled?: string[];
    sx?: SxProps;
    isEditHotSpotDialogOpen: boolean;
    currentEditHotSpotDialogTabOpen: EditHotSpotDialogTab | undefined;
}

// Props to map the tab options
interface ICreatorTabsProps {
    tabName: string;
    icon: ReactElement<SvgIconProps>;
}

function shouldDisableTabWhenEditHotspotIsOpen(
    tabName: string,
    isEditHotSpotDialogOpen: boolean,
    editHotspotDialogTabOpen: EditHotSpotDialogTab | undefined,
    richTextEditorMode: "presentation mode" | "normal mode" | undefined,
    // @ts-ignore
): boolean {
    if (isEditHotSpotDialogOpen) {
        if (!["Images", "Videos", "Audio"].includes(tabName) && editHotspotDialogTabOpen === "actions") {
            return true;
        } else if (
            !["Images", "Videos", "Audio"].includes(tabName) &&
            editHotspotDialogTabOpen === "content" &&
            richTextEditorMode === "normal mode"
        ) {
            return true;
        } else if (
            !["Images", "Text", "Audio", "Videos"].includes(tabName) &&
            editHotspotDialogTabOpen === "content" &&
            richTextEditorMode === "presentation mode"
        ) {
            return true;
        } else if (editHotspotDialogTabOpen === "style") {
            return true;
        } else if (editHotspotDialogTabOpen === "render") {
            return true;
        }
    } else {
        return false;
    }
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorTabs({
    isEditHotSpotDialogOpen,
    currentEditHotSpotDialogTabOpen,
    setCloseDraw,
    setOpenDraw,
    disabled,
    sx,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { organisation } = useAppSelector((state) => state.userReducer);
    const { currentSidePanel, currentRichTextEditorMode } = useAppSelector((state) => state.currentReducer);
    const { mediaUploading } = useAppSelector((state) => state.mediaUploadReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const tabOptions: ICreatorTabsProps[] =
        organisation === "virtus-tech"
            ? [
                  //   { tabName: "Templates", icon: <TemplateIcon /> },

                  {
                      tabName: "360 Images",
                      icon: <IconImmersiveImage />,
                  },
                  {
                      tabName: "360 Videos",
                      icon: <IconImmersiveVideo />,
                  },
                  {
                      tabName: "Hotspots",
                      icon: <RadioButtonCheckedIcon />,
                  },
                  { tabName: "Text", icon: <TextFieldsRoundedIcon /> },
                  { tabName: "Images", icon: <ImageRoundedIcon /> },

                  {
                      tabName: "Videos",
                      icon: <VideoLibraryRoundedIcon />,
                  },
                  { tabName: "Audio", icon: <AudiotrackRoundedIcon /> },
                  { tabName: "3D", icon: <Model3DIcon /> },
              ]
            : organisation === "eco-goal"
            ? [
                  {
                      tabName: "360 Images",
                      icon: <Icon360Image />,
                  },
                  {
                      tabName: "Hotspots",
                      icon: <RadioButtonCheckedIcon />,
                  },
                  { tabName: "Images", icon: <ImageRoundedIcon /> },

                  {
                      tabName: "Videos",
                      icon: <VideoLibraryRoundedIcon />,
                  },
              ]
            : [
                  //   { tabName: "Templates", icon: <TemplateIcon /> },
                  {
                      tabName: "360 Images",
                      icon: <Icon360Image />,
                  },
                  {
                      tabName: "360 Videos",
                      icon: <Icon360Video />,
                  },
                  {
                      tabName: "Hotspots",
                      icon: <RadioButtonCheckedIcon />,
                  },
                  { tabName: "Images", icon: <ImageRoundedIcon /> },

                  {
                      tabName: "Videos",
                      icon: <VideoLibraryRoundedIcon />,
                  },
                  { tabName: "Audio", icon: <AudiotrackRoundedIcon /> },
                  { tabName: "Text", icon: <TextFieldsRoundedIcon /> },
                  { tabName: "3D", icon: <Model3DIcon /> },
              ];

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Render for the tab buttons

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <Drawer
            variant="permanent"
            anchor="left"
            PaperProps={{
                sx: {
                    backgroundColor: theme.palette.background.default,
                    position: "absolute",
                    top: "68px",
                    width: "80px",
                    zIndex: 1450,
                    height: "calc(100vh - 67px)",
                    ...sx,
                },
            }}
        >
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Templates Options */}
            {tabOptions
                .filter((element: ICreatorTabsProps) =>
                    disabled && disabled.length ? !disabled.includes(element.tabName) : element.tabName,
                )
                .map((element: ICreatorTabsProps) => (
                    <VTCreatorTabOption
                        disable={
                            currentSidePanel.option === "DISABLED" ||
                            (element.tabName === "3D" && organisation !== EOrganisation.VIRTUS_TECH) ||
                            mediaUploading
                                ? true
                                : shouldDisableTabWhenEditHotspotIsOpen(
                                      element.tabName,
                                      isEditHotSpotDialogOpen,
                                      currentEditHotSpotDialogTabOpen,
                                      currentRichTextEditorMode,
                                  )
                        }
                        currentTab={currentSidePanel.currentTab}
                        tabName={element.tabName}
                        icon={element.icon}
                        setCloseDraw={() => !mediaUploading && setCloseDraw()}
                        setOpenDraw={() => !mediaUploading && setOpenDraw(element.tabName)}
                    />
                ))}
        </Drawer>
    );
}
