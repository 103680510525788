//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";

// Custom components imports (and hooks and helpers)

export const MediaApi = createApi({
    reducerPath: "media",
    tagTypes: [
        "media_image",
        "media_three_sixty_video",
        "media_video",
        "media_three_sixty_image",
        "media_three_d_models",
        "media_audio",
        "media",
        "preSignUrl",
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_NINJA_MEDIA_CONFIG,
    }),
    endpoints: (builder) => ({
        duplicateMedia: builder.mutation<any, { userId: string; scenarioId: string }>({
            query: ({ userId, scenarioId }) => ({
                url: `duplicate/${userId}/${scenarioId} `,
                method: "POST",
            }),
            invalidatesTags: [
                { type: "media_three_sixty_image", id: "LIST" },
                { type: "media_three_sixty_video", id: "LIST" },
                { type: "media_image", id: "LIST" },
                { type: "media_video", id: "LIST" },
                { type: "media_audio", id: "LIST" },
            ],
        }),
        getAllImages: builder.query<IMediaItem[], string>({
            query: (userId) => ({

                url: process.env.REACT_APP_IS_GLOBAL_ACCELERATOR ? `media/images/${userId}` : `images/${userId}`,

                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IMediaItem) => ({
                              type: "media_image" as const,
                              id,
                          })),
                          { type: "media_image", id: "LIST" },
                      ]
                    : [{ type: "media_image", id: "LIST" }],
        }),
        // TODO: type just getting it working sorry chris ;) you naughty boi
        createAllImages: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "media_image", id: "LIST" }],
        }),
        // TODO: type just getting it working sorry chris ;) you naughty boi
        updateAllImages: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "PUT",
                body,
            }),
            invalidatesTags: [{ type: "media_image", id: "LIST" }],
        }),
        updateAllScenarioMedia: builder.mutation<
            any,
            { id: string; media_type: EDraggableOptions; media_ids: string[] }
        >({
            query: (body) => ({
                url: "mediacollection",
                method: "PUT",
                body,
            }),
            invalidatesTags: [
                { type: "media_three_sixty_image", id: "LIST" },
                { type: "media_three_sixty_video", id: "LIST" },
                { type: "media_image", id: "LIST" },
                { type: "media_video", id: "LIST" },
                { type: "media_audio", id: "LIST" },
            ],
        }),
        deleteMedia: builder.mutation<any, { mediaType: EDraggableOptions; collectionId: string; mediaId: string }>({
            query: (body) => ({

                url: process.env.REACT_APP_IS_GLOBAL_ACCELERATOR
                    ? `media/${body.mediaType}/${body.collectionId}/${body.mediaId}`
                    : `${body.mediaType}/${body.collectionId}/${body.mediaId}`,

                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "media_three_sixty_image", id: "LIST" },
                { type: "media_three_sixty_video", id: "LIST" },
                { type: "media_image", id: "LIST" },
                { type: "media_video", id: "LIST" },
                { type: "media_audio", id: "LIST" },
                { type: "media_three_d_models", id: "LIST" },
            ],
        }),
        // TODO: type just getting it working sorry chris ;)
        updateAllVideos: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "PUT",
                body,
            }),
            invalidatesTags: [{ type: "media_video", id: "LIST" }],
        }),

        createAllVideos: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "media_video", id: "LIST" }],
        }),

        getAllAudio: builder.query<IMediaItem[], string>({
            query: (userId) => ({

                url: process.env.REACT_APP_IS_GLOBAL_ACCELERATOR ? `media/audio/${userId}` : `audio/${userId}`,

                method: "GET",
            }),

            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IMediaItem) => ({
                              type: "media" as const,
                              id,
                          })),
                          { type: "media_audio", id: "LIST" },
                      ]
                    : [{ type: "media_audio", id: "LIST" }],
        }),
        createAllAudio: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "media_audio", id: "LIST" }],
        }),
        // TODO: type just getting it working sorry chris ;)
        updateAllAudio: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "PUT",
                body,
            }),
            invalidatesTags: [{ type: "media_audio", id: "LIST" }],
        }),
        getAll360Images: builder.query<IMediaItem[], string>({
            query: (userId) => ({

                url: process.env.REACT_APP_IS_GLOBAL_ACCELERATOR
                    ? `media/three_sixty_image/${userId}`
                    : `three_sixty_image/${userId}`,

                method: "GET",
            }),

            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IMediaItem) => ({
                              type: "media" as const,
                              id,
                          })),
                          { type: "media_three_sixty_image", id: "LIST" },
                      ]
                    : [{ type: "media_three_sixty_image", id: "LIST" }],
        }),

        getAll360Videos: builder.query<IMediaItem[], string>({
            query: (userId) => ({
                url: process.env.REACT_APP_IS_GLOBAL_ACCELERATOR
                    ? `media/three_sixty_video/${userId}`
                    : `three_sixty_video/${userId}`,
                method: "GET",
            }),

            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IMediaItem) => ({
                              type: "media" as const,
                              id,
                          })),
                          { type: "media_three_sixty_video", id: "LIST" },
                      ]
                    : [{ type: "media_three_sixty_video", id: "LIST" }],
        }),
        // TODO: type just getting it working sorry chris ;)
        createImmersiveVideos: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "media_three_sixty_video", id: "LIST" }],
        }),
        // TODO: type just getting it working sorry chris ;)
        updateAllImmersiveVideos: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "PUT",
                body,
            }),
            invalidatesTags: [{ type: "media_three_sixty_video", id: "LIST" }],
        }),
        // TODO: type just getting it working sorry chris ;)
        updateAllImmersiveImages: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "PUT",
                body,
            }),
            invalidatesTags: [{ type: "media_three_sixty_image", id: "LIST" }],
        }),
        createAllImmersiveImages: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "media_three_sixty_image", id: "LIST" }],
        }),
        // TODO: type just getting it working sorry chris ;)
        createImmersiveModels: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "media_three_d_models", id: "LIST" }],
        }),
        // TODO: type just getting it working sorry chris ;)
        updateAllImmersiveModels: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: "mediametadata",
                method: "PUT",
                body,
            }),
            invalidatesTags: [{ type: "media_three_d_models", id: "LIST" }],
        }),
        getAllImmersiveModels: builder.query<IMediaItem[], string>({
            query: (userId) => ({

                url: process.env.REACT_APP_IS_GLOBAL_ACCELERATOR
                    ? `media/three_d_model/${userId}`
                    : `three_d_model/${userId}`,

                method: "GET",
            }),
            providesTags: (result) => [{ type: "media_three_d_models", id: "LIST" }],
        }),
        getAllVideos2d: builder.query<IMediaItem[], string>({
            query: (userId) => ({

                url: process.env.REACT_APP_IS_GLOBAL_ACCELERATOR ? `media/video/${userId}` : `video/${userId}`,

                method: "GET",
            }),

            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IMediaItem) => ({
                              type: "media" as const,
                              id,
                          })),
                          { type: "media_video", id: "LIST" },
                      ]
                    : [{ type: "media_video", id: "LIST" }],
        }),
        preSignedUrl: builder.mutation<
            { url: string },
            Partial<{
                organisation: string;
                media_type: string;
                media_id: string;
                filetype: string;
            }>
        >({
            query: (body) => ({
                url: "presignedurl",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "preSignUrl", id: "LIST" }],
        }),
    }),
});

export const {
    useDuplicateMediaMutation,
    useGetAllImagesQuery,
    useGetAll360ImagesQuery,
    useGetAll360VideosQuery,
    useGetAllAudioQuery,
    useGetAllVideos2dQuery,
    usePreSignedUrlMutation,
    useDeleteMediaMutation,
    useCreateAllImagesMutation,
    useUpdateAllImagesMutation,
    useUpdateAllScenarioMediaMutation,
    useUpdateAllImmersiveVideosMutation,
    useCreateImmersiveVideosMutation,
    useUpdateAllVideosMutation,
    useUpdateAllAudioMutation,
    useUpdateAllImmersiveImagesMutation,
    useCreateAllImmersiveImagesMutation,
    useCreateAllVideosMutation,
    useCreateAllAudioMutation,
    useCreateImmersiveModelsMutation,
    useUpdateAllImmersiveModelsMutation,
    useGetAllImmersiveModelsQuery,
} = MediaApi;
