//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useState } from "react";

// Material ui Imports
import { Grid, ImageList, ImageListItem, Skeleton, SxProps, Tooltip, Typography, useTheme } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

// Custom components imports (and hooks and helpers)
import {
    VTButton,
    VTTextField,
    VTHTMLHotspot,
    VTSidePanelHotspotWrapper,
    VTHTMLHotspotRightWrongAnswerToggle,
    VTSidePanelTextHotspotWrapper,
} from "@virtus-tech-repository/virtus-tech-repository";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Preview, RadioButtonCheckedRounded } from "@mui/icons-material";

// Media imports
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ReactComponent as Movement } from "../components/movement.svg";
import SelectAllRoundedIcon from "@mui/icons-material/SelectAllRounded";
import { Draggable, Droppable, Position } from "react-beautiful-dnd";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import {
    EDraggableHotspotId,
    EDroppableContainerId,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import { setCurrentDoubleClick, setCurrentHotspot, setCurrentHotspotTransfer } from "../../store/slices/current.slice";
import {
    useDeleteGroupHotspotMutation,
    useDeleteHotspotMutation,
    useGetGroupHotspotsQuery,
    useGetHotspotsQuery,
} from "../../services/hotspot.service";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { v4 as uuid } from "uuid";
import { Params, useParams } from "react-router-dom";
import { AddCircleOutlineRounded, DeleteRounded } from "@mui/icons-material";
import { SettingsRounded } from "@mui/icons-material";

import NoHotspotTemplates from "../../assets/images/NoScenarios.svg";
import { IconSwitch } from "../../helpers/IconSwitchHelper";
import * as THREE from "three";

// const [searchValue, setSearchValue] = useState<string>("");

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sideDrawWidth: number;
    sx?: SxProps;
    handleDragStart?: (mediaType: EDraggableOptions, src: string, preview?: string) => void;
    handleDragStop?: () => void;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
}

export interface EDragOptions {}

export enum EDraggableTextHotspotId {
    HEADING = "heading",
    SUB_HEADING = "sub heading",
    PARAGRAPH = "paragraph",
    LIST = "list",
}

// export const SidePanelTextHotspotParagraph = Template.bind({});
// SidePanelTextHotspotParagraph.args = {
//     sx: {
//         width: "200px",
//         height: "200px",
//     },
//     title: "Paragraph Text Hotspot",
//     onAddButtonClicked: undefined,
//     children: (
//         <VTHTMLHotspot
//             onClose={undefined}
//             scale2d={1}
//             vtRichTextEditorContent={{
//                 content: "paragraph",
//             }}
//             sx={{
//                 marginTop: "-6px",
//                 "> div > div": { cursor: "default" },
//             }}
//         />
//     ),
// };

const textHotspots: IHotspot[] = [
    {
        id: "text hotspot 1",
        location: new THREE.Vector3(0, 0, 0),
        type: EDraggableOptions.INFORMATION,
        contents: {
            preClickContent: {
                contentData: '<div class="rte-container"> <b><font size="6">Heading</font></b> </div>',
            },
        },
    },
    // {
    //     id: "text hotspot 2",
    //     location: new THREE.Vector3(0, 0, 0),
    //     type: EDraggableOptions.INFORMATION,
    //     contents: {
    //         preClickContent: {
    //             contentData: '<div class="rte-container"> <font size="5">Sub Heading</font> </div>',
    //         },
    //     },
    // },
    // {
    //     id: "text hotspot 3",
    //     location: new THREE.Vector3(0, 0, 0),
    //     type: EDraggableOptions.INFORMATION,
    //     contents: {
    //         preClickContent: {
    //             contentData: '<div class="rte-container">  <font size="3">paragraph</font><br>  </div>',
    //         },
    //     },
    // },
    // {
    //     id: "text hotspot 4",
    //     location: new THREE.Vector3(0, 0, 0),
    //     type: EDraggableOptions.INFORMATION,
    //     contents: {
    //         preClickContent: {
    //             contentData:
    //                 '<div class="rte-container"> <ul><li>  Item 1<br></li><li>Item 2</li><li>Item 3<br></li></ul> </div>',
    //         },
    //     },
    // },
];

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function VTTextSidePanel({
    sideDrawWidth,
    handleDragStart,
    handleDragStop,
    sx,
    videoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();
    const { sceneId }: Readonly<Params<string>> = useParams();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentScene, currentlyDragging } = useAppSelector((state) => state.currentReducer);
    const { organisation } = useAppSelector((state) => state.userReducer);

    const { id: userId } = useAppSelector((state) => state.userReducer);
    const [deleteQuestionHotspot, isLoading] = useDeleteGroupHotspotMutation();
    const [deleteHotspot] = useDeleteHotspotMutation();

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const { data: hotspotTemplates } = useGetGroupHotspotsQuery(userId);
    const { data: hotspots } = useGetHotspotsQuery(currentScene.id);
    const [templateLoading, setTemplateLoading] = useState<string>("");
    const [currentMouseOver, setCurrentMouseOver] = useState<string>("");

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    function handleButtonClick(buttonIndex: number) {
        setActiveButton(buttonIndex);
    }

    function localHandleDrag(
        dragged: boolean,
        mediaType?: EDraggableOptions,
        src?: string,
        preview?: string | undefined,
    ) {
        console.log("BDGG: dragged", dragged, mediaType, src);
        if (dragged && handleDragStart && mediaType && src) {
            handleDragStart(mediaType, src, preview);
        } else if (!dragged && handleDragStop) {
            handleDragStop();
        }
    }

    function handleDoubleClick(mediaType: EDraggableOptions, src: string) {
        dispatch(setCurrentDoubleClick({ src: src, mediaType: mediaType }));
    }

    const theme = useTheme();

    return (
        <Grid container xs={12} justifyContent={"center"} sx={{ height: "100%", ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Images search */}
            <Grid item xs={11}>
                <VTTextField
                    placeholder={"Search Hotspots"}
                    sx={{
                        marginTop: "20px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Text list */}
            <Droppable droppableId={EDroppableContainerId.HOTSPOTS} type="DROPPABLE_MEDIA">
                {(provided, snapshot) => (
                    <Grid
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        item
                        container
                        xs={12}
                        columnSpacing={1}
                        sx={{
                            marginTop: "5px",
                            overflowY: "scroll",
                            width: "100%",
                            padding: "20px",
                            height: "100%",
                        }}
                        alignContent={"flex-start"}
                    >
                        {"paragraph text hotspot".includes(searchValue.toLowerCase()) && (
                            <Draggable
                                key={0}
                                draggableId={EDraggableHotspotId.TEXT_HOTSPOT_PARAGRAPH}
                                index={0}
                                isDragDisabled={false}
                            >
                                {(provided, snapshot) => (
                                    <>
                                        <Grid
                                            item
                                            xs={6}
                                            container
                                            sx={{ marginBottom: "8px" }}
                                            onMouseEnter={() => {
                                                // dispatch(
                                                //     setCurrentHotspotTransfer({
                                                //         create: false,
                                                //         hotspot: { ...hotspotElement, id: uuid() },
                                                //     }),
                                                // )
                                            }}
                                        >
                                            <VTSidePanelTextHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                sx={{
                                                    width: "100%",
                                                    height: "180px",
                                                }}
                                                title={"Paragraph Text Hotspot"}
                                            >
                                                <VTHTMLHotspot
                                                    onClose={undefined}
                                                    scale2d={0.8}
                                                    vtRichTextEditorContent={{
                                                        // @ts-ignore
                                                        content: "Paragraph",
                                                    }}
                                                    sx={{
                                                        marginTop: "-6px",
                                                        "> div": { cursor: "grab" },
                                                    }}
                                                />
                                            </VTSidePanelTextHotspotWrapper>
                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    </>
                                )}
                            </Draggable>
                        )}
                    </Grid>
                )}
            </Droppable>
        </Grid>
    );
}
