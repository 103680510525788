import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";

import NoPreview from "../assets/images/NoScenarioImage.svg";
import NoPreviewFile from "../assets/images/NoFilesImage.svg";
import PassthroughPreview from "../assets/images/PassthroughPreview.svg";
import Model3DPreview from "../assets/images/Model3dPreview.svg";

export function getImageForSceneOrDefaultImageIfPreviewImageDoesntExist(sceneData: IScene): any {
    if (sceneData.previewImage) {
        return sceneData.previewImage;
    } else if (sceneData.mediaType === EDraggableOptions.IMMERSIVE_IMAGE) {
        if (sceneData.src === "https://medias3.digi-sim-api.co.uk/app_defaults/default_360_image") {
            return `${sceneData.src}/f.png`;
        } else {
            return `${sceneData.src}/${sceneData.src.split("/").at(-1)}_preview.jpg`;
        }
    } else if (sceneData.mediaType === EDraggableOptions.IMMERSIVE_VIDEO) {
        return sceneData.src.split(".mp4")[0] + "_preview.jpg";
    } else if (sceneData.mediaType === EDraggableOptions.VIDEO) {
        console.log("DBG: preview src", sceneData.src);
        return sceneData.src.split(".mp4")[0] + "_preview.jpg";
    } else if (sceneData.mediaType === EDraggableOptions.DOCUMENT) {
        return NoPreviewFile;
    } else if (sceneData.mediaType === EDraggableOptions.MODEL_3D) {
        return Model3DPreview;
    } else if (sceneData.mediaType === EDraggableOptions.PASSTHROUGH) {
        return PassthroughPreview;
    } else {
        return NoPreview;
    }
}
