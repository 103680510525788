//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports

// Material ui Imports
// Custom components imports (and hooks and helpers)
import { VTButton, VTDialog, VTTextField, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";

import { useDispatch } from "react-redux";
import {
    setCurrentAudio,
    setCurrentFeedbackImage,
    setCurrentFeedbackInput,
    setCurrentFeedbackVideo,
} from "../../store/slices/current.slice";
import ReactAudioPlayer from "react-audio-player";

import ReactPlayer from "react-player";
import { useAppSelector } from "../../store/hooks";
import { Grid } from "@mui/material";
import { useState } from "react";

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function CreatorFeedbackContainer() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    const {
        currentAudio,
        currentFeedbackImage,
        currentFeedbackVideo,
        currentFeedbackInput,
        currentSidePanel,
        currentlyPreview,
    } = useAppSelector((state) => state.currentReducer);

    const [userInputText, setUserInputText] = useState<string>("");

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // Test data image loading and video question loading

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <>
            <ReactAudioPlayer
                src={currentAudio}
                autoPlay
                controls={false}
                onEnded={() => {
                    dispatch(setCurrentAudio(""));
                }}
            />

            <VTDialog
                open={currentFeedbackImage !== ""}
                title={"Image Feedback"}
                sx={{ marginLeft: "calc(80px + " + currentSidePanel.currentWidth + "px)" }}
                primaryArea={
                    <>
                        <VTButton
                            onClick={() => {
                                dispatch(setCurrentFeedbackImage(""));
                            }}
                        >
                            Continue
                        </VTButton>
                    </>
                }
            >
                <Grid item container xs={12} justifyContent={"center"}>
                    <img
                        src={currentFeedbackImage}
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            height: "60vh",
                        }}
                    />
                </Grid>
            </VTDialog>

            <VTDialog
                open={currentFeedbackVideo !== ""}
                title={"Video Feedback"}
                sx={{ marginLeft: "calc(80px + " + currentSidePanel.currentWidth + "px)" }}
                primaryArea={
                    <>
                        <VTButton
                            onClick={() => {
                                dispatch(setCurrentFeedbackVideo(""));
                            }}
                        >
                            Continue
                        </VTButton>
                    </>
                }
            >
                <Grid item container xs={12} justifyContent={"center"}>
                    <ReactPlayer
                        url={currentFeedbackVideo}
                        onEnded={() => dispatch(setCurrentFeedbackVideo(""))}
                        controls
                    />
                </Grid>
            </VTDialog>

            <VTDialog
                open={currentFeedbackInput !== ""}
                title={currentFeedbackInput}
                sx={{ marginLeft: "calc(80px + " + currentSidePanel.currentWidth + "px)" }}
                primaryArea={
                    <>
                        <VTButton
                            onClick={() => {
                                dispatch(setCurrentFeedbackInput(""));
                            }}
                        >
                            Submit
                        </VTButton>
                    </>
                }
            >
                <Grid item container xs={12} justifyContent={"center"}>
                    <VTTextField
                        value={userInputText}
                        onChange={(e) => setUserInputText(e)}
                        sx={{ width: "80%" }}
                        multiline
                        rows={4}
                    />
                </Grid>
            </VTDialog>
        </>
    );
}
